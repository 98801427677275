// Imports
import { ArrowRight, Close } from "@icons";

// CLOSE BUTTON
export const ButtonClose = (props) => {
  return (
    <button
      className="button__close"
      onClick={() => props.action()}
      title={props.title}
      aria-label={props.title}
    >
      <Close />
    </button>
  );
};

// BUTTON - Text
export const Button = (props) => {
  const interactLink = props.url !== undefined;
  const interactClick = props.action !== undefined;

  const IconWrapper = (props) => {
    return <div className="icon">{props.icon || <ArrowRight />}</div>;
  };

  const Button = (props) => {
    return (
      <button
        className={
          "button__cta " +
          (props.secondary ? "secondary " : "") +
          (props.disabled ? "disabled " : "") +
          ("icon-" + props.iconPosition)
        }
        onClick={interactClick ? () => props.action() : undefined}
        aria-label={props.text}
        type={props.type}
        value={props.value}
      >
        {props.iconPosition && <IconWrapper icon={props.icon} />}
        {!props.noText && <div className="text">{props.text || "Tovább"}</div>} 
      </button>
    );
  };

  if (interactLink) {
    return (
      <a href={props.url} target={props.target || "_self"} rel="noreferrer">
        <Button {...props} />
      </a>
    );
  } else {
    return <Button {...props} />;
  }
};
