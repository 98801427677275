import { useEffect, useState } from "react";
import { cookieSetTour } from "@apis-common";
import { ArrowBackward, ArrowRight, Close, Done } from "@icons";
import { setTourOpen } from "@store-common";
import { tourDefaultStyle, tourSteps } from "@utils-common";

export const TourCard = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [tourCardStyle, setTourCardStyle] = useState(tourDefaultStyle);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => (prevStep + 1) % tourSteps.length);
  };

  const handlePreviousStep = () => {
    setCurrentStep(
      (prevStep) => (prevStep - 1 + tourSteps.length) % tourSteps.length
    );
  };

  const handleTourState = () => {
    setTourOpen(false);
    cookieSetTour(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Listen for window resize event
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const waitForDOM = () => {
      const targetElement = document.getElementById(
        tourSteps[currentStep].targetID
      );

      // If we can find targetElement
      if (targetElement) {
        let yOffset, xOffset;
        const targetElementRect = targetElement.getBoundingClientRect();

        // If target element position is bottom
        if (tourSteps[currentStep].position === "bottom") {
          yOffset = -190;
          xOffset = targetElementRect.width / 2;
        }

        // If target element position is top
        if (tourSteps[currentStep].position === "top") {
          console.log(screenWidth);

          yOffset = 235;
          if (screenWidth < 980) {
            xOffset = -90;
            if (screenWidth < 672) {
              xOffset = -60;
            }
          } else {
            xOffset = -120;
          }
        }

        // Add new style to card
        const newStyle = {
          display: "block",
          position: "absolute",
          left: `${targetElementRect.left + xOffset}px`,
          top: `${targetElementRect.top + yOffset}px`,
        };

        setTourCardStyle(newStyle);
      } else {
        setTimeout(waitForDOM, 100);
      }
    };

    waitForDOM();
  }, [currentStep]);

  return (
    <div
      className={`tour-card ${tourSteps[currentStep].position}`}
      style={tourCardStyle}
    >
      <div className="tour-card__header">
        <h5>Kezdeti lépések</h5>
        <button onClick={handleTourState}>
          <Close />
        </button>
      </div>

      <div className="tour-card__body">
        <div className="tour-card__body-illustration">
          {currentStep === tourSteps.length - 1 ? (
            <Done color="green" />
          ) : (
            <img
              src={tourSteps[currentStep].illustration}
              alt={tourSteps[currentStep].title}
            />
          )}
        </div>
        <div className="tour-card__body-description">
          <h6>{tourSteps[currentStep].title}</h6>
          <p>{tourSteps[currentStep].desc}</p>
        </div>
      </div>
      <div className="tour-card__navigation">
        <button
          onClick={handlePreviousStep}
          className={`${currentStep === 0 ? "hidden" : ""}`}
        >
          <ArrowBackward />
        </button>
        <div className="tour-card__navigation-dots">
          {tourSteps.map((_, index) => (
            <button
              onClick={() => setCurrentStep(index)}
              key={index}
              className={index === currentStep ? "active" : ""}
            ></button>
          ))}
        </div>
        <button
          onClick={handleNextStep}
          className={`${currentStep === tourSteps.length - 1 ? "hidden" : ""}`}
        >
          <ArrowRight />
        </button>
      </div>
    </div>
  );
};
