// You can create a bullet point list by
// including an array of strings within the content array.
// Eg.: {...., content: [["Item01", "Item02"]] }

// Otherwise, it will be a simple paragraph content.
// Eg.: {...., content: ["Text", "Text"] }

// Finally, you can combine both formats.
// Eg.: {...., content: [["Item01", "Item02"], "Text", "Text"] }

export const Discounts = [
  {
    title: "Mennyiségi kedvezmény",
    badge: "Akár -8%",
    content: [
      [
        "Nettó 1 millió Ft feletti vásárlás esetében -5% díjkedvezmény a végösszeg árából",
        "Nettó 5 millió Ft feletti vásárlás esetében -8% díjkedvezmény a végösszeg árából",
      ],
    ],
    shortDesc: "1 milló felett -5%, 5 millió felett -8% kedvezmény",
    discountActive: false,
    discountPercent: [5, 8],
  },
  {
    title: "Csomagkedvezmény",
    badge: "-2%",
    content: [
      "Kizárólag a domborzatmodell és ortofotó viszonylatában érvényesíthető. Ha a felhasználó mindkét terméket kiválasztja és megrendeli a bejelölt terület kapcsán, akkor a teljes árból 2% árkedvezményben részesül. A feltételek teljesülése esetén a csomagkedvezmény automatikusan levonásra kerül a listaárból.",
    ],
    shortDesc: "Domborzatmodell és ortofotó viszonylatában",
    discountActive: false,
    discountPercent: [2],
  },
  {
    title: "K+F kedvezmény",
    badge: "Egyedi kedvezmény",
    content: [
      "A felhasználónak megrendelést megelőzően lehetősége van megjelölni, hogy a megrendelt terméket K+F tevékenységhez használja fel. Ebben az esetben a megrendelés rögzítését követően az Envirosense Hungary Kft. felveszi a kapcsolatot a megrendelővel és egyedi tárgyalást kezdeményez. A K+F kedvezmény mértéke minden esetben az egyedi tárgyalás eredménye. Ha a tárgyalás során bebizonyosodik, hogy a megrendelő nem valós K+F tevékenységhez vásárolja a téradatot, a K+F kedvezményre nem jogosult.",
    ],
    shortDesc: "Egyedi kedvezményrendszer alapján",
    discountActive: false,
    discountPercent: [null],
  },
  {
    title: "Avulási kedvezmény",
    badge: "-50%",
    content: [
      "Az 5 évnél idősebb ortofotó termékre egységesen 50% kedvezményt biztosítunk a megrendelőknek. Az ortofotó készítésének időpontját a felhasználó a térképes keresőben ellenőrízheti. Az avulási kedvezményre való jogosultság esetén a kedvezmény a termék listaárából automatikusan levonásra kerül.",
    ],
    shortDesc: "5 évnél régebbi ortofotó termékre",
    discountActive: false,
    discountPercent: [50],
  },
];