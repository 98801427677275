// React
//import olMapScreenshot from "ol-map-screenshot";
import { createContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSnapshot } from "valtio";
// APIs
import { getGeocode } from "@apis-common";
// Assets
import CrosshairCursor from "@images-common/Cursor";
// Layout
import { MainBottom } from "@layout";
// Partials
import {
  MapConfig,
  availabilityLayer,
  backgroundLayer,
  ortoLayer,
  satalaiteLayer,
  useToastContext,
} from "@partials";
// Components
import { PinStyle } from "@service-roof";
// Store
import { StoreApp, setAside } from "@store-common";
import {
  StoreRequests,
  clearActivePrevPin,
  clearNewRequestPin,
  setActivePrevPin,
  setNewRequestPin,
  setNewRequestPinAddress,
} from "@store-roof";
import { checkAvailability } from "@utils-roof";
import { clusterCircleStyle, clusterCircles, clusters } from "./Clusters";
import { newPinLayer } from "./Layers";
// lodash
import throttle from "lodash.throttle";
import Feature from "ol/Feature";
// OpenLayers
import Map from "ol/Map";
import View from "ol/View";
import { defaults as controlDefaults } from "ol/control/defaults";
import { createEmpty, extend, getWidth } from "ol/extent";
import Point from "ol/geom/Point";
import { defaults as interactionDefaults } from "ol/interaction/defaults";
import { fromLonLat, transform, transformExtent } from "ol/proj";
import { getVectorContext } from "ol/render";
import { Fill, Style } from "ol/style";

// Create MapContext context
const MapContext = createContext();

// OPENLAYERS MAP COMPONENT
export const MapRoof = () => {
  // snapshots
  const { searchBounds, asideActive, searchPin } = useSnapshot(StoreApp);
  const { newRequestPin, activePrevPin, prevRequests, requestChangeTrack } =
    useSnapshot(StoreRequests);
  // map and layers
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [clustersSource, setClustersSource] = useState(null);
  const [newPinSource, setNewPinSource] = useState(null);
  // features and clusters
  const [features, setFeatures] = useState(null);
  const [clustersInit, setClustersInit] = useState(false);
  const clusterPinObject = useRef();
  // url param
  const [urlParam, setUrlParam] = useSearchParams();
  const [urlParamInit, setUrlParamInit] = useState(false);
  // toast
  const addToast = useToastContext();
  // Toolbar keys
  const [key, setKey] = useState("cursor");

  // Init map
  useEffect(() => {
    let options = {
      view: new View({
        projection: MapConfig.baseViewProjection,
        center: MapConfig.baseViewWMCenter,
        zoom: MapConfig.initZoom,
        minZoom: MapConfig.initZoom,
        maxZoom: MapConfig.maxZoom,
        extent: MapConfig.maxViewExtent(),
      }),
      layers: [
        backgroundLayer,
        satalaiteLayer,
        availabilityLayer,
        ortoLayer,
        clusters,
        clusterCircles,
        newPinLayer,
      ],
      controls: controlDefaults({
        zoom: false,
        rotate: false,
      }),
      interactions: interactionDefaults({
        pinchRotate: false,
      }),
    };
    let mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    setClustersSource(clusters.getSource());
    setNewPinSource(newPinLayer.getSource());

    return () => mapObject.setTarget(undefined);
  }, []);

  // --- FEATURES ---
  // Set user owned features from Store
  useEffect(() => {
    if (prevRequests.length) {
      const featuresArray = prevRequests
        .filter((req) => req.visible)
        .map((req) => {
          const coordinates = [req.lng, req.lat];
          const feature = new Feature(new Point(fromLonLat(coordinates)));
          feature.request_id = req.request_id;
          feature.status = req.status;
          feature.address = req.address;
          feature.selected = false;
          return feature;
        });
      setFeatures(featuresArray);
    }
  }, [prevRequests]);

  // Add features to cluster source
  useEffect(() => {
    if (clustersSource) {
      if (features) {
        // Clear cluster source if not empty
        if (!clustersSource.getSource().isEmpty())
          clustersSource.getSource().clear();
        // Add features to cluster source
        clustersSource.getSource().addFeatures(features);
        // Update init state
        setClustersInit(true);
      }
    }
  }, [clustersSource, features]);

  // Handle freshly requested pin
  useEffect(() => {
    if (requestChangeTrack > 0) {
      // Find the freshly requested item
      const freshRequest = prevRequests.find(
        (item) => item.request_id === requestChangeTrack
      );

      if (freshRequest) {
        // Create a new feature
        const freshFeature = new Feature(
          new Point(fromLonLat([freshRequest.lng, freshRequest.lat]))
        );

        // Add parameters to pin
        freshFeature.request_id = freshRequest.request_id;
        freshFeature.status = freshRequest.status;
        freshFeature.address = freshRequest.address;
        freshFeature.selected = false;

        // Add fresh feature to the existing features
        setFeatures((prevState) => ({
          ...prevState,
          freshFeature,
        }));

        // Add feature to the clusterSource
        clustersSource.getSource().addFeature(freshFeature);
      }
    }
  }, [requestChangeTrack, clustersSource, prevRequests]);

  // --- URL SEARCH PARAM ---
  // Check Initial UrlParam
  useEffect(() => {
    if (clustersInit && !urlParamInit) {
      const initId = urlParam.get("tet");

      if (initId) {
        // Find ID inside prevRequests
        let findRequestID = prevRequests.find(
          (e) => e.request_id.toString() === initId
        );

        // Go ahead if ID matched
        if (findRequestID) {
          // Transform coordinates
          const coords = transform(
            [findRequestID.lng, findRequestID.lat],
            "EPSG:4326",
            "EPSG:3857"
          );
          // Set cluster pin
          setActivePrevPin({
            request_id: findRequestID.request_id,
            coordinates: coords,
          });
        } else {
          // Throw error toast to user if ID doesn't match
          addToast([
            "error",
            `A keresett azonosító (TET-${initId}) nem elérhető!`,
          ]);
        }
      }
      // Set init StoreApp
      setUrlParamInit(true);
    }
  }, [clustersInit, urlParam, prevRequests, urlParamInit, addToast]);

  // Handle UrlParam Change when activePrevPin StoreApp changes
  useEffect(() => {
    if (urlParamInit) {
      if (activePrevPin) {
        // Set URLSearchParam -> ID
        setUrlParam({ tet: activePrevPin.request_id });
      } else {
        // Unset URLSearchParam -> ID
        urlParam.delete("tet");
        setUrlParam(urlParam);
      }
    }
  }, [urlParamInit, activePrevPin, urlParam, setUrlParam]);

  // --- PIN CHANGES ---
  // Handle ClusterPin StoreApp changes
  useEffect(() => {
    if (clustersSource) {
      if (activePrevPin) {
        // Set PREVIOUS clusterPinObject unselected and update style
        if (clusterPinObject.current) {
          clusterPinObject.current.selected = false;
          clusterPinObject.current.setStyle(PinStyle());
        }

        // Set clusterPinObject selected and update style
        clusterPinObject.current = clustersSource
          .getSource()
          .getFeaturesAtCoordinate(activePrevPin.coordinates)[0];

        // Move view to pin
        const getView = map.getView();
        getView.animate({
          duration: 400,
          center: activePrevPin.coordinates,
          zoom:
            getView.getZoom() <= MapConfig.pinZoom
              ? MapConfig.pinZoom
              : getView.getZoom(),
        });

        // Update pin style
        clusterPinObject.current.selected = true;
        clusterPinObject.current.setStyle(PinStyle());
      } else {
        // Set PREVIOUS clusterPinObject unselected and update style
        if (clusterPinObject.current) {
          clusterPinObject.current.selected = false;
          clusterPinObject.current.setStyle(PinStyle());
        }
      }
    }
  }, [activePrevPin, clustersSource, map]);

  // Handle NewPin StoreApp changes
  useEffect(() => {
    if (newPinSource) {
      if (newRequestPin) {
        // Create new selection pin
        const newPinFeature = new Feature({
          geometry: new Point(
            fromLonLat([newRequestPin.lng, newRequestPin.lat])
          ),
        });

        // Refresh component usestates
        setTimeout(() => {
          setAside(false);
          setAside(true);
        }, 500);

        // Clear newPinSource then add selection pin
        newPinSource.clear();
        newPinSource.addFeature(newPinFeature);

        // Move view to pin
        const getView = map.getView();
        getView.animate({
          duration: 400,
          center: fromLonLat([newRequestPin.lng, newRequestPin.lat]),
          zoom:
            getView.getZoom() <= MapConfig.pinZoom
              ? MapConfig.pinZoom
              : getView.getZoom(),
        });

        // Reverse Geocode pin address
        if (newRequestPin.available) {
          getGeocode(newRequestPin.lat, newRequestPin.lng);
        } else {
          setNewRequestPinAddress("");
        }
      } else {
        // Clear newPinSource
        newPinSource.clear();
      }
    }
  }, [newRequestPin, newPinSource, map]);

  // Resize map if a asideActive StoreApp changes
  useEffect(() => {
    if (map) {
      setTimeout(function () {
        map.updateSize();
      }, 0); //setTimeout needed
    }
  }, [asideActive, map]);

  // --- INTERACTION ---
  // Handle Map HOVER and CLICK events
  useEffect(() => {
    if (map) {
      // Map Hover events
      map.on(
        "pointermove",
        throttle((e) => {
          // Utility function to check features under pixel
          const getFeature = (pixel) =>
            map.forEachFeatureAtPixel(
              pixel,
              (feature) => {
                return feature;
              },
              {
                layerFilter: (layer) => {
                  return layer !== newPinLayer && layer !== availabilityLayer;
                },
              }
            );

          // Change cursor if it is hovered on feature
          map.getViewport().style.cursor = getFeature(e.pixel)
            ? "pointer"
            : CrosshairCursor;
        }, 100)
      );
    }
  }, [map]);

  useEffect(() => {
    const handleMapClick = (e) => {
      clusters.getFeatures(e.pixel).then((features) => {
        // Feature is available
        if (features.length > 0) {
          const clusterMembers = features[0].get("features");

          if (clusterMembers.length > 1) {
            const extent = createEmpty();
            clusterMembers.forEach((feature) =>
              extend(extent, feature.getGeometry().getExtent())
            );
            const view = map.getView();
            const resolution = map.getView().getResolution();
            if (
              view.getZoom() === view.getMaxZoom() ||
              (getWidth(extent) < resolution && getWidth(extent) < resolution)
            ) {
              clusterCircles.setStyle(clusterCircleStyle);
            } else {
              view.fit(extent, {
                duration: 500,
                padding: [250, 250, 250, 250],
              });
            }
          }

          if (clusterMembers.length === 1) {
            if (newRequestPin) {
              clearNewRequestPin();
            }

            let clickedClusterPin = clustersSource
              .getSource()
              .getClosestFeatureToCoordinate(e.coordinate);

            setActivePrevPin({
              request_id: clickedClusterPin.request_id,
              coordinates: clickedClusterPin.getGeometry().getCoordinates(),
            });
          }
        }

        // Feature is not available
        else {
          if (activePrevPin) {
            clearActivePrevPin();
          }

          const [convertedNewPinLng, convertedNewPinLat] = transform(
            e.coordinate,
            "EPSG:3857",
            "EPSG:4326"
          );

          const newPinAvailability = checkAvailability(
            map,
            e.pixel,
            availabilityLayer
          );
          setNewRequestPin(
            convertedNewPinLng,
            convertedNewPinLat,
            newPinAvailability
          );
        }
      });
    };

    if (map && key !== "ruler") {
      map.on("click", handleMapClick);
    }

    return () => {
      if (map) {
        map.un("click", handleMapClick);
      }
    };
  }, [
    map,
    asideActive,
    activePrevPin,
    newRequestPin,
    clustersSource,
    prevRequests,
    key,
  ]);

  // Navigate to the searched area bounds
  useEffect(() => {
    if (map) {
      if (searchBounds.length > 0) {
        const transSearchBounds = transformExtent(
          searchBounds,
          "EPSG:4326",
          "EPSG:3857"
        );
        let getView = map.getView();
        getView.fit(transSearchBounds, { duration: 600 });

        if (searchPin) {
          const handleAvailabilityLayerLoad = () => {
            setNewRequestPin(
              searchPin[0],
              searchPin[1],
              checkAvailability(map, searchPin, availabilityLayer)
            );
          };

          availabilityLayer.on("postrender", handleAvailabilityLayerLoad);

          return () => {
            availabilityLayer.un("postrender", handleAvailabilityLayerLoad);
          };
        }

        if (!searchPin && newRequestPin) {
          clearNewRequestPin();
        }
      }
    }
  }, [searchBounds, activePrevPin, map]);

  // Clip Google satalite layer with available area
  useEffect(() => {
    if (map) {
      // Set Extent
      availabilityLayer.getSource().on("addfeature", () => {
        satalaiteLayer.setExtent(availabilityLayer.getSource().getExtent());
      });
      // Style cliping
      const style = new Style({
        fill: new Fill({
          color: "black",
        }),
      });
      // Clip layer
      satalaiteLayer.on("postrender", (e) => {
        const vectorContext = getVectorContext(e);
        e.context.globalCompositeOperation = "destination-in";
        availabilityLayer.getSource().forEachFeature(function (feature) {
          vectorContext.drawFeature(feature, style);
        });
        e.context.globalCompositeOperation = "source-over";
      });
    }
  }, [map]);

  return (
    <>
      <MapContext.Provider value={{ map: map }}>
        <div className="map" ref={mapRef} />
      </MapContext.Provider>
      <MainBottom map={map} setKey={setKey} />
    </>
  );
};
