import QRCode from "qrcode.react";

const QRGenerator = ({ value }) => {
  return (
    <div>
      <QRCode
        id="QR_CODE_ID"
        value={value}
        size={100}
        bgColor="#FFF"
        fgColor="#000"
        includeMargin
        level={"H"}
      />
    </div>
  );
};

export default QRGenerator;
