// Imports
import { proxy } from "valtio";
import { addDaysToDate } from "@utils-common";

// Initial data structures
const initActiveRequestGeostoreData = {
  request_id: 0,
  area: "",
  address: "",
  status: "",
  deadline: "",
  layers: [],
  user: {
    id: "",
    first_name: "",
    last_name: "",
  },
};

// STORE
export const StoreRequests = proxy({
  // Previous requests
  prevRequests: [],

  // Detailed data for active request
  activeRequestData: initActiveRequestGeostoreData,

  // Actual bottom panel StoreApp
  panelBottomActive: false,
});

// Set previous requests from API
export const setPrevRequests = (requests) => {
  let prevRequestsArray = [];

  // Mapping data and add to array
  requests.map((request) => {
    let requestObj = {
      request_id: `ENV-${request.Map.request_id}`,
      request_data: JSON.parse(request.Map.request_data),
      address: JSON.parse(request.Map.resolved_addr),
      status: request.Map.status,
      user: {
        id: request.User.id,
        first_name: request.User.first_name,
        last_name: request.User.last_name,
      },
    };
    return prevRequestsArray.push(requestObj);
  });
  StoreRequests.prevRequests = prevRequestsArray;
};

export const setRequest = (dataObj) => {
  const { request_id, address, status, user, request_data } = dataObj;
  const { totalArea, polygons, survey_date, selectedLayers } = request_data;

  console.log(request_data);

  StoreRequests.activeRequestData = {
    request_id: request_id,
    polygons: polygons.length,
    area: totalArea,
    city: address,
    status: status,
    layers: selectedLayers,
    deadline: addDaysToDate(survey_date, 21),
    user: user,
  };
};

// Acitvate or deactivate bottom panel
export const setPanelBottomActive = (value) => {
  StoreRequests.panelBottomActive = value;
};