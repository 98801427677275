import { setAside } from "@store-common";
import { setNewRequestData } from "@store-forest";
import { setNewRequestDataGeoStore } from "@store-geostore";
import {
  drawVectorLayer,
  modify,
  modifyStyle,
  source,
  styleFunction,
  removeHighlightStyle,
  tipPoint,
} from "./layer.toolbar";
import { checkDrawVectorLayerExist } from "./utils.toolbar";
import { Draw, Select } from "ol/interaction";
import throttle from 'lodash/throttle';
import {click, pointerMove} from 'ol/events/condition';
import { availabilityLayer } from "@partials";

let draw, removeHighlight, remove;

// Draw interaction
const createDraw = ( map, interactionType, activeService ) => {
  // Check if the polygon vector layer exist
  checkDrawVectorLayerExist(map);

  // Remove unneeded interactions
  map.removeInteraction(removeHighlight);
  map.removeInteraction(remove);

  const addInteraction = () => {
    const drawType = interactionType;
    const idleTip = "Kattintson a kijelölés megkezdéséhez";
    let tip = idleTip;

    draw = new Draw({
      source: source,
      type: drawType,
      style: (feature) => {
        return styleFunction(feature, true, drawType, tip);
      },
    });

    draw.on("drawstart", () => {
      modify.setActive(false);
      tip = null;
    });

    // Draw end
    draw.on("drawend", () => {
      modifyStyle.setGeometry(tipPoint);
      modify.setActive(true);
      map.once("pointermove", () => {
        modifyStyle.setGeometry();
      });
    });

    drawVectorLayer.getSource().on('change',
      throttle(() => {
        handleDrawChange(activeService);
      },100)
    );

    // Add the interactions
    map.addInteraction(draw);
    map.addInteraction(modify);
  };

  addInteraction();
};

// Erease interaction
const eraseDraw = ( map, activeService ) => {
  // Remove unneeded interactions
  map.removeInteraction(draw);
  map.removeInteraction(modify);

  // Define remove highlighting interaction
  removeHighlight = new Select({
    source: source,
    condition: pointerMove,
    style: removeHighlightStyle,
    layers: [drawVectorLayer],
  });

  // Define remove interaction
  remove = new Select({
    source: source,
    condition: click,
    layers: [drawVectorLayer],
  });

  // Remove interaction listener
  remove.on('select', function (e) {
    const selectedFeatures = e.selected;

    if (selectedFeatures.length > 0) {
      // Remove the selected feature from the drawVectorLayer source
      drawVectorLayer.getSource().removeFeature(selectedFeatures[0]);
    }
  });

  // Handle change
  drawVectorLayer.getSource().on('change', () => handleDrawChange(activeService));

  // Add the interactions
  map.addInteraction(removeHighlight);
  map.addInteraction(remove);
};

// Clear interaction
const clearDraw = ( map ) => {
  if (map) drawVectorLayer.getSource().clear();
  if (map && draw) map.removeInteraction(draw);
};

// Handle drawVectorLayer feature changes
export const handleDrawChange = ( activeService ) => {
  const drawFeatures = drawVectorLayer.getSource().getFeatures();

  if (drawFeatures.length) {
    // Check availability by compareing the feature coordinates to the availabilityLayer
    const isAvailable = !drawFeatures.some((feature) => {
      const coordinates = feature.getGeometry().getCoordinates();
      return coordinates[0].some((coordinate) => {
        return !availabilityLayer.getSource().getFeaturesAtCoordinate(coordinate).length;
      });
    });

    // Set Stores
    activeService === 0
      ? setNewRequestDataGeoStore(drawFeatures, isAvailable)
      : setNewRequestData(drawFeatures, isAvailable);

    // Open Aside
    setAside(true);
  } else {
    setAside(false);
  }
};

export { draw, createDraw, eraseDraw, clearDraw };
