// Imports
import { useSnapshot } from "valtio";
import {
  StoreModel,
  handleFeatureChange,
  handleViewDetailChange,
  handleViewDetailOpen,
  handleViewPanelTypeOpen,
  handleViewTypeChange,
} from "@service-roof";
import { ArrowDropDown, Done, Features, LidarRes, ViewShow } from "@icons";
import { StoreRequests } from "@store-roof";

// VIEWBAR
const Viewbar = () => {
  const { pcd_file } = useSnapshot(StoreRequests.activeRequestData);
  const {
    viewbarTypeOpen,
    currentView,
    views,
    viewbarLidarDetailOpen,
    lidarDetailAuto,
    lidarDetail,
    features,
  } = useSnapshot(StoreModel.view);

  const viewOptions = views.map((option, i) => {
    // Handle if no LiDAR file
    if (option === "LiDAR" && !pcd_file) return null;
    return <TypeOption key={i} id={i} type={option} />;
  });

  const lidarDetailOptionsArray = ["Auto", "100%", "50%", "33%", "25%", "20%"];
  const lidarDetailOptions = lidarDetailOptionsArray.map((option, i) => {
    return <DetailOption key={i} id={i} detail={option} />;
  });
  const lidarDetailPercentage =
    parseFloat((1 / lidarDetail) * 100).toFixed() + "%";

  const viewFeatures = features.map((feature, i) => {
    if (feature.available) {
      return <Feature key={i} id={i} feature={feature} />;
    }
    return null;
  });

  return (
    <div className="viewbar">
      <div className={`viewbar__type ${viewbarTypeOpen ? "opened" : ""}`}>
        <button
          className="selected"
          onClick={(e) => {
            e.stopPropagation();
            handleViewPanelTypeOpen();
          }}
        >
          <ViewShow />
          <div className="label">
            <span>{viewbarTypeOpen ? "Nézet" : views[currentView]}</span>
            <ArrowDropDown />
          </div>
        </button>
        <div className="options">{viewOptions}</div>
      </div>

      {currentView === 3 && (
        <div
          className={`viewbar__lidardetail ${
            viewbarLidarDetailOpen ? "opened" : ""
          }`}
        >
          <button
            className="selected"
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetailOpen();
            }}
          >
            <LidarRes />
            <div className="label">
              <span>
                {viewbarLidarDetailOpen
                  ? "Részletesség"
                  : lidarDetailAuto
                  ? "Auto - " + lidarDetailPercentage
                  : lidarDetailPercentage}
              </span>
              <ArrowDropDown />
            </div>
          </button>
          <div className="options">{lidarDetailOptions}</div>
        </div>
      )}

      <div className="features">{viewFeatures}</div>
    </div>
  );
};

const TypeOption = ({ id, type }) => {
  const { currentView } = useSnapshot(StoreModel.view);

  return (
    <button
      className={currentView === id ? "active" : ""}
      onClick={(e) => {
        e.stopPropagation();
        handleViewTypeChange(id);
      }}
    >
      <Done />
      <span>{type}</span>
    </button>
  );
};

const DetailOption = ({ id, detail }) => {
  const { lidarDetail, lidarDetailAuto } = useSnapshot(StoreModel.view);

  return (
    <button
      className={
        lidarDetailAuto
          ? id === 0
            ? "active"
            : ""
          : lidarDetail === id
          ? "active"
          : ""
      }
      onClick={(e) => {
        e.stopPropagation();
        handleViewDetailChange(id);
      }}
    >
      <Done />
      <span>{detail}</span>
    </button>
  );
};

const Feature = ({ id, feature }) => {
  return (
    <button
      className={feature.active ? "active" : ""}
      title={feature.name}
      onClick={(e) => {
        e.stopPropagation();
        handleFeatureChange(id);
      }}
    >
      <Features type={feature.icon} />
    </button>
  );
};

export default Viewbar;
