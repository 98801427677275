export const CheckboxForm = ({ label, name, value, onChange, checked, hasError = false }) => {
  return (
    <div  className={`checkboxForm${hasError ? ' error' : ''}`}>
      <label name={name}>
        <input
          name={name}
          type="checkbox"
          value={value}
          onChange={onChange}
          checked={checked}
        />
        <span className="checkboxForm__checkmark"></span>
        <span>{label}</span>
      </label>
    </div>
  );
};
