// imports
import { useState, useEffect } from 'react';
import { useSnapshot } from "valtio";
import { StoreApp, toogleActiveProjection } from "@store-common";
import { Convert } from "@icons";
import { transformCoords, formatCoords } from "@utils-common";


export const Coordinates = ({ map }) => {
  const { projections, activeProjection } = useSnapshot(StoreApp);
  const [ coords, setCoords ] = useState(null);
  const [ transformedCoords, setTransformedCoords ] = useState(null);

  // Handle Coords update 
  useEffect(() => {
    if (!map) return;

    const updateCoords = (e) => {
      setCoords(e.coordinate);
    };

    map.on('pointermove', updateCoords);
    return () => { map.un('pointermove', updateCoords); };
  }, [map]);

  // Handle Coords projection transform 
  useEffect(() => {
    if (!coords) return;

    setTransformedCoords(transformCoords(coords, 'EPSG:3857', projections[activeProjection].epsg));
  }, [coords, activeProjection]);

  if (!transformedCoords) return;
  return (
    <div className="footer__coordinates">
      <button
        onClick={toogleActiveProjection}
        title="Koordináta rendszer módosítása"
      >
        <Convert color="white" />
        <span className="span--bold">{projections[activeProjection].epsg} - {projections[activeProjection].label}</span>
      </button>
      <hr />
      <span>{formatCoords(transformedCoords, projections[activeProjection].decimals)}</span>
    </div>
  );
};
