import { Discount, Edit2 } from "@icons";
import { Button } from "@molecules";
import { useModalContext } from "@partials";
import { CartMinimum, setPanelState } from "@store-geostore";
import {
  checkPanelDataInstantPurchase,
  mappingSelectedLayers,
} from "@utils-geostore";

export const PanelGeostoreNewRequestSumDetails = ({
  panelData,
  totalAmount,
  inputSQMValue,
  handleForm,
  panelInfoDiscount,
  panelInfoCartMinimum,
  setRequestComment,
  requestComment,
}) => {
  const { setModalType, setIsModalOpen } = useModalContext();
  const openModal = (e, modalType) => {
    e.preventDefault();
    setModalType(modalType);
    setIsModalOpen(true);
  };
  const instantPurchase = checkPanelDataInstantPurchase(
    mappingSelectedLayers(panelData)
  );

  const handleCommentOnChange = (e) => {
    setRequestComment(e.target.value);
  };
  
  return (
    <>
      <div>
        <div className="panel__content-geo__details__title">
          <h5> Részletek</h5>
          <button onClick={() => setPanelState(0)}>
            Termékek szerkesztése
            <Edit2 color />
          </button>
        </div>
        <div className="panel__content-geo__details__list">
          {panelData
            .filter((item) => item.checkbox)
            .map((item, index) => (
              <ListItem
                key={item.id}
                hr={
                  index !== panelData.filter((item) => item.checkbox).length - 1
                }
                item={item}
                inputSQMValue={inputSQMValue}
              />
            ))}
        </div>
        {panelInfoDiscount && (
          <div className="panel__content-geo__details__discount">
            <div className="panel__content-geo__details__discount-item">
              <div>
                <div className="panel__content-geo__details__discount-item__title">
                  <span>
                    <Discount />
                  </span>{" "}
                  <h5>{panelInfoDiscount.title}</h5>
                </div>
                <span>{panelInfoDiscount.shortDesc}</span>
              </div>
              <h6>-{panelInfoDiscount.discount.toLocaleString('hu-HU')} FT</h6>
            </div>
          </div>
        )}
        {panelInfoCartMinimum && (
          <div className="panel__content-geo__details__discount">
            <div className="panel__content-geo__details__discount-item">
              <div>
                <div className="panel__content-geo__details__discount-item__title">
                  <h5>{CartMinimum.title}</h5>
                </div>
                <span>{CartMinimum.shortDesc}</span>
              </div>
              <h6>+{CartMinimum.extraAmount.toLocaleString('hu-HU')} FT</h6>
            </div>
          </div>
        )}
        <div className="panel__content-geo__details__comment">
          <h5>Megjegyzés</h5>
          <textarea
            value={requestComment}
            onChange={handleCommentOnChange}
            placeholder="Opcionális megjegyzéseit írja ide..."
          />
        </div>
      </div>
      <div>
        <div className="panel__content-geo__details__sum">
          <h4>Összesen</h4>
          <h4>
            {" "}
            {(totalAmount - (panelInfoDiscount.discount ?? 0) + (panelInfoCartMinimum ? CartMinimum.extraAmount : 0)).toLocaleString('hu-HU')} FT
            {instantPurchase && <span>-tól*</span>}
          </h4>
        </div>
        <div className="panel__content-geo__details__action">
          <Button
            iconPosition="after"
            text={instantPurchase ? "Ajánlatkérés" : "Adatok igénylése"}
            type="submit"
            action={() => handleForm()}
          />
          <div className="panel__content-geo__details__action-text">
            <div>
              <span>Az itt feltüntetett árak az Áfá-t nem tartalmazzák és az elérhető legmagasabb kedvezménnyel kerülnek számításra. A kijelölt területek 0.01 km²-re (1 hektár) kerekítve kerül felszámításra.</span>
            </div>
            {instantPurchase && (
              <div>
                <span>
                  *Az összeállított megrendelésben szereplő néhány tételre
                  egyedi árazás vonatkozik. Ajánlatkérést követően tudunk pontos
                  összeget kalkulálni.
                </span>
              </div>
            )}
          </div>
          <div className="panel__content-geo__details__action-links">
            <a href="#" onClick={(e) => openModal(e, "discount")}>
              Kedvezmények listája
            </a>
            <span></span>
            <a href="#" onClick={(e) => openModal(e, "purchasingProcess")}>
              Vásárlás folyamata
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const ListItem = ({ item, hr = true, inputSQMValue }) => {
  return (
    <>
      <div className="panel__content-geo__details__list-item">
        <div>
          <h5>{item.title}</h5>
          <span>
            {item.price !== null ? (
              !item.instantPurchase ? (
                <>
                  {`${item.price.toLocaleString('hu-HU')} Ft/${item.unit}`}
                  <strong>
                    <span className="circle"></span>
                    Egyedi árazás alapán<sup>*</sup>{" "}
                  </strong>
                </>
              ) : (
                `${item.price.toLocaleString('hu-HU')} Ft/${item.unit} x ${inputSQMValue}`
              )
            ) : (
              <strong>
                Egyedi árazás alapán<sup>*</sup>{" "}
              </strong>
            )}{" "}
          </span>
        </div>
        <h6>
          {item.instantPurchase &&
            Math.round(inputSQMValue * item.price).toLocaleString('hu-HU') + " FT"}
        </h6>
      </div>
      {hr && <hr />}
    </>
  );
};
