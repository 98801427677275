// Imports
import proj4 from "proj4";
import { transform } from "ol/proj";
import { register } from "ol/proj/proj4";

// Define EPSG:23700
proj4.defs(
  "EPSG:23700",
  "+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs"
);
register(proj4);

export const transformCoords = (c, fp, tp) => {
  const tc = transform(c, fp, tp);
  return tc;
};

export const formatCoords = (c, d) => {
  return `${c[1].toFixed(d)}, ${c[0].toFixed(d)}`;
};

// Contains true key in object
export const containsTrueKey = (obj) => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === true) {
      return true;
    }
  }
  return false;
};

// Preprocess tools object
export const preprocessToolsObject = (obj) => {
  const newObj = {};
  let firstKey = null;

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === true) {
      if (firstKey === null) {
        firstKey = key;
        newObj[firstKey] = true;
      } else {
        newObj[key] = false;
      }
    }
  }

  return newObj;
};
