// Imports
import { cookieGetToken, urls } from "api/common";
import { SetDomain } from "data/common";
import { CheckError } from "utils/common";

// Get Domain details
export const getDomain = async (domain_id) => {
  await fetch(urls.domain + domain_id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => SetDomain(actualData))
    .catch((error) => console.log(error));
};
