// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import {
  cookieGetGeoStoreData,
  cookieGetGeoStoreInfo,
  cookieRemoveGeoStore,
} from "@apis-common";
import { postNewRequestGeostore } from "@apis-geostore";
import { ButtonClose } from "@molecules";
import { useToastContext } from "@partials";
import { PanelNoData } from "@service-geostore";
import { StoreApp, setAside, setToolbarHidden } from "@store-common";
import {
  StoreNewRequestGeoStore,
  StoreProductsGeoStore,
} from "@store-geostore";
import {
  calculateTotalPrice,
  createRequestObject,
  mapProductsData,
  mappingSelectedLayers,
} from "@utils-geostore";
import { PanelGeostoreNewRequestContent } from "./PanelGeostoreNewRequestContent";
import { PanelGeostoreNewRequestInfo } from "./PanelGeostoreNewRequestInfo";
import { calculateDiscount } from "utils/service-geostore/discount";
import { calculateCartMinimum } from "utils/service-geostore/cartminimum";

// Main Panel component
export const PanelGeostore = () => {
  const addToast = useToastContext();
  const { asideActive, activeService } = useSnapshot(StoreApp);
  const { products } = useSnapshot(StoreProductsGeoStore);
  const { panelInfo: panelInfoGeoStore } = useSnapshot(StoreNewRequestGeoStore);
  const [panelInfo, setPanelInfo] = useState({});
  const [panelData, setPanelData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [panelInfoDiscount, setPanelInfoDiscount] = useState(false);
  const [panelInfoCartMinimum, setPanelInfoCartMinimum] = useState(false);
  const [requestComment, setRequestComment] = useState("");

  const handleForm = () => {
    const formattedPaneData = mappingSelectedLayers(panelData);
    const formattedNewRequest = createRequestObject(
      formattedPaneData,
      panelInfo,
      totalAmount,
      panelInfoDiscount,
      requestComment
    );

    const requestBody = {
      building_type: "string",
      building_features: 0,
      service: "GeoStore",
      lat: panelInfo.lat,
      lon: panelInfo.lon,
      resolved_addr: JSON.stringify(panelInfo.city),
      request_data: JSON.stringify(formattedNewRequest),
    };

    postNewRequestGeostore(
      requestBody,
      addToast,
      setPanelData,
      products,
      setPanelInfo
    );
  };

  useEffect(() => {
    const totalAmount = calculateTotalPrice(
      panelData,
      panelInfo?.total_area ?? 0
    );

    // TODD: Implement valid date
    const MOCK_INACTIVE_OUTDATED_DISCOUNT = "2022.04.01.";
    // const MOCK_ACTIVE_OUTDATED_DISCOUNT = "2009.04.01.";

    setTotalAmount(totalAmount);

    // TODD: Implement valid date
    calculateDiscount(
      totalAmount,
      panelData,
      setPanelInfoDiscount,
      MOCK_INACTIVE_OUTDATED_DISCOUNT
    );

    // Calculate cart minimum
    calculateCartMinimum(
      totalAmount,
      setPanelInfoCartMinimum
    );
  }, [panelData, panelInfo]);

  useEffect(() => {
    setPanelInfo(panelInfoGeoStore);
  }, [panelInfoGeoStore]);

  useEffect(() => {
    // Init panel info
    const initPanelInfo = cookieGetGeoStoreInfo();
    if (initPanelInfo) setPanelInfo(initPanelInfo);

    // Init panel data
    const initPanelData = cookieGetGeoStoreData();

    if (products.length > 0) {
      const mappedProds = mapProductsData(products);
      if (initPanelData && initPanelData.slugArray.length > 0) {
        const checkedProds = mappedProds.map((prod) => {
          return {
            ...prod,
            checkbox: initPanelData.slugArray.includes(prod.slug),
          };
        });
        setPanelData(checkedProds);
      } else {
        setPanelData(mappedProds);
      }
    }
  }, [products]);

  if (asideActive && typeof panelInfo?.available !== "undefined") {
    return (
      <>
        <div className="panel">
          <div className="panel__header">
            <div className="panel__header__content">
              <h5>{panelInfo?.polygons?.length ?? 0} terület kijelölve</h5>
              <h2>{panelInfo?.total_area ?? 0} km²</h2>
              <p>{panelInfo?.city}</p>
            </div>
            <ButtonClose
              action={() => {
                setAside(false);
                setToolbarHidden(false, activeService);
                cookieRemoveGeoStore();
              }}
            />
          </div>
          {panelInfo.available ? (
            <PanelGeostoreNewRequestContent
              setPanelData={setPanelData}
              panelData={panelData}
              totalAmount={totalAmount}
              setTotalAmount={setTotalAmount}
              inputSQMValue={panelInfo?.total_area ?? 0}
              handleForm={handleForm}
              panelInfoDiscount={panelInfoDiscount}
              panelInfoCartMinimum={panelInfoCartMinimum}
              setRequestComment={setRequestComment}
              requestComment={requestComment}
            />
          ) : (
            <PanelNoData />
          )}
        </div>
        <PanelGeostoreNewRequestInfo />
      </>
    );
  } else {
    return <div className="panel" />;
  }
};
