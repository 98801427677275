// Imports
import { proxy } from "valtio";

// STORE
export const StoreDomain = proxy({
  available_services: [],
  domain_id: "",
  domain_name: "",
  company_name: "",
  company_postal: 0,
  company_city: "",
  company_address: "",
  company_vat: "",
  on_trial: true,
  is_active: false,
  is_super_domain: true,
  parent_id: "",
  cross_sharing: false,
  users: [],
});

// Set domain data from api data
export const SetDomain = (data) => {
  // Keys that need to be stored
  const domainDataToStore = [
    "domain_id",
    "domain_name",
    "company_name",
    "company_postal",
    "company_city",
    "company_address",
    "company_vat",
    "on_trial",
    "is_active",
    "is_super_domain",
    "parent_id",
    "cross_sharing",
    "users",
  ];

  // Store the values
  domainDataToStore.forEach((key) => {
    StoreDomain[key] = data[key];
  });

  StoreDomain.available_services = JSON.parse(data.available_services);
};
