// Imports
import { cookieGetToken, urls } from "api/common";
import { getPCDFile } from "@apis-roof";
import { setActiveRequestData } from "@store-roof";
import { CheckError } from "utils/common";

// Login
export const getRequestByID = async (request_id, message) => {
  await fetch(urls.getRequestByID + request_id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) =>
      CheckError(
        response,
        message,
        false,
        "Probléma lépett fel az adatok lekérdezésekor."
      )
    )
    .then((actualData) => {
      // Set returned requests data
      setActiveRequestData(actualData);
      // Try LiDAR PCD file
      if (actualData.model_json !== null) getPCDFile(request_id, message);
    })
    .catch((error) => console.log(error));
};
