import * as toGeoJSON from "@mapbox/togeojson";
import { setPolygonsArray, setShapesFiles } from "@store-common";
import shp from "shpjs";
import { transform } from "ol/proj";
import Polygon from "ol/geom/Polygon.js";
import { Feature } from "ol";
import { uploadVectorLayer } from "./layer.upload";
import { drawVectorLayer } from "../toolbar/layer.toolbar";
import { zoomToLayerExtent } from "@partials";


const FilExtensions = ["kml", "geojson", "shp"];
const SHPExtension = ["dbf", "shp", "prj"];

export const checkUploadVectorLayerExist = (map) => {
  let uploadVectorLayerExist = false;
  map.getLayers().forEach(function(layer) {
    if (layer.get('title') === 'upload-layer') {
      uploadVectorLayerExist = true;
    }
  });
  if (!uploadVectorLayerExist) {
    // Add layer
    map.addLayer(uploadVectorLayer);

    // Add change event
    let uploadFeatureAddTimeout, uploadFeaturesLength;
    uploadVectorLayer.getSource().on('change', () => {
      clearTimeout(uploadFeatureAddTimeout);
  
      uploadFeatureAddTimeout = setTimeout(() => {
        const currentUploadFeaturesLength = uploadVectorLayer.getSource().getFeatures().length;
  
        if (currentUploadFeaturesLength > 0 && currentUploadFeaturesLength !== uploadFeaturesLength) {
          uploadFeaturesLength = currentUploadFeaturesLength;
          zoomToLayerExtent(map, uploadVectorLayer, 100);
        }
      }, 500);
    });
  }
};

export const checkExtensionsInArray = (fileArray) => {
  let hasDbf = false;
  let hasShp = false;
  let hasPrj = false;

  for (const file of fileArray) {
    if (file.ext === "dbf") {
      hasDbf = true;
    }
    if (file.ext === "shp") {
      hasShp = true;
    }
    if (file.ext === "prj") {
      hasPrj = true;
    }
  }

  return { hasDbf, hasShp, hasPrj };
};

export const checkFileTypeSHP = (fileArray) =>
  fileArray.some((file) => SHPExtension.includes(file.ext));

export const checkFileSHP = (fileArray) => {
  let response = true;
  if (
    (fileArray.dbf === null) |
    (fileArray.shp === null) |
    (fileArray.prj === null)
  )
    response = false;
  return response;
};

export const checkFileNameSHP = (fileName) => SHPExtension.includes(fileName);

export const fileUploadUtility = async (file) => {
  const { name } = file;
  const ext = name.split(".").pop();

  const newFile = {
    name: name,
    ext: ext,
    file: file,
  };

  // Init reader
  const reader = new FileReader();
  reader.readAsText(file, "UTF-8");

  // If file is KMLF
  if (ext === FilExtensions[0]) {
    reader.onload = (e) => {
      const kmlText = e.target.result;
      const kmlParser = new DOMParser();
      const kmlDocument = kmlParser.parseFromString(kmlText, "application/xml");
      const geoJson = toGeoJSON.kml(kmlDocument);
      return setPolygonsArray(geoJson, FilExtensions[0]);
    };
    reader.onerror = () => console.log("Error");
  }

  // If file is GeoJson
  if (ext === FilExtensions[1]) {
    reader.onload = (e) =>
      setPolygonsArray(JSON.parse(e.target.result), FilExtensions[1]);
    reader.onerror = () => console.log("Error");
  }

  // If file is SHP
  if (SHPExtension.includes(ext)) {
    if (file) {
      if (ext === "prj") {
        reader.onload = (e) => {
          setShapesFiles(ext, e.target.result);
        };
      } else {
        file.arrayBuffer().then((bufferResult) => {
          setShapesFiles(ext, bufferResult);
        });
      }
    }
  }

  return newFile;
};

export const handleSHP = async (files) => {
  const geoJson = shp.combine([shp.parseShp(files.shp, files.prj), shp.parseDbf(files.dbf)]);
  setPolygonsArray(geoJson, FilExtensions[2]);
};


// Create Feature from Uploaded files
export const createFeaturesFromFiles = (uploadedFiles) => {
  const newFeatures = [];
  uploadedFiles.forEach((file, i) => {
    const originalPolygons = file.geometry.coordinates;
    let newArray = originalPolygons; 

    if (file.ext === "geojson") {
      const transformedPolygons = originalPolygons.map((polygon) => {
        return polygon.map((coordinates) => {
          return coordinates.map((coordinatesPolygon) =>
            transform(coordinatesPolygon, "EPSG:23700", "EPSG:4326")
          );
        });
      });
      newArray = transformedPolygons[0];
    }

    const transformedPolygonsAgain = newArray.map((polygon) => {
      return polygon.map((coordinates) =>
        transform(coordinates, "EPSG:4326", "EPSG:3857")
      );
    });

    const polygonGeometry = new Polygon(transformedPolygonsAgain);
    const newFeature = new Feature({
      geometry: polygonGeometry,
      status: "Upload",
      id: i,
      data: {
        status: "Upload",
      },
    });
    newFeatures.push(newFeature);
  });

  return newFeatures;
};

// Add uploaded files to layer
export const addUploadedFeaturesToLayer = (features, layer) => {
  features.map((feature) =>
    layer.getSource().addFeature(feature)
  );
};

export const populateUploadVectorLayer = (filePolygons) => {
  const uploadedFeatures = createFeaturesFromFiles(filePolygons);
  addUploadedFeaturesToLayer(uploadedFeatures, uploadVectorLayer);
};

export const populateDrawVectorLayer = (filePolygons, selection) => {
  const reducedFilePolygons = selection.map(index => filePolygons[index]);
  const selectedFeatures = createFeaturesFromFiles(reducedFilePolygons);
  addUploadedFeaturesToLayer(selectedFeatures, drawVectorLayer);
};