// Imports
import { getRequests } from "./getRequests";
import { cookieGetToken, urls } from "api/common";
import { CheckError } from "utils/common";

// Sending a request with selected position
export const postNewRequestForest = async (body, message, setAside) => {
  await fetch(urls.postNewRequest, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
    body: JSON.stringify(body),
  })
    .then((response) =>
      CheckError(
        response,
        message,
        "Az igény sikeresen leadásra került!",
        "Hiba lépett fel az igény leadásakor!"
      )
    )
    .then((actualData) => {
      console.log(actualData);
      setAside(false);
      getRequests();
    })
    .catch((error) => console.log(error));
};
