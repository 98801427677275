// Imports
import { useSnapshot } from "valtio";
import { StoreModel, setCurrentTool } from "@service-roof";
import { Cursor, Download, Ruler } from "@icons";
import { StoreRequests } from "@store-roof";
import { exportOBJ } from "../Model/OBJExporter";

// TOOLBAR
const Toolbar = ({ model }) => {
  return (
    <ul className="toolbar">
      <CursorTool />
      <RulerTool />
      <ExportTool model={model} />
    </ul>
  );
};

const CursorTool = () => {
  const { currentTool, tools } = useSnapshot(StoreModel.tool);
  const { shiftPressed } = useSnapshot(StoreModel.cursor);

  return (
    <li
      className={currentTool === 0 ? "active" : ""}
      title={tools[0]}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentTool(0);
      }}
    >
      <Cursor multi={shiftPressed} color={currentTool === 0 ? null : "white"} />
    </li>
  );
};

const RulerTool = () => {
  const { currentTool, tools } = useSnapshot(StoreModel.tool);

  return (
    <li
      className={currentTool === 1 ? "active" : ""}
      title={tools[1]}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentTool(1);
      }}
    >
      <Ruler color={currentTool === 1 ? null : "white"} />
    </li>
  );
};

const ExportTool = ({ model }) => {
  const { tools } = useSnapshot(StoreModel.tool);
  const { request_id } = useSnapshot(StoreRequests.activeRequestData);

  // Downloaded file nameing
  const filename = `TET-${request_id}_OBJ`;

  return (
    <li
      title={tools[2]}
      onClick={(e) => {
        e.stopPropagation();
        exportOBJ({ model, filename });
      }}
    >
      <Download color="white" />
    </li>
  );
};

export default Toolbar;
