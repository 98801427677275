// Google analitics tracking
import ReactGA from "react-ga4";

// Base URL
const gaMeasurementID = "G-7LWERBCXDY";

// Initialize Google Analytics
export const GaInit = () => {
  ReactGA.initialize(gaMeasurementID, {
    debug: true,
  });
};

// Login Event
export const GaEventLogin = () => {
  ReactGA.event({
    category: 'User',
    action: 'User Logged In'
  });
};

// Request Initiated Event
export const GaEventRequestInitiated = (service, value) => {
  ReactGA.event({
    category: 'Request',
    action: 'New Request Initiated',
    label: service || null,
    value: value,
  });
};