// Valtio
import { uploadVectorLayer } from "components/partials/maptools/upload/layer.upload";
import { proxy } from "valtio";

// STATE
export const StoreApp = proxy({
  devMode: process.env.NODE_ENV === "development",

  services: [
    {
      id: 0,
      key: "service-geostore",
      name: "Téradatbank",
      slug: "",
      search: {
        enabled: true,
        types: ["places", "coordinates"],
      },
      filter: {
        enabled: false,
      },
      widgets: {
        zoom: true,
        coordinates: true,
        survey: true,
        scale: true,
        toolbar: {
          cursor: true,
          add: true,
          ruler: true,
        },
        toolbarHidden: false,
        panelBottom: false,
      },
      terms: "/tc-resolver?service=Téradatbank",
    },
    {
      id: 1,
      key: "service-roof",
      name: "Tető",
      slug: "teto",
      search: {
        enabled: true,
        types: ["places", "coordinates"],
      },
      filter: {
        enabled: true,
      },
      widgets: {
        zoom: true,
        coordinates: true,
        survey: true,
        scale: true,
        toolbar: {
          cursor: true,
          add: false,
          ruler: true,
        },
        toolbarHidden: false,
        panelBottom: false,
      },
      terms: "/tc-resolver?service=Tető",
    },
    {
      id: 2,
      key: "service-forest",
      name: "Erdő",
      slug: "erdo",
      search: {
        enabled: false,
        types: ["cities", "coordinates", "mepar"],
      },
      filter: {
        enabled: false,
      },
      widgets: {
        zoom: true,
        coordinates: true,
        survey: true,
        scale: true,
        toolbar: {
          cursor: true,
          add: true,
          ruler: true,
        },
        toolbarHidden: false,
        panelBottom: true,
        fileUploadMultistep: 0,
      },
      terms: "/tc-resolver?service=Erdő",
    },
  ],
  activeService: 0,

  search: [
    { id: "places", label: "Címre", placeholder: "Keresés címekre..." },
    { id: "city", label: "Városra", placeholder: "Keresés városra..." },
    {
      id: "mepar",
      label: "MePAR",
      placeholder: "Keresés MePAR azonosítóra...",
    },
  ],
  searchInputOpen: false,
  searchBounds: [],
  searchPin: null,

  filterPanelOpen: false,
  filterBounds: [],

  projections: [
    { epsg: "EPSG:4326", label: "WGS 84", decimals: 6 },
    { epsg: "EPSG:23700", label: "EOV", decimals: 2 },
    { epsg: "EPSG:3857", label: "Mercator", decimals: 2 },
  ],
  activeProjection: 0,

  surveyDate: null,

  asideActive: false,
  uploadActive: false,
  panelInfoActive: false,
  panelInfoActiveSlug: "",
  tourOpen: true,

  serviceStatus: [],
});

export const setActiveService = (id) => {
  StoreApp.activeService = id;
};

export const setSearchInput = (value) => {
  StoreApp.searchInputOpen = value;
  if (value) {
    setFilterPanelOpen(false);
  }
};

export const setSearchBounds = (SWLng, SWLat, NELng, NELat, setPin) => {
  // Store search bounds
  StoreApp.searchBounds = [SWLng, SWLat, NELng, NELat];
  // Store search pin (if a pin can be placed)
  StoreApp.searchPin = setPin
    ? [(SWLng + NELng) / 2, (SWLat + NELat) / 2]
    : null;
};

export const setFilterPanelOpen = (value) => {
  StoreApp.filterPanelOpen = value;
  if (value) {
    setSearchInput(false);
  }
};

export const toogleActiveProjection = () => {
  StoreApp.activeProjection =
    (StoreApp.activeProjection + 1) % StoreApp.projections.length;
};

export const setSurveyDate = (value) => {
  StoreApp.surveyDate = value
    ? value.slice(0, 4) + "." + value.slice(4, 6) + "." + value.slice(6) + "."
    : null;
};

export const setAside = (value) => {
  StoreApp.asideActive = value;
  if (!value) {
    StoreApp.searchBounds = [];
    StoreApp.searchPin = null;
  } else {
    setFilterPanelOpen(false);
  }
};

export const setUpload = (value) => {
  StoreApp.uploadActive = value;
  if (!value) uploadVectorLayer.getSource().clear();
};

export const setToolbarHidden = (value, activeService) => {
  StoreApp.services[activeService].widgets.toolbarHidden = value;
};

export const setFileUploadMultiStep = (step) =>
  (StoreApp.services[2].widgets.fileUploadMultistep = step);

export const setPanelInfoActive = (value) => (StoreApp.panelInfoActive = value);
export const setPanelInfoActiveSlug = (slug) =>
  (StoreApp.panelInfoActiveSlug = slug);
export const setTourOpen = (value) => (StoreApp.tourOpen = value);

export const setServiceStatus = (items) => {
  StoreApp.serviceStatus = items.filter(status => status.active === true);
};

export const setTermsAndConds = (items) => {
  console.log(items);
};
