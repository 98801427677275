import { useSnapshot } from "valtio";
import { Button } from "@molecules";
import { useModalContext } from "@partials";
import { StoreUser } from "@store-common";
import { CartMinimum, setPanelState } from "@store-geostore";
import {
  checkPanelDataInstantPurchase,
  mappingSelectedLayers,
} from "@utils-geostore";

export const PanelGeostoreNewRequestSum = ({
  totalAmount,
  panelInfoDiscount,
  panelInfoCartMinimum,
  panelData,
}) => {
  const { userLoggedIn } = useSnapshot(StoreUser);
  const { setIsModalOpen } = useModalContext();
  const instantPurchase = checkPanelDataInstantPurchase(
    mappingSelectedLayers(panelData)
  );

  if (panelInfoCartMinimum) {
    totalAmount += CartMinimum.extraAmount;
  }

  return (
    <>
      <hr />
      <div className="panel__content-geo__sum-action">
        {userLoggedIn ? (
          <>
            <div className="panel__content-geo__sum-action-price">
              {panelInfoDiscount && totalAmount > 0 ? (
                <>
                  <h6>{totalAmount.toLocaleString('hu-HU')} FT helyett:</h6>
                  <h4>
                    {(totalAmount - panelInfoDiscount.discount).toLocaleString('hu-HU')} FT
                    {instantPurchase && <span>-tól*</span>}
                  </h4>
                </>
              ) : (
                <h4>{totalAmount.toLocaleString('hu-HU')} FT</h4>
              )}
            </div>
            <Button
              iconPosition="after"
              text="Tovább"
              type="submit"
              disabled={totalAmount === 0 && !instantPurchase}
              action={() => setPanelState(1)}
            />
          </>
        ) : (
          <Button
            iconPosition="after"
            text="Tovább"
            type="submit"
            disabled={totalAmount === 0}
            action={() => setIsModalOpen(true)}
          />
        )}
      </div>
    </>
  );
};
