import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Close, Download } from "@icons";
import { Button } from "@molecules";
import {
  StoreApp,
  setPanelInfoActive,
  setPanelInfoActiveSlug,
} from "@store-common";
import { StoreProductsGeoStore } from "@store-geostore";
import { Tag } from "../molecules";

export const PanelGeostoreNewRequestInfo = () => {
  const { panelInfoActiveSlug } = useSnapshot(StoreApp);
  const { products } = useSnapshot(StoreProductsGeoStore);
  const [infoData, setInfoData] = useState(null);

  useEffect(() => {
    if (panelInfoActiveSlug !== "") {
      const foundPanel = products.filter(
        (panel) => panel.slug === panelInfoActiveSlug
      );
      if (foundPanel.length > 0) {
        setInfoData(foundPanel[0]);
      } else {
        setInfoData(null);
      }
    }
  }, [panelInfoActiveSlug]);

  if (infoData === null) return <></>;

  return (
    <div className="panel__info">
      <div className="panel__info-title">
        <h2>{infoData.title}</h2>
        <button
          onClick={() => {
            setPanelInfoActive(false);
            setPanelInfoActiveSlug("");
          }}
        >
          <Close />
        </button>
      </div>
      <div className="panel__info-image">
        <img src={infoData.image.url} alt={infoData.title} />
      </div>
      <div className="panel__info-content">
        <div className="panel__info-content-top">
          <div className="panel__info-content-top-filters">
            {infoData.format.map((tag, i) => {
              return <Tag key={i} tag={tag} />;
            })}
            <Tag tag={infoData.resolution} />
          </div>
          <div className="panel__info-content-top-desc">
            <p>{infoData.shortDescription}</p>
          </div>
        </div>
        <div className="panel__info-content-bottom">
          {infoData.demoUrl && (
            <Button
              iconPosition="before"
              icon={<Download />}
              secondary
              text={"Demo fájl letöltése"}
              url={`https://demofiles.envimap.hu/${infoData.demoUrl}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
