import { vatValidator } from "@apis-common";

const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);
const isValidPhoneNumber = (phoneNumber) => /^\+?\d{9,}$/.test(phoneNumber);
const initFormErrors = (formData, setFormData) => {
  // No errors, reset all hasError properties to false
  formData.forEach((field) => (field.hasError = false));
  setFormData(formData);
};

const validateFormData = async (data, formData, setFormData) => {
  const errors = [];

  initFormErrors(formData, setFormData);

  if (data.vatnum.length !== 13) {
    if (data.vatnum.length !== 11 && !data.vatnum.includes("-")) {
      errors.push("Kérjük a teljes adószámot adja meg!");

      const vat = formData.findIndex((field) => field.name === "vatnum");
      if (vat !== -1) {
        formData[vat].hasError = true;
      }
    }
  }

  if (
    data.vatnum.length === 13 ||
    (data.vatnum.length !== 11 && data.vatnum.includes("-"))
  ) {
    // Slice the vatnum from index 0 to 8
    const companyList = await vatValidator(
      data.vatnum.slice(0, 8),
      "tax_number"
    );

    if (companyList.length < 1) {
      errors.push("Az adószám nem létezik!");

      const vat = formData.findIndex((field) => field.name === "vatnum");
      if (vat !== -1) {
        formData[vat].hasError = true;
      }
    }
  }

  if (data.password !== data.passwordAgain) {
    errors.push("Jelszavak nem egyeznek!");

    // Find the index of the field with name "password" in formData
    const passwordIndex = formData.findIndex(
      (field) => field.name === "password"
    );
    // Find the index of the field with name "passwordAgain" in formData
    const passwordAgainIndex = formData.findIndex(
      (field) => field.name === "passwordAgain"
    );

    // If found, set the hasError property to true for the corresponding fields
    if (passwordIndex !== -1) {
      formData[passwordIndex].hasError = true;
    }

    if (passwordAgainIndex !== -1) {
      formData[passwordAgainIndex].hasError = true;
    }
  }

  if (!isValidEmail(data.email)) {
    errors.push("Helytelen email formátum!");

    const emailIndex = formData.findIndex((field) => field.name === "email");
    if (emailIndex !== -1) {
      formData[emailIndex].hasError = true;
    }
  }

  if (!isValidPhoneNumber(data.phone)) {
    errors.push("Helytelen telefonszám!");

    const phoneIndex = formData.findIndex((field) => field.name === "phone");
    if (phoneIndex !== -1) {
      formData[phoneIndex].hasError = true;
    }
  }

  if (!data.checkbox) {
    errors.push("ÁSZF elfogadása kötelező!");

    const checkboxIndex = formData.findIndex(
      (field) => field.name === "checkbox"
    );
    if (checkboxIndex !== -1) {
      formData[checkboxIndex].hasError = true;
    }
  }

  if (errors.length > 0) {
    setFormData(formData);
    return errors;
  }

  initFormErrors(formData, setFormData);

  return true;
};

export { validateFormData };
