import { useSnapshot } from "valtio";
import { cookieSetGeoStoreData } from "@apis-common";
import { StoreNewRequestGeoStore } from "@store-geostore";
import { useDynamicHeightCalculation } from "@utils-common";
import { mappingSelectedLayers } from "@utils-geostore";
import { CheckboxGEO } from "../form";
import { PanelGeostoreNewRequestSum } from "./PanelGeostoreNewRequestSum";
import { PanelGeostoreNewRequestSumDetails } from "./PanelGeostoreNewRequestSumDetails";

export const PanelGeostoreNewRequestContent = ({
  panelData,
  setPanelData,
  totalAmount,
  inputSQMValue,
  handleForm,
  panelInfoDiscount,
  panelInfoCartMinimum,
  setRequestComment,
  requestComment,
}) => {
  const { panelState } = useSnapshot(StoreNewRequestGeoStore);
  const height = useDynamicHeightCalculation(mappingSelectedLayers(panelData));

  const handleCheckboxChange = (itemId, isChecked) => {
    const updatedData = panelData.map((item) => {
      if (item.id === itemId) {
        return { ...item, checkbox: isChecked };
      }
      return item;
    });
    // Set panel data
    setPanelData(updatedData);
    // Set cookie panel data
    cookieSetGeoStoreData(mappingSelectedLayers(updatedData));
  };

  return (
    <div className="panel__content-geo">
      {panelState === 0 && (
        <div
          className="panel__content-geo__checkboxes"
          style={{
            maxHeight: height > 0 && height + "px",
          }}
        >
          {panelData.map((data) => (
            <CheckboxGEO
              key={data.id}
              data={data}
              onChange={handleCheckboxChange}
            />
          ))}
        </div>
      )}

      {panelState === 0 ? (
        <div className="panel__content-geo__sum">
          <PanelGeostoreNewRequestSum
            totalAmount={totalAmount}
            panelInfoDiscount={panelInfoDiscount}
            panelInfoCartMinimum={panelInfoCartMinimum}
            panelData={panelData}
          />
        </div>
      ) : (
        <div className="panel__content-geo__order">
          <PanelGeostoreNewRequestSumDetails
            panelData={panelData}
            totalAmount={totalAmount}
            inputSQMValue={inputSQMValue}
            handleForm={handleForm}
            panelInfoDiscount={panelInfoDiscount}
            panelInfoCartMinimum={panelInfoCartMinimum}
            setRequestComment={setRequestComment}
            requestComment={requestComment}
          />
        </div>
      )}
    </div>
  );
};
