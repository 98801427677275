// imports
import { useSnapshot } from "valtio";
import { Toolbar, Zoom } from "@partials";
import { StoreApp } from "@store-common";
import { containsTrueKey } from "@utils-common";

export const Maptools = ({ map, setKey }) => {
  const { services, activeService } = useSnapshot(StoreApp);
  const widgets = services[activeService].widgets;
  return (
    <div className="maptools">
      <div className="maptools-left" />
      <div className="maptools-fill">
        {!widgets.toolbarHidden && containsTrueKey(widgets.toolbar) && (
          <Toolbar map={map} tools={widgets.toolbar} setKey={setKey} />
        )}
      </div>
      <div className="maptools-right">{widgets.zoom && <Zoom map={map} />}</div>
    </div>
  );
};
