import { Warn } from "@icons";

export const SHPInfoBox = ({ filesMissing }) => {
  const { hasDbf, hasPrj, hasShp } = filesMissing;

  const missingFileTypes = [];

  if (!hasDbf) missingFileTypes.push(".dbf");
  if (!hasPrj) missingFileTypes.push(".prj");
  if (!hasShp) missingFileTypes.push(".shp");

  if (missingFileTypes.length === 0) return <></>;

  const missingFileTypesText = missingFileTypes.join(", ");

  return (
    <div className="infobox">
      <Warn color="red" />
      <h6>
        {missingFileTypesText}
        {" hiányzik "}
      </h6>
    </div>
  );
};
