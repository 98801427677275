import { Discounts } from "@store-geostore";

// This utility function helps us calculate
// the total order amount, including the
// highest discount (if applicable)

//  1.  Figyeljük, hogy elérhető-e a kedvezmény,
//      és kapcsoljuk át aktívra az adott kedvezményt.
//      Akkor kell figylni:
//       - Új igény van
//       - Az igény részletei változnak

//  2.  Amennyiben elérhető a kedvezény, akkor meg kell nézni
//      mely kedvezmények elérhetőek

//  3.  Minden aktív kedvezményre kapcsolódan számoljuk végig
//      az adott végösszeg alapján a kedvezmény mértékét
//      Kedvezmények definíciója:
//      - Mennyiségi kedvezmény: Nettó 1 millió Ft feletti vásárlás
//        esetében 5%, nettó 5 millió Ft feletti vásárlás esetében
//        8% kedvezmény.
//      - Csomagkedvezmény: Domborzatmodell és ortofotó viszonylatában.
//        Ha mindkét terméket kiválasztja a bejelölt terület kapcsán,
//        akkor kap 2% árkedvezményt a teljes árból. Ha csak az egyik
//        terméket választja ki alapból, akkor felajánljuk neki, hogy
//        "együtt olcsóbb" - UPSELL
//      - K+F kedvezmény: a felhasználónak a megrendelés leadásánál
//        legyen lehetősége bejelölni azt, hogy az adatot K+F
//        tevékenységhez vásárolja és elfogadja az ÁSZF-ben
//        leírtakat (Az ÁSZF-ben le kell írni, hogy a kutatás eredményeinek
//        Envivel történő megosztását a megrendelő vállalja). Ha ezt
//        bepipálja, akkor az az infó bejön hozzánk, és egy külön
//        megállapodást kötünk vele.
//      - Avulási kedvezmény: az 5 évnél idősebb ortofotó termékre
//        egységesen 50% kedvezmény.

//  4.  A legnagyobb mértékű kedvezménnyel térjünk vissza
//      és jelenítsük meg a felületen: vonjuk ki az összegből
//      és írjuk ki a panelba

//  5.  Abban az esetben ha változás történik, figyeljük hogy
//      az adott kedvezmény még aktív-e és e szerint számoljuk
//      a kedvezményt

const isAllTrue = (panelData) => {
  let foundOrto = false;
  let foundDomborzatmodell = false;

  for (const data of panelData) {
    if (data.slug.includes("orto") && data.checkbox) {
      foundOrto = true;
    }
    if (data.slug.includes("domborzatmodell") && data.checkbox) {
      foundDomborzatmodell = true;
    }
  }

  return foundOrto && foundDomborzatmodell;
};


const calculateDayDifference = (dateString) => {
  // Creating the provided date
  const inputDate = new Date(dateString);

  // Creating the current date
  const today = new Date();

  // Calculating the difference in days
  const timeDifference = today - inputDate;

  // Checking if the difference is 5 years or more
  const result = timeDifference >= 5 * 365 * 24 * 60 * 60 * 1000;

  return result;
};

const decimalPercentage = (decimalValue) => decimalValue / 100;

const findIndexOfHighestDiscount = (discountsArray) => {
  let highestDiscountIndex = -1;
  let highestDiscountValue = 0;

  for (let i = 0; i < discountsArray.length; i++) {
    if (discountsArray[i].discount > highestDiscountValue) {
      highestDiscountValue = discountsArray[i].discount;
      highestDiscountIndex = i;
    }
  }

  return [highestDiscountIndex, highestDiscountValue];
};

const checkActiveDiscounts = (totalAmount, panelData, surveyDate) => {
  const initDiscounts = Discounts;

  // Check any discount is active
  initDiscounts.forEach((discountItem) => {
    if (discountItem.title === "Mennyiségi kedvezmény") {
      discountItem.discountActive = totalAmount >= 1000000;
    }

    if (discountItem.title === "Csomagkedvezmény") {
      discountItem.discountActive = isAllTrue(panelData);
    }

    if (discountItem.title === "Avulási kedvezmény")
      discountItem.discountActive = calculateDayDifference(surveyDate);
  });

  // Get active discounts list
  const activeDiscounts = initDiscounts.filter(
    (discount) => discount.discountActive
  );
  return activeDiscounts;
};

const calculateHighestDiscount = (activeDiscounts, totalAmount) => {
  let discountesArray = [];

  if (activeDiscounts.length > 0) {
    activeDiscounts.map((discountItem, index) => {
      let item = {};

      if (discountItem.title === "Mennyiségi kedvezmény") {
        item = {
          id: index,
          discount: Math.round(
            totalAmount *
              (totalAmount < 5000000
                ? decimalPercentage(discountItem.discountPercent[0])
                : decimalPercentage(discountItem.discountPercent[1]))
          ),
        };
      } else {
        item = {
          id: index,
          discount: Math.round(
            totalAmount * decimalPercentage(discountItem.discountPercent[0])
          ),
        };
      }

      return discountesArray.push(item);
    });

    // Get highest discount index
    const highestDiscountIndex = findIndexOfHighestDiscount(discountesArray);

    const highestDiscountObject = {
      ...activeDiscounts[highestDiscountIndex[0]],
      discount: highestDiscountIndex[1],
    };

    return highestDiscountObject;
  }

  return false;
};

export const calculateDiscount = (
  totalAmount,
  panelData,
  setPanelInfoDiscount,
  surveyDate
) => {
  // 2. - Get active discounts array
  const activeDiscounts = checkActiveDiscounts(
    totalAmount,
    panelData,
    surveyDate
  );
  // 3. - Get highest discount and pass forward
  setPanelInfoDiscount(calculateHighestDiscount(activeDiscounts, totalAmount));
};
