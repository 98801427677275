export const formatStatus = (status) => {
  switch (true) {
    case status === "Available":
      return "Elkészült";
    case status === "Archived":
      return "Archiválva";
    case status === "Pending":
      return "Várakozik";
    case status === "Saved":
      return "Mentett";
    default:
      return status;
  }
};
