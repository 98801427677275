// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { getRequestByID } from "@apis-roof";
import {
  ButtonClose,
} from "@molecules";
import {
  useToastContext
} from "@partials";
import {
  PanelNewRequest,
  PanelPrevRequest
} from "@service-roof";
import { Convert } from "@icons";
import { StoreApp, toogleActiveProjection } from "@store-common";
import { StoreRequests, closePanel } from "@store-roof";
import { transformCoords, formatCoords } from "@utils-common";

// Main Panel component
export const Panel = () => {
  const { asideActive } = useSnapshot(StoreApp);
  const {
    prevRequests,
    activePrevPin,
    newRequestPin,
    newRequestPinAddress
  } = useSnapshot(StoreRequests);
  const [panelData, setPanelData] = useState(null);
  const addToast = useToastContext();

  useEffect(() => {
    if (asideActive) {
      // If new request
      if (newRequestPin) {
        setPanelData({
          type: "new",
          coordinates: {
            lng: newRequestPin.lng,
            lat: newRequestPin.lat,
          },
          available: newRequestPin.available,
          address: newRequestPinAddress,
        });
      }

      // If previous request
      if (activePrevPin) {
        // Call API for detailed information
        getRequestByID(activePrevPin.request_id, addToast);

        // Basic information that we already have will be used for the frame
        const scopedRequest = prevRequests.find(
          (e) => e.request_id === activePrevPin.request_id
        );

        // Set basic data
        if (scopedRequest) {
          setPanelData({
            type: "prev",
            request_id: scopedRequest.request_id,
            coordinates: {
              lng: scopedRequest.lng,
              lat: scopedRequest.lat,
            },
            status: scopedRequest.status,
            address: scopedRequest.address,
          });
        }
      }
    }
  }, [
    asideActive,
    newRequestPin,
    newRequestPinAddress,
    activePrevPin,
    prevRequests,
    addToast,
  ]);

  if (asideActive && panelData) {
    return (
      <div className="panel">
        <div className="panel__header">
          <div className="panel__header__content">
            <h4>
              {panelData.request_id
                ? `TET-${panelData.request_id}`
                : "Új igény lokáció:"}
            </h4>
            <p>{panelData.address.formatted}</p>
            <PanelCoordinates coordinates={panelData.coordinates} />
          </div>
          <ButtonClose action={() => closePanel()} />
        </div>

        <div className="panel__content">
          {panelData.type === "new" ? (
            <PanelNewRequest data={panelData} />
          ) : (
            <PanelPrevRequest/>
          )}
        </div>
      </div>
    );
  } else {
    return <div className="panel" />;
  }
};

const PanelCoordinates = ({ coordinates }) => {
  const { projections, activeProjection } = useSnapshot(StoreApp);
  const [transformedCoords, setTransformedCoords] = useState(null);

  // Handle coordinate transformation
  useEffect(() => {
    if (!coordinates) return;

    setTransformedCoords(transformCoords([coordinates.lng, coordinates.lat], 'EPSG:4326', projections[activeProjection].epsg));
  }, [coordinates, activeProjection]);

  if (!transformedCoords) return;
  return (
    <button
      className="panel__header__coordinates"
      onClick={toogleActiveProjection}
    >
      <span className="span--bold">
        {formatCoords(transformedCoords, projections[activeProjection].decimals)}
      </span>
      <span>{projections[activeProjection].label}</span>
      <Convert />
    </button>
  );
};