// Imports
import { Fill, Style } from "ol/style";


// AVAILABILITY LAYER STYLE
export const availabilityStyle = new Style({
  fill: new Fill({
    color: "transparent",
  }),
});

// SURVEY DATA LAYER STYLE
export const surveyStyle = new Style({
  fill: new Fill({
    color: 'transparent',
  })
});