import { urls } from "./urls";

// Credentials
const apiKey = process.env.REACT_APP_VAT_API_KEY;
const customerToken = process.env.REACT_APP_VAT_CUSTOMER_TOKEN;
const clientId = process.env.REACT_APP_VAT_CLIENT_ID;

export const vatValidator = async (value, type) => {
  // Custom field, we can change it later if we want
  const fields = false;

  // Default headers
  const headers = new Headers();
  headers.append("X-Api-Key", apiKey);
  headers.append("X-Customer-Token", customerToken);
  headers.append("X-Client-Id", clientId);

  // If there is any field, we can add it to the headers
  if (fields) {
    headers.append("X-Fields", fields);
  }

  // Query params
  const queryParams = new URLSearchParams({
    key: type,
    value: value,
    // limit: 30,
    // page: 1,
  });

  // Fetch method
  try {
    const response = await fetch(`${urls.vatNum}?${queryParams}`, {
      method: "GET",
      headers: headers,
      mode: "cors",
    });

    if (!response.ok) {
      throw new Error(
        `Network response was not ok: ${response.status} ${response.statusText}`
      );
    }

    const actualData = await response.json();

    return actualData;
    
  } catch (error) {
    console.log(error);
    return error;
  }
};
