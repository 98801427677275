import { setAside, setSelectedOptionsUpload, setUpload } from "@store-common";
import { checkUploadVectorLayerExist } from "./utils.upload";
import { drawVectorLayer } from "../toolbar/layer.toolbar";
import { handleDrawChange } from "../toolbar/draw.toolbar";

// Upload Polygon
export const Upload = ( map, activeService ) => {

  // Check if the polygon vector layer exist
  checkUploadVectorLayerExist(map);

  // Open file upload panel
  setAside(true);
  setUpload(true);

  // Handle uploadVectorLayer polygon selection
  map.on("click", (e) => {
    const pixel = e.pixel;
    const feature = map.forEachFeatureAtPixel(pixel, (feature, layer) => {
      if (layer && layer.get("title") === "upload-layer" && feature) {
        return feature;
      }
    });

    if (feature) {
      const isChecked = feature.get("selected");
      setSelectedOptionsUpload(isChecked, feature.get("id"));
    }
  });

  // Handle drawVectorLayer changes
  drawVectorLayer.getSource().on('change', () => {
    handleDrawChange(activeService);
  });
};