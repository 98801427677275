// Imports
import { Fill, Style } from "ol/style";
import { getVectorContext } from "ol/render";
import { availabilityLayer, satalaiteLayer } from "@partials";


// Clip Google satalite layer with available area
export const maskAvailabilityLayer = () => {
  // Set Extent
  availabilityLayer.getSource().on("addfeature", () => {
    satalaiteLayer.setExtent(availabilityLayer.getSource().getExtent());
  });
  // Style cliping
  const style = new Style({
    fill: new Fill({
      color: "black",
    }),
  });
  // Clip layer
  satalaiteLayer.on("postrender", (e) => {
    const vectorContext = getVectorContext(e);
    e.context.globalCompositeOperation = "destination-in";
    availabilityLayer.getSource().forEachFeature(function (feature) {
      vectorContext.drawFeature(feature, style);
    });
    e.context.globalCompositeOperation = "source-over";
  });
};

// Animate view to layer features extent
export const zoomToLayerExtent = (map, layer, padding) => {
  const extent = layer.getSource().getExtent();
  const view = map.getView();

  view.fit(extent, { 
    padding: [padding, padding, padding, padding],
    duration: 400,
  });
};

// Animate view to features extent
export const zoomToFeatureExtent = (map, feature, padding) => {
  const extent = feature.getGeometry().getExtent();
  const view = map.getView();

  view.fit(extent, { 
    padding: [padding, padding, padding, padding],
    duration: 400,
  });
};