// Imports
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { getInit } from "@apis-roof";
import { Aside, Main, MainTop } from "@layout";
import { MapRoof, Panel } from "@service-roof";
import { StoreUser } from "@store-common";

export const ServiceRoof = () => {
  const { domain_id } = useSnapshot(StoreUser.user);

  // Initial data call
  useEffect(() => {
    getInit(domain_id);
  }, [domain_id]);

  return (
    <>
      <Main>
        <MainTop />
        <MapRoof />
      </Main>
      <Aside>
        <Panel />
      </Aside>
    </>
  );
};
