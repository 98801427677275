// Imports
import { proxy } from "valtio";
import { calculateArea, transformGeometry } from "@utils-forest";

// Store init state
const initStoreUpload = {
  filesArray: [],
  filePolygons: [],
  addFilesToMap: false,
  selectedOptions: [],
  filesSHP: {
    dbf: null,
    shp: null,
    prj: null,
  },
};

// STORE
export const StoreUpload = proxy(initStoreUpload);

export const initStore = () => {
  for (const key in initStoreUpload) {
    StoreUpload[key] = initStoreUpload[key];
  }
};

export const setAddFilesToMap = (value) => (StoreUpload.addFilesToMap = value);

// Clear files
export const clearFilesArray = () => {
  StoreUpload.filesArray = [];
};

// Set files
export const setFilesArray = (file) => {
  StoreUpload.filesArray = [...StoreUpload.filesArray, file];
};

// Remove files
export const removeFilesArray = (fileName) => {
  StoreUpload.filesArray = StoreUpload.filesArray.filter(
    (file) => file.name !== fileName
  );
};

// Set polygons
export const setPolygonsArray = (file, ext) => {
  const polygonList = file.features.map((polygon, i) => {
    let standardPolygon = polygon;

    if (polygon.geometry.geometries) {
      standardPolygon = {
        ...polygon,
        geometry: {
          type: "MultiPolygon",
          coordinates: transformGeometry(polygon.geometry.geometries),
        },
      };
    }

    return {
      ...standardPolygon,
      id: i,
      ext: ext,
      area: calculateArea(
        standardPolygon.geometry.coordinates,
        ext,
        standardPolygon.geometry.type
      ),
    };
  });
  StoreUpload.filePolygons = polygonList;
};

// Clear polygons
export const clearPolygonsArray = () => {
  StoreUpload.filePolygons = [];
};

// Handle selected options
export const setSelectedOptionsUpload = (isChecked, optionID) => {
  const prevSelectedOptions = StoreUpload.selectedOptions;
  if (isChecked) {
    // If the option is already selected, remove it from the array
    StoreUpload.selectedOptions = prevSelectedOptions.filter(
      (id) => id !== optionID
    );
  } else {
    // If the option is not selected, add it to the array
    StoreUpload.selectedOptions = [...prevSelectedOptions, optionID];
  }
};

// Set shape files
export const setShapesFiles = (ext, file) => {
  StoreUpload.filesSHP[ext] = file;
};
