// Imports
import { createContext, useCallback, useContext, useState } from "react";
import { Row } from "@layout";
import { HamburgerClose } from "@icons";

export const ToastContext = createContext();

export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (toast) => {
      if (toasts.length === 0) {
        setToasts((toasts) => [...toasts, toast]);
        setTimeout(() => setToasts((toasts) => toasts.slice(1)), 3000);
      }
    },
    [setToasts, toasts]
  );

  const removeToast = () => {
    setToasts((toasts) => toasts.slice(1));
  };
  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {toasts.map((message, index) => {
        return (
          <div className={`toast show ${message[0]}`} key={index}>
            <div className="toast-content">
              <Row>
                <p>{message[1]}</p>
                <button onClick={() => removeToast()}>
                  <HamburgerClose />
                </button>
              </Row>
            </div>
          </div>
        );
      })}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
