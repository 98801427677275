// Imports
import { useSnapshot } from "valtio";
import { postNewRequest } from "@apis-roof";
import { Button } from "@molecules";
import { useToastContext } from "@partials";
import { Close, Done, Pin } from "@icons";
import { StoreDomain } from "data/common";
import { StoreRequests } from "@store-roof";
import productThumbnail from "@images-roof/product_thumbnail.png";
import { useEffect } from "react";
import { GaEventRequestInitiated } from "@apis-common";

export const PanelNewRequest = ({ data }) => {
  const { prevRequests } = useSnapshot(StoreRequests);
  const { on_trial, parent_id } = useSnapshot(StoreDomain);
  const addToast = useToastContext();

  const handleSubmit = (e) => {
    // Prevent form to default function
    e.preventDefault();

    // Creating request body
    let body = {
      lat: data.coordinates.lat,
      lon: data.coordinates.lng,
      building_type: "Lakóingatlan",
      building_features: 1,
      resolved_addr: JSON.stringify(data.address),
      service: "Solar",
    };

    // Call endpoint
    postNewRequest(body,addToast);
  };

  // Handle special pricing scenarios
  const checkPricing = () => {
    switch (parent_id) {
      case "db75f7a4-5ae5-48f1-b534-e86b08b91305":  //Terrán Partner
        return 8000;
      default:
        return 18000;
    }
  };
  
  // Analytics
  useEffect(() => {
    // Send new request initiated event to GA
    GaEventRequestInitiated(
      "EnviMAP Tető",
      checkPricing(),
    );
  }, []);
  

  if (on_trial && prevRequests.length >= 3 && data.available) {
    return <TrialEnded />;
  } else {
    return (
      <>
        <DataAvailability status={data.available} />

        {data.available ? (
          <div className="panel__content-box__request">

            <div className="panel__content-box__request__productcard">
              <div
                className="panel__content-box__request__productcard-thumbnail"
                style={{ backgroundImage: `url(${productThumbnail})`}}
              />
              <div className="panel__content-box__request__productcard-description">
                <h5>Épületmodell tetőobjektumokkal</h5>
                <span>Távérzékelt adatokból, térinformatikai eljárással készített LOD2 részletességű 3D épületmodell kategorizált tetőelemek és tetőobjektumok megjelenítésével (pl. felületnagyságok, taréjgerinc-, oromszegély-, ereszhossz, dőlésszögek, tetőobjektumok kiterjedése) 500 m2 tetőfelület méretig. 500 m2 feletti tetőfelülettel rendelkező épület esetében az igényleadást követően egyedi ajánlat készül.</span>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="panel__content-box__request__total">
                <p>Szolgáltatás ára</p>
                <h4>{checkPricing().toLocaleString('hu-HU')} Ft</h4>
              </div>
              <div className="panel__content-box__request__button">
                <Button text="Megrendelés" type="submit" value="submit" />
              </div>
              <span className="panel__content-box__request__miniscule">
                Az itt feltűntetett árak nettó árak, az Áfá-t nem tartalmazzák.
              </span>
            </form>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
};

const DataAvailability = ({ status }) => {
  const icons = [
    <Done color="white" key="icon01" />,
    <Close color="white" key="icon02" />,
  ];
  const headlines = ["Elérhető adatok", "Nincsenek elérhető adatok"];
  const leads = [
    "A kiválasztott épülethez tartozó adatok igénylése esetén három munkanapon belül elérheti azokat.",
    "A kiválasztott ponthoz sajnos jelenleg nem rendelkezünk adatokkal.",
  ];

  return (
    <div className="panel__content-box__status">
      <span
        className={`panel__content-box__status-icon ${
          status ? "Accepted" : "Denied"
        }`}
      >
        {status ? icons[0] : icons[1]}
      </span>
      <div className="panel__content-box__status-content">
        <h2>{status ? headlines[0] : headlines[1]}</h2>
        <p>{status ? leads[0] : leads[1]}</p>
      </div>
    </div>
  );
};

const TrialEnded = () => {
  const { domain_name } = useSnapshot(StoreDomain);

  // Download zipped project files
  const sendUpgradeRequest = () => {
    const address = "info@teto.envimap.hu";
    const subject = "Csomagváltási igény - " + domain_name;
    const body = "";
    window.open(`mailto:${address}?subject=${subject}&body=${body}`);
  };

  return (
    <div className="panel__content-box__status">
      <span className="panel__content-box__status-icon trial-ended">
        <Pin />
      </span>
      <div className="panel__content-box__status-content">
        <h2>Ingyenes adatigényei elfogytak</h2>
        <p>
          A Demo időszak alatt engedélyezett három darab térítésmentes
          adatigénylését felhasználta.
        </p>
      </div>
      <Button text="Tovább használnám" action={sendUpgradeRequest} />
    </div>
  );
};
