import { StoreUpload, clearFilesArray, clearPolygonsArray, initStore, removeFilesArray, setAside, setFilesArray, setSelectedOptionsUpload, setUpload } from "@store-common";
import { Button, ButtonClose, SHPInfoBox } from "@molecules";
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { checkExtensionsInArray, checkFileNameSHP, checkFileSHP, checkFileTypeSHP, fileUploadUtility, handleSHP, populateDrawVectorLayer, populateUploadVectorLayer } from "./utils.upload";
import { useSnapshot } from "valtio";
import { Bin, Upload } from "@icons";
import { uploadVectorLayer } from "./layer.upload";


// Upload Panel
export const PanelUpload = () => {
  const baseClass = "panel__content-box__upload";
  const { check, filesArray, filesSHP, filePolygons, selectedOptions } = useSnapshot(StoreUpload);
  const [ uploadStep, setUploadStep ] = useState(0);
  const [ fileReady, setFileReady ] = useState(false);

  // Initialize
  useEffect(() => {
    initStore();
  }, []);

  // React Dropzone event
  const onDrop = async (acceptedFiles) => {
    processUploads(acceptedFiles);
  };

  // React Dropzone defaults
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/geo+json': [ '.json', '.geojson' ],
      'application/vnd': [ '.kml' ],
      'application/octet-stream': [ '.shp', '.dbf', '.prj' ]
    },
    onDrop,
  });

  // Process droped files
  const processUploads = async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      try {
        const newFile = await fileUploadUtility(file);
  
        if (!checkFileNameSHP(newFile.ext) || !checkFileTypeSHP(filesArray)) {
          clearFilesArray();
        }
  
        setFilesArray(newFile);
      } catch (error) {
        // Handle any errors that occur during file processing
        console.error("Error processing file:", error);
      }
    }
  };

  // Remove the user-selected file
  const handleRemoveItem = (fileName) => {
    removeFilesArray(fileName);
    clearPolygonsArray();
  };

  // Handle Shape files being selected
  useEffect(() => {
    if (checkFileSHP(filesSHP)) {
      handleSHP(filesSHP);
    } else {
      clearPolygonsArray();
    }
  }, [filesSHP]);

  // Set files ready based on filePolygons
  useEffect(() => {
    setFileReady(filePolygons.length ? true : false);
  }, [filePolygons]);

  // Handle selected polygons
  useEffect(() => {
    uploadVectorLayer.getSource().getFeatures().forEach(feature => {
      feature.set("selected", selectedOptions.includes(feature.get("id")));
    });
  }, [selectedOptions]);

  // Handle stepping
  const handleStep = (direction) => {
    if (uploadStep < 1) {
      // Add selected polygons to uploadVectorLayer
      populateUploadVectorLayer(filePolygons);
      // Move forward
      setUploadStep(uploadStep + direction);
    } else {
      // Add selected polygons to drawVectorLayer
      populateDrawVectorLayer(filePolygons, selectedOptions);
      // Close upload panel
      setUpload(false);
      // Request panel should open automaticly with the selected polygon
    }
  };

  return (
    <div className="panel">
      <div className="panel__header">
        <div className="panel__header__content">
          <h4>Feltöltés</h4>
          <p>Terület kijelölése poligon állomány hozzáadásával</p>
        </div>
        <ButtonClose
          action={() => {
            setAside(false);
            setUpload(false);
          }}
        />
      </div>

      <div className="panel__content">
        {uploadStep === 0 ? (
          <div className={baseClass}>

            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <div className="dropzone__icon">
                <Upload/>
              </div>
              <div className="dropzone__content">
                <h4>Húzza ide a fájlokat vagy kattintson</h4>
                <p>SHAPE fájl (.shp + .dbf + .prj), KML vagy GeoJSON</p>
              </div>
            </div>

            {filesArray.length !== 0 && (
              <div className={`${baseClass}__files-list`}>
                <>
                  {filesArray.map((file, index) => (
                    <UploadListItem
                      file={file}
                      key={index}
                      handleRemoveItem={handleRemoveItem}
                    />
                  ))}
                  {checkFileTypeSHP(filesArray) && (
                    <SHPInfoBox filesMissing={checkExtensionsInArray(filesArray)} />
                  )}
                </>
              </div>
            )}

          </div>
        ) : (
          <div className={`${baseClass}__feature-list`}>
            {filePolygons.length !== 0 &&
              filePolygons.map((option, index) => (
                <CheckboxButton
                  option={option}
                  key={index}
                  check={parseInt(check)}
                  index={index}
                  selectedOptions={selectedOptions}
                />
              ))}
          </div>
        )}
      </div>

      <div className="panel_bottom">
        {uploadStep === 1 && (
          <Button
            secondary
            text="Vissza"
            type="button"
            action={() => handleStep(-1)}
          />
        )}
        {((fileReady && uploadStep === 0) || (selectedOptions.length > 0 && uploadStep === 1)) &&  (
          <Button
            text="Tovább"
            type="button"
            action={() => handleStep(+1)}
          />
        )}
      </div>
    </div>
  );
};


const UploadListItem = ({ file, handleRemoveItem }) => {
  return (
    <div className="upload-item">
      <div className="upload-item__name">
        <h6> {file.name} </h6>
      </div>
      <div className="upload-item__delete">
        <button onClick={() => handleRemoveItem(file.name)}>
          {" "}
          <Bin />{" "}
        </button>
      </div>
    </div>
  );
};


const CheckboxButton = ({ selectedOptions, option }) => {
  const isChecked = selectedOptions.includes(option.id);

  const handleCheckboxChange = () => {
    setSelectedOptionsUpload(isChecked, option.id);
  };

  return (
    <div className={`checkbox-button ${isChecked ? "checked" : ""}`}>
      <label htmlFor={option.id}>
        <div className="checkbox-button__input">
          <input
            type="checkbox"
            name={option.id}
            value={option.id}
            id={option.id}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <h5>ID: {option.id}</h5>
        </div>
        <div>
          <span>{option.area}</span>
        </div>
      </label>{" "}
    </div>
  );
};

