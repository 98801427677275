import newreq01 from "@images-forest/newreq_01.png";
import newreq02 from "@images-forest/newreq_02.png";
import newreq03 from "@images-forest/newreq_03.png";
import newreq04 from "@images-forest/newreq_04.png";
import newreq05 from "@images-forest/newreq_05.png";
import newreq06 from "@images-forest/newreq_06.png";

const forestPackages = [
  {
    name: "Raszteres adatok",
    unitPrice: 2200,
    items: [
      {
        name: "Digitális ortofotó",
        description:
          "Lombmentes állapotban, a lézerszkenneléssel azonos időben készült RGB színes ortofotó, 20 cm terepi felbontás, EOV vetületi rendszer, GeoTIFF formátum",
        type: "GeoTIFF",
        img: newreq01,
      },
      {
        name: "Digitális domborzatmodell",
        description:
          "DDM (az utolsó lézerjel-visszaverődésekből interpolált talajfelszín modellje, 1*1 m felbontású raszterháló, Balti-tenger feletti magasság értékek, EOV vetületi rendszer, GeoTIFF formátum)",
        type: "GeoTIFF",
        img: newreq02,
      },
      {
        name: "Digitális felszínmodell",
        description:
          "DFM (az első lézerjel-visszaverődésekből interpolált borított felszín modellje, 1*1 m felbontású raszterháló, Balti-tenger feletti magasság értékek, EOV vetületi rendszer, GeoTIFF formátum)",
        type: "GeoTIFF",
        img: newreq03,
      },
      {
        name: "Digitális relatív magasságmodell",
        description:
          "DRM (a DFM és a DDM modellek különbségeként számított magasságmodell, 1*1 m felbontású raszterháló, talajszint feletti magasság értékek, EOV vetületi rendszer, GeoTIFF formátum)",
        type: "GeoTIFF",
        img: newreq04,
      },
    ],
  },
  {
    name: "Fatérfogat adatok",
    unitPrice: 4200,
    items: [
      {
        name: "Koronavetület",
        description:
          "Felismert fakoronák vetületének poligon állománya (ESRI SHP és TopoLynx Map formátum)",
        type: "ESRI SHP",
        img: newreq05,
      },
      {
        name: "Faegyedek",
        description:
          "Felismert fatörzsek pont állománya (ESRI SHP és TopoLynx Map formátum). Leíró adatok minden egyes faegyedre: Sorszám, Faegyed EOV pozíció, Fafaj/fafajcsoport, Famagasság, Koronaátmérő, Koronaterület, Számított mellmagassági átmérő, Számított körlap, Számított fatérfogat.",
        type: "ESRI SHP",
        img: newreq06,
      },
    ],
  },
  {
    name: "Faállomány adatbázis",
    unitPrice: 5200,
    items: [
      {
        name: "Digitális ortofotó",
        description:
          "Lombmentes állapotban, a lézerszkenneléssel azonos időben készült RGB színes ortofotó, 20 cm terepi felbontás, EOV vetületi rendszer, GeoTIFF formátum",
        type: "GeoTIFF",
        img: newreq01,
      },
      {
        name: "Digitális domborzatmodell",
        description:
          "DDM (az utolsó lézerjel-visszaverődésekből interpolált talajfelszín modellje, 1*1 m felbontású raszterháló, Balti-tenger feletti magasság értékek, EOV vetületi rendszer, GeoTIFF formátum)",
        type: "GeoTIFF",
        img: newreq02,
      },
      {
        name: "Digitális felszínmodell",
        description:
          "DFM (az első lézerjel-visszaverődésekből interpolált borított felszín modellje, 1*1 m felbontású raszterháló, Balti-tenger feletti magasság értékek, EOV vetületi rendszer, GeoTIFF formátum)",
        type: "GeoTIFF",
        img: newreq03,
      },
      {
        name: "Digitális relatív magasságmodell",
        description:
          "DRM (a DFM és a DDM modellek különbségeként számított magasságmodell, 1*1 m felbontású raszterháló, talajszint feletti magasság értékek, EOV vetületi rendszer, GeoTIFF formátum)",
        type: "GeoTIFF",
        img: newreq04,
      },
      {
        name: "Koronavetület",
        description:
          "Felismert fakoronák vetületének poligon állománya (ESRI SHP és TopoLynx Map formátum)",
        type: "ESRI SHP",
        img: newreq05,
      },
      {
        name: "Faegyedek",
        description:
          "Felismert fatörzsek pont állománya (ESRI SHP és TopoLynx Map formátum). Leíró adatok minden egyes faegyedre: Sorszám, Faegyed EOV pozíció, Fafaj/fafajcsoport, Famagasság, Koronaátmérő, Koronaterület, Számított mellmagassági átmérő, Számított körlap, Számított fatérfogat.",
        type: "ESRI SHP",
        img: newreq06,
      },
    ],
  },
];

const checkPricing = (active, area) => {
  const totalPrice = forestPackages[active].unitPrice * area;
  return totalPrice;
};

export { forestPackages, checkPricing };
