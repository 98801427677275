// Imports
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { getInit } from "@apis-forest";
import { Aside, Main, MainTop } from "@layout";
import { MapForest, PanelForest } from "@service-forest";
import { StoreApp, StoreUser } from "@store-common";
import { PanelUpload } from "@partials";

export const ServiceForest = () => {
  const { domain_id } = useSnapshot(StoreUser.user);
  const { uploadActive } = useSnapshot(StoreApp);

  // Initial data call
  useEffect(() => {
    getInit(domain_id);
  }, [domain_id]);

  return (
    <>
      <Main>
        <MainTop />
        <MapForest />
      </Main>
      <Aside>
        {uploadActive
          ? <PanelUpload />
          : <PanelForest />
        }
      </Aside>
    </>
  );
};
