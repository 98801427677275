import { useState } from "react";
import { Registration } from "@apis-common";
import { ArrowRight } from "@icons";
import { Button, CheckboxForm, Input, InputVAT } from "@molecules";
import { useModalContext, useToastContext } from "@partials";
import { RegistrationFormFields } from "@store-geostore";
import {
  autoCompleteField,
  dataObject,
  handleInput,
  validateFormData,
} from "@utils-common";
import { Modal } from "..";

export const ModalRegistration = () => {
  const { setModalType } = useModalContext();
  const addToast = useToastContext();
  const [formData, setFormData] = useState(RegistrationFormFields);
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const checkboxObject = formData.find((item) => item.type === "checkbox");

  // Handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = dataObject(formData);
    const isFormValid = await validateFormData(data, formData, setFormData);

    if (isFormValid !== true) setHasFormErrors(isFormValid);

    // Call EP
    if (isFormValid === true) {
      const validFormData = {
        ...data,
        company_vat: data.vatnum,
        domain_id: "670a5c9c-1c12-476e-90a0-bbce8bf56d0c",
        available_services: '["GeoStore"]',
        market_segment: "Téradatbank",
        employee_number: "",
      };

      delete validFormData.checkbox;
      delete validFormData.passwordAgain;

      setHasFormErrors(false);

      Registration(validFormData, setModalType, addToast);
    }
  };

  const handleInputVAT = (name, formattedValue) => {
    setFormData((prevData) => {
      const updatedData = prevData.map((field) =>
        field.name === name
          ? {
              ...field,
              value: formattedValue,
            }
          : field
      );
      return updatedData;
    });
  };

  return (
    <Modal>
      <form onSubmit={handleSubmit}>
        <div className="modal-content__body">
          <p>Igénye leadásához EnviMAP fiókra van szüksége!</p>

          {hasFormErrors && (
            <div className="modal-content__body-form-errors">
              <ul>
                {hasFormErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="modal-content__body-form">
            {formData.map((formField, index) => {
              if (formField.type !== "checkbox") {
                if (formField.name === "company_vat") {
                  return (
                    <InputVAT
                      key={index}
                      label={formField.label}
                      value={formField.value}
                      onChange={(formattedValue) =>
                        handleInputVAT(formField.name, formattedValue)
                      }
                      name={formField.name}
                      required={formField.required}
                      hasError={formField.hasError}
                      onBlur={(e) =>
                        autoCompleteField(e, "completeByVAT", setFormData)
                      }
                    />
                  );
                } else {
                  return (
                    <Input
                      key={index}
                      label={formField.label}
                      type={formField.type}
                      value={formField.value}
                      onChange={(e) => handleInput(e, setFormData)}
                      name={formField.name}
                      required={formField.required}
                      hasError={formField.hasError}
                      onBlur={
                        formField.name === "company"
                          ? (e) =>
                              autoCompleteField(
                                e,
                                "completeByName",
                                setFormData
                              )
                          : undefined
                      }
                    />
                  );
                }
              }
            })}
          </div>
          <div className="modal-content__body-form">
            <CheckboxForm
              checked={checkboxObject.value}
              label={
                <>
                  Elfogadom az{" "}
                  <a
                    href="https://envimap.hu/ENVIROSENSE_HUNGARY_Kft-ASZF-ENVIMAP-Teradatbank.pdf"
                    target="blank"
                  >
                    Általános Szerződési Feltételek
                  </a>
                  -ben leírtakat és hozzájárulok adataim kezeléséhez.
                </>
              }
              name={checkboxObject.name}
              onChange={(e) => handleInput(e, setFormData)}
              value={checkboxObject.value}
              required={checkboxObject.required}
              hasError={checkboxObject.hasError}
            />
            <Button
              iconPosition="after"
              icon={<ArrowRight />}
              text="Regisztráció"
              type="submit"
              value="download"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
