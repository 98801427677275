const getAvailableServicesList = (service) => {
  switch (true) {
    case service === "GeoStore":
      return "service-geostore";
    case service === "Solar":
      return "service-roof";
    case service === "Forest":
      return "service-forest";
    default:
      console.error("Nincs ilyen szolgáltatás!");
  }
};

export { getAvailableServicesList };
