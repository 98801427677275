import { useModalContext } from ".";
import {
  ModalLogin,
  ModalRegistration,
  ModalRegistrationSuccess,
} from "./types";
import { ModalDiscount } from "./types/ModalDiscount";
import { ModalPurchasingProcess } from "./types/ModalPurchasingProcess";

export const ModalManager = () => {
  const { modalType, isModalOpen } = useModalContext();

  const ModalComponent = () => {
    switch (true) {
      case modalType === "login":
        return <ModalLogin />;
      case modalType === "registration":
        return <ModalRegistration />;
      case modalType === "registrationSuccess":
        return <ModalRegistrationSuccess />;
      case modalType === "discount":
        return <ModalDiscount />;
      case modalType === "purchasingProcess":
        return <ModalPurchasingProcess />;
      default:
        break;
    }
  };

  return isModalOpen && <ModalComponent />;
};
