import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ArrowDown, ArrowRight } from "@icons";
import { StoreApp, StoreDomain } from "@store-common";
import { getAvailableServicesList } from "@utils-common";

export const ServiceSwitcher = () => {
  const navigate = useNavigate();
  const { services, activeService } = useSnapshot(StoreApp);
  const { available_services } = useSnapshot(StoreDomain);
  const [selectableServices, setSelectableServices] = useState([]);
  const [dropDownService, setDropDownService] = useState(false);

  // Set selectable services
  useEffect(() => {
    if (available_services.length > 0) {
      // Filter for services available for the users domain
      const domainAvailableServices = available_services.map((service) =>
        getAvailableServicesList(service)
      );

      const availableServices = services.filter((service) =>
        domainAvailableServices.includes(service.key)
      );
      // Set services that the user can select
      setSelectableServices(
        availableServices.filter(
          (service) => service.name !== services[activeService].name
        )
      );
    }
  }, [activeService, available_services]);

  return (
    <div className={`navbar-left__service ${dropDownService && "active"}`}>
      {selectableServices <= 1 ? (
        <div className="navbar-left__service-current">
          {services[activeService].name}
        </div>
      ) : (
        <button
          className="navbar-left__service-current"
          onClick={() => setDropDownService(!dropDownService)}
        >
          {services[activeService].name}
          <ArrowDown color="white" />
        </button>
      )}

      {dropDownService && (
        <div className="navbar-left__service-dropdown">
          <ul>
            {selectableServices.map((service, index) => (
              <li key={index}>
                <button onClick={() => navigate("/" + service.slug)}>
                  {service.name} <ArrowRight />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
