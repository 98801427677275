// Imports
import { fromLonLat } from 'ol/proj';

const baseViewProjection = "EPSG:3857";
const baseViewCenter = [parseFloat("19.503304"), parseFloat("47.162494")];
const baseViewWMCenter = fromLonLat(baseViewCenter, baseViewProjection);

export const MapConfig = {
  baseViewProjection,
  baseViewCenter,
  baseViewWMCenter,
  initZoom: 8,
  maxZoom: 20,
  pinZoom: 18,
  maxViewExtent: () => {
    const c = baseViewWMCenter;
    const p = 500 * 1000;
    return [c[0] - p, c[1] - p, c[0] + p, c[1] + p];
  }
};