import { createContext, useEffect, useRef, useState } from "react";
import { cookieGetGeoStoreInfo } from "@apis-common";
// Layout
import { MainBottom } from "@layout";
// Partials
import {
  MapConfig,
  availabilityLayer,
  backgroundLayer,
  satalaiteLayer,
  ortoLayer,
  maskAvailabilityLayer,
  previousPolygonLayer,
  drawVectorLayer,
  clearDraw,
  zoomToFeatureExtent,
  useToastContext,
} from "@partials";
import { PanelBottom } from "@service-geostore";
import {
  addCookieFeaturesToLayer,
  addPreviousFeaturesToLayer,
  changeInteraction,
  handleMapHover,
  handleSearch,
} from "./functions.MapGeoStore";
import Map from "ol/Map";
import View from "ol/View";
import { defaults as controlDefaults } from "ol/control/defaults";
import { defaults as interactionDefaults } from "ol/interaction/defaults";
import { useSnapshot } from "valtio";
import { StoreRequests, setPanelBottomActive, setRequest } from "@store-geostore";
import { StoreApp } from "@store-common";
import { checkDrawVectorLayerExist } from "components/partials/maptools/toolbar/utils.toolbar";

import { useSearchParams } from "react-router-dom";

// Create MapContext context
const MapContext = createContext();

export const MapGeostore = () => {
  // Map and layers
  const mapRef = useRef();
  const [ map, setMap ] = useState(null);
  const [ mapInit, setMapInit ] = useState(false);
  const [ prevRequestsInit, setPrevRequestsInit ] = useState(false);
  const { prevRequests, activeRequestData, panelBottomActive } = useSnapshot(StoreRequests);
  const { asideActive, searchBounds } = useSnapshot(StoreApp);
  // url param
  const [urlParam, setUrlParam] = useSearchParams();
  const [urlParamInit, setUrlParamInit] = useState(false);
  // toast
  const addToast = useToastContext();

  // Init map
  useEffect(() => {
    let options = {
      view: new View({
        projection: MapConfig.baseViewProjection,
        center: MapConfig.baseViewWMCenter,
        zoom: MapConfig.initZoom,
        minZoom: MapConfig.initZoom,
        maxZoom: MapConfig.maxZoom,
        extent: MapConfig.maxViewExtent(),
      }),
      layers: [
        backgroundLayer,
        satalaiteLayer,
        ortoLayer,
        availabilityLayer,
        previousPolygonLayer
      ],
      controls: controlDefaults({
        zoom: false,
        rotate: false,
      }),
      interactions: interactionDefaults({
        pinchRotate: false,
      }),
    };
    let mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []);

  // Initial map effect
  useEffect(() => {
    if (map && !mapInit) {
      // Clean previousPolygonLayer
      previousPolygonLayer.getSource().clear();
      
      // Check cookie
      const cookieInfo = cookieGetGeoStoreInfo();
      if (cookieInfo) {
        setUrlParamInit(true);
        checkDrawVectorLayerExist(map);
        addCookieFeaturesToLayer(cookieInfo, drawVectorLayer, map);
      }
      // Finish map initalization
      setMapInit(true);
    }
  }, [map, mapInit]);

  // Add previous request to map
  useEffect(() => {
    if (prevRequests.length > 0) {
      // Add prev requests
      addPreviousFeaturesToLayer(prevRequests, previousPolygonLayer);
      setPrevRequestsInit(true);
    }
  }, [prevRequests]);

  // Check Initial UrlParam
  useEffect(() => {
    if (prevRequestsInit) {
      const previousFeatures = previousPolygonLayer.getSource().getFeatures();
      
      if (!urlParamInit && previousFeatures) {
        // Get initial url param
        const initId = urlParam.get("env");

        if (initId) {
          // Find ID inside prevRequests
          let findFeature = previousFeatures.find(
            (e) => e.values_.data.request_id === "ENV-" + initId
          );

          // Go ahead if ID matched with any feature
          if (findFeature) {
            setRequest(findFeature.values_.data);
            zoomToFeatureExtent(map, findFeature, 200);
            setPanelBottomActive(true);
          } else {
            addToast([
              "error",
              `A keresett azonosító (ENV-${initId}) nem elérhető!`,
            ]);
          }
        }
        // Set init state
        setUrlParamInit(true);
      }
    }
  }, [prevRequestsInit, urlParamInit]);

  // Handle UrlParam Change when activePrevPin StoreApp changes
  useEffect(() => {
    if (urlParamInit) {
      if (panelBottomActive) {
        // Set URLSearchParam -> ID
        setUrlParam({ env: activeRequestData.request_id.replace(/\D/g, "") });
      } else {
        // Unset URLSearchParam -> ID
        urlParam.delete("env");
        setUrlParam(urlParam);
      }
    }
  }, [urlParamInit, panelBottomActive, activeRequestData, urlParam, setUrlParam]);

  // Handle Map events
  useEffect(() => {
    if (map) {
      // Hover click
      handleMapHover(map);
      // Feature click
      changeInteraction(map);
      // Mask Google Satalite with AvailabilityLayer
      maskAvailabilityLayer();
    }
  }, [map]);

  // ClearDraw if aside has been closed
  useEffect(() => {
    if (!asideActive) clearDraw(map);
  }, [asideActive]);

  // Handle search
  useEffect(() => {
    if (map && searchBounds) handleSearch(map, searchBounds);
  }, [map, searchBounds]);

  return (
    <>
      <MapContext.Provider value={{ map: map }}>
        <div className="map" ref={mapRef} />
      </MapContext.Provider>
      <PanelBottom map={map} />
      <MainBottom map={map} />
    </>
  );
};
