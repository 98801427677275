// Imports
import { useState } from "react";
import { ViewHide, ViewShow } from "@icons";

export const InputPassword = ({
  label,
  type = "password",
  placeholder,
  value,
  onChange,
  name,
  required = false,
  error,
}) => {
  const [showPass, toogleShowPass] = useState(false);
  return (
    <div className={`input ${error ? "error" : ""}`}>
      <label>
        {label}
        <input
          type={showPass ? "text" : type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={type === "password" ? "on" : ""}
          required={required}
        />
        <span
          className="password-visibility"
          onClick={() => toogleShowPass(!showPass)}
        >
          {showPass ? <ViewShow /> : <ViewHide />}
        </span>
      </label>
    </div>
  );
};
