// Imports
import { useSnapshot } from "valtio";
import { ButtonClose } from "@molecules";
import {
  StoreApp,
  setAside,
  setToolbarHidden,
} from "@store-common";
import { StoreNewRequest } from "@store-forest";
import { PanelNewRequestForest, PanelNoData } from "@service-forest";

// Main Panel component
export const PanelForest = () => {
  const { asideActive, activeService } = useSnapshot(StoreApp);
  const { newRequestData} = useSnapshot(StoreNewRequest);

  if (asideActive && typeof newRequestData?.available !== 'undefined') {
    return (
      <div className="panel forest">
        <div className="panel__header">
          <div className="panel__header__content">
            <h4>Új igény</h4>
            <p>{newRequestData.resolved_addr.locality + ' - ' + newRequestData.request_data.total_area + " hektár"}</p>
          </div>
          <ButtonClose
            action={() => {
              setAside(false);
              setToolbarHidden(false, activeService);
            }}
          />
        </div>

        <div className="panel__content">
          {newRequestData.available
            ? (
              <PanelNewRequestForest setAside={setAside}/>
            ) : (
              <PanelNoData />
            )
          }
        </div>
      </div>
    );
  } else {
    return <div className="panel" />;
  }
};
