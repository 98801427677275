// Imports
import { useSnapshot } from "valtio";
import { getZipFile } from "@apis-geostore";
import { Download } from "@icons";
import { Button, ButtonClose } from "@molecules";
import { useToastContext } from "@partials";
import { StoreRequests, setPanelBottomActive } from "@store-geostore";
import { formatStatus } from "@utils-common";
import { deselectFeatures } from "../map/functions.MapGeoStore";

export const PanelBottom = ({ map }) => {
  const { panelBottomActive, activeRequestData } = useSnapshot(StoreRequests);
  const addToast = useToastContext();

  // Download zipped project files
  const downloadZIP = () => {
    const request_id = activeRequestData.request_id.replace(/\D/g, "");
    getZipFile(request_id, addToast);
  };

  return (
    <div className={`panel__bottom ${panelBottomActive ? "active" : ""}`}>
      <div className="panel__bottom-header">
        <div>
          <div>
            <h4>{activeRequestData.request_id}</h4>
            <div className="panel__bottom-header__meta">
              <span>{activeRequestData.polygons} terület - </span>
              <span>{activeRequestData.area} km²</span>
            </div>
            <div className="panel__bottom-header__city">
              <span>{activeRequestData.city}</span>
            </div>
          </div>
        </div>
        <ButtonClose
          action={() => {
            setPanelBottomActive(false);
            // Remove selected feature
            deselectFeatures(map);
          }}
        />
      </div>
      <hr />
      <div className="panel__bottom-body">
        
        <div className="panel__bottom-body-data">
          <div className="panel__bottom-body-data-col">
            <span>Állapot</span>
            <span className="span--bold">
              <span
                className={`status-circle ${activeRequestData.status}`}
              ></span>{" "}
              {formatStatus(activeRequestData.status)}
            </span>
          </div>
          {/*
          <div className="panel__bottom-body-data-col">
            <span>
              {(activeRequestData.status === "Available" ||
                activeRequestData.status === "Archived") &&
                "Elérhető"}
              {activeRequestData.status === "Pending" && "Várható teljesítés"}
              {activeRequestData.status === "Saved" && "Hozzáadva"}
            </span>
            <span className="span--bold">
              {activeRequestData.deadline}
              {(activeRequestData.status === "Available" ||
                activeRequestData.status === "Archived") &&
                "-ig"}
            </span>
          </div>
          */}
          <div className="panel__bottom-body-data-col">
            <span>Igényelte</span>
            <span className="span--bold">
              {activeRequestData.user.first_name +
                " " +
                activeRequestData.user.last_name}
            </span>
          </div>
        </div>

        <div className="panel__bottom-body-layers">
          <span>Termékek</span>
          <ul className="panel__bottom-body-layers-list">
            {activeRequestData.layers.map((layer, i) => {
              return (
                <li key={i}>
                  <span className="span--bold">{layer.title}</span>
                </li>
              );
            })}
          </ul>
        </div>

        {activeRequestData.status === "Available" && (
          <Button
            iconPosition="after"
            icon={<Download />}
            text="Csomag letöltése"
            type="button"
            value="download"
            action={downloadZIP}
          />
        )}
        {activeRequestData.status === "Pending" && (
          <form action={`mailto:info@envimap.hu?subject=${activeRequestData.request_id}`}>
            <Button secondary text="Kapcsolatfelvétel" type="submit" />
          </form>
        )}
      </div>
    </div>
  );
};
