import { ArrowRight, Close } from "@icons";
import { ModalLayout, useModalContext } from ".";

export const Modal = ({ children, fullH }) => {
  const { modalType, setModalType, setIsModalOpen } = useModalContext();
  return (
    <ModalLayout fullH={fullH}>
      <div className="modal-content__header">
        <h2>
          {modalType === "registration" ? "Regisztráljon" : "Bejelentkezés"}
        </h2>
        <button onClick={() => setIsModalOpen(false)}>
          <Close />
        </button>
      </div>
      {children}
      <div className="modal-content__footer">
        <h6>
          {modalType === "registration"
            ? "Van már EnviMAP fiókja?"
            : "Nincs még fiókja?"}
        </h6>
        <button
          onClick={() =>
            setModalType(
              modalType === "registration" ? "login" : "registration"
            )
          }
        >
          {modalType === "registration" ? "Jelentkezzen be!" : "Regisztráljon"}
          <ArrowRight />
        </button>
      </div>
    </ModalLayout>
  );
};
