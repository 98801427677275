const RegistrationFormFields = [
  {
    label: "Cégnév",
    type: "text",
    name: "company",
    value: "",
    required: true,
    hasError: false,
  },
  {
    label: "Adószám",
    type: "text",
    name: "vatnum",
    value: "",
    required: true,
    hasError: false,
  },
  {
    label: "Vezetéknév*",
    type: "text",
    name: "last_name",
    value: "",
    required: true,
    hasError: false,
  },
  {
    label: "Keresztnév*",
    type: "text",
    name: "first_name",
    value: "",
    required: true,
    hasError: false,
  },
  {
    label: "Email cím*",
    type: "text",
    name: "email",
    value: "",
    required: true,
    hasError: false,
  },
  {
    label: "Telefonszám*",
    type: "text",
    name: "phone",
    value: "",
    required: true,
    hasError: false,
  },
  {
    label: "Jelszó*",
    type: "password",
    name: "password",
    value: "",
    required: true,
    hasError: false,
  },
  {
    label: "Jelszó újra*",
    type: "password",
    name: "passwordAgain",
    value: "",
    required: true,
    hasError: false,
  },
  {
    label:
      "Elfogadom az Általános Szerződési Feltételek-ben leírtakat és hozzájárulok adataim kezeléséhez.",
    name: "checkbox",
    value: false,
    type: "checkbox",
    required: true,
    hasError: false,
  },
];

const LoginFormFields = [
  {
    label: "Email-cím*",
    type: "text",
    name: "email",
    value: "",
  },
  {
    label: "Jelszó*",
    type: "password",
    name: "password",
    value: "",
  },
  {
    label: "Jegyezzen meg",
    value: false,
    name: "rememberMe",
    type: "checkbox",
  },
];



export {
  RegistrationFormFields,
  LoginFormFields,
};
