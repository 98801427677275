// Imports
import { setAside } from "@store-common";
import { setPanelState } from "@store-geostore";
import { mapProductsData } from "@utils-geostore";
import { cookieGetToken, cookieRemoveGeoStore, urls } from "api/common";
import { CheckError } from "utils/common";

// Sending a request with selected position
export const postNewRequestGeostore = async (
  body,
  message,
  setPanelData,
  products,
  setPanelInfo
) => {
  await fetch(urls.postNewRequest, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      CheckError(
        response,
        message,
        "Az igény sikeresen leadásra került!",
        "Hiba lépett fel az igény leadásakor!"
      );

      // Init panel data
      const mappedProds = mapProductsData(products);
      setPanelInfo({});
      setPanelData(mappedProds.map((item) => ({ ...item, checkbox: false })));

      // Reset cookie
      cookieRemoveGeoStore();

      // Close panel
      setAside(false);

      // Set panel default state
      setPanelState(0);
    })
    .then((actualData) => console.log(actualData))
    .catch((error) => console.log(error));
};
