import { CartMinimum } from "@store-geostore";


export const calculateCartMinimum = (
  totalAmount,
  setPanelInfoCartMinimum,
) => {
  if (!CartMinimum.enabled) return;
  setPanelInfoCartMinimum(totalAmount > 0 && totalAmount < CartMinimum.minimumAmount);
};


