export const addDaysToDate = (dateString, days) => {
  const inputDate = new Date(dateString);
  inputDate.setDate(inputDate.getDate() + days);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  return `${year}.${month}.${day}.`;
};

export const getFormattedDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  if (month < 10) {
    month = '0' + month;
  }

  if (day < 10) {
    day = '0' + day;
  }

  return `${year}-${month}-${day}`;
};