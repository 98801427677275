export const Input = ({
  label,
  type = "text",
  placeholder,
  value,
  onChange,
  name,
  required = false,
  disabled = false,
  hasError,
  onBlur,
}) => {
  return (
    <div className={`input${hasError ? " error" : ""}`}>
      <label>
        {label}
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={type === "password" ? "on" : ""}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
        />
      </label>
    </div>
  );
};
