// Imports
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ModalContenxtProvider, ServiceStatus, TCResolver, ToastContextProvider } from "@partials";
import { ServiceForest } from "@service-forest";
import { ServiceGeostore } from "@service-geostore";
import { ServiceRoof } from "@service-roof";
import {
  StoreApp,
  StoreDomain,
  StoreUser,
  setActiveService,
  setAside,
} from "@store-common";
import { getAvailableServicesList } from "@utils-common";
import {
  GaInit,
  cookieGetGeoStoreInfo,
  cookieGetTempPassword,
  cookieGetToken,
  getProfile,
} from "api/common";

// View Height calculation utility
const calculateVh = () => {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");
};

// APP
const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { services, asideActive, panelInfoActive, activeService } =
    useSnapshot(StoreApp);
  const { userLoggedIn } = useSnapshot(StoreUser);
  const { available_services } = useSnapshot(StoreDomain);
  // const statusMessage = "Szolgáltatásunkban 2023. október 12-én és 13-án karbantartást végzünk. Ezeken a napokon az épületmodell megrendeléseket rögzítjük, de teljesítésükben néhány napos késedelem várható. Megrendelését kérem az előzmények figyelembevételével adja le. Megértését köszönjük!"

  // Check logged in StoreApp with userToken cookie
  useEffect(() => {
    // Initialize Google Analytics
    GaInit();

    // Handle login cookie
    if (!cookieGetToken() || cookieGetTempPassword()) {
      //window.location.assign("https://account.envimap.hu");
      console.warn(
        "Visitor type: %cguest 🕵",
        "color: #0A0A29; font-weight: bold"
      );
    } else {
      console.warn(
        "Visitor type: %cuser 🧑‍💻",
        "color: #0A0A29; font-weight: bold"
      );
      getProfile();
    }
  }, []);

  // Update the active service in response to changes in the URL
  useEffect(() => {
    if (available_services.length > 0) {
      const APIServices = available_services.map((service) =>
        getAvailableServicesList(service)
      );

      if (!APIServices) return;

      let redirectURL = "/";
      const slug = location.pathname.split("/")[1];
      const requestedService = services.find(
        (service) => service.slug === slug
      );

      // Handle if the GeoStore is disabled
      if (!APIServices.includes("service-geostore")) {
        const fallbackService = services.find(
          (service) => service.key === APIServices[0]
        );
        redirectURL = fallbackService.slug;
      }

      // Set active service or navigate to GeoStore
      if (requestedService) {
        const isServiceAvailable = APIServices.includes(requestedService.key);

        isServiceAvailable
          ? setActiveService(requestedService.id)
          : navigate(redirectURL);
      } else {
        navigate(redirectURL);
      }
    }
  }, [location, available_services]);

  // Fix mobile vh issue
  useEffect(() => {
    calculateVh();
    window.addEventListener("resize", calculateVh);
    window.addEventListener("orientationchange", calculateVh);
  }, []);

  // Fix mobile overflow problem
  useEffect(() => {
    if (!asideActive) {
      document.body.classList.add("fixed");
    } else {
      document.body.classList.remove("fixed");
    }
  }, [asideActive]);

  // Open panel if user/guest has stored polygon in cookies
  useEffect(() => {
    const cookieInfo = cookieGetGeoStoreInfo();
    if (cookieInfo && activeService === 0) setAside(true);
    else {
      setAside(false);
    }
  }, [activeService]);

  return (
    <ToastContextProvider>
      <div className="App">
        <ModalContenxtProvider>
          <ServiceStatus />
          <div
            className={`layout ${asideActive ? "aside-active" : ""}${
              panelInfoActive ? " panel-info-active" : ""
            }`}
          >
            <Routes>
              <Route path="/">
                {userLoggedIn ? (
                  <>
                    <Route index element={<ServiceGeostore />} />
                    <Route path="teto" element={<ServiceRoof />} />
                    <Route path="erdo" element={<ServiceForest />} />
                  </>
                ) : (
                  <Route index element={<ServiceGeostore />} />
                )}
                <Route path="tc-resolver" element={<TCResolver />} />
              </Route>
            </Routes>
          </div>
        </ModalContenxtProvider>
      </div>
    </ToastContextProvider>
  );
};

export default App;
