import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Fill, Stroke, Style } from "ol/style";

// Upload layer

const uploadDefaultStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.3)",
  }),
  stroke: new Stroke({
    color: "#00D5FF",
    width: 2,
  }),
});

export const uploadSelectedStyle = new Style({
  fill: new Fill({
    color: "#00D5FF",
  }),
  stroke: new Stroke({
    color: "#00D5FF",
    width: 4,
  }),
});

export const uploadVectorLayer = new VectorLayer({
  title: "upload-layer",
  source: new VectorSource(),
  style: (feature) => {
    return styleFunction(feature.get("selected"));
  },
});

const styleFunction = (selected) => {
  if (selected) {
    return uploadSelectedStyle;
  } else {
    return uploadDefaultStyle;
  }
};