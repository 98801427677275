import { createContext, useContext, useState } from "react";
import { ModalManager } from "./ModalManager";

export const ModalContext = createContext();

export const ModalContenxtProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("registration");
  return (
    <ModalContext.Provider
      value={{
        modalType: modalType,
        isModalOpen: isModalOpen,
        setIsModalOpen: setIsModalOpen,
        setModalType: setModalType,
      }}
    >
      <ModalManager />
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
