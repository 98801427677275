// Imports
import { proxy } from "valtio";

export const StoreProductsGeoStore = proxy({
  products: []
});


// Set toolbar to make done StoreApp
export const setProductsGeoStore = (products) => {
  // Sort the products based on the order field
  const sortedProducts = products.slice().sort((a, b) => a.order - b.order);
  // Add to proxy object
  StoreProductsGeoStore.products = sortedProducts;
};