// Imports
import { useSnapshot } from "valtio";
import { StoreModel, setModelViewerFullscreen } from "@service-roof";
import { Expand } from "@icons";

// FULLSCREEN
const Fullscreen = () => {
  const { fullScreen } = useSnapshot(StoreModel.view);

  return (
    <div
      className={`fullscreen ${fullScreen ? "active" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        setModelViewerFullscreen();
      }}
    >
      <div className="fullscreen__icon">
        <Expand expanded={fullScreen} color="white" />
      </div>
      <div className="fullscreen__overlay" />
    </div>
  );
};

export default Fullscreen;
