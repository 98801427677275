// Imports
import { useState } from "react";
import { useSnapshot } from "valtio";
import { StoreModel, setModelViewerHelp } from "@service-roof";
import { Close, Cursor, Help as HelpIcon, Ruler } from "@icons";
import { useHover } from "@use-gesture/react";

// HELP BUTTON
const HelpButton = () => {
  const { helpOpen } = useSnapshot(StoreModel.view);

  return (
    <div className={`helpButton ${!helpOpen ? "inactive" : ""}`}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setModelViewerHelp();
        }}
        title={helpOpen ? "Teljes képernyő bezárása" : "Súgó megnyitása"}
      >
        <HelpIcon />
      </button>
    </div>
  );
};

// HELP PANEL
const HelpPanel = () => {
  const { helpOpen } = useSnapshot(StoreModel.view);

  const data = [
    {
      title: "Navigáció",
      icon: null,
      items: [
        {
          figure: "mouse-left",
          interaction: "dragging",
          caption: "Modell forgatása",
        },
        {
          figure: "mouse-right",
          interaction: "dragging",
          caption: "Modell mozgatása",
        },
        {
          figure: "mouse-scroll",
          interaction: "scrolling",
          caption: "Nagyítás görgetéssel",
        },
        {
          figure: "key-tab",
          interaction: "shortpress",
          caption: "Váltás az eszközök között",
        },
      ],
    },
    {
      title: "Kurzor műveletek",
      icon: <Cursor />,
      items: [
        {
          figure: "mouse-left",
          interaction: "clicking",
          caption: "Sík kijelölése kattintással",
        },
        {
          figure: "key-shift",
          interaction: "longpress",
          caption: "Több sík kijelölése",
        },
        {
          figure: "key-esc",
          interaction: "shortpress",
          caption: "Panel bezárása",
        },
      ],
    },
    {
      title: "Mérőszalag műveletei",
      icon: <Ruler />,
      items: [
        {
          figure: "mouse-left",
          interaction: "clicking",
          caption: "Mérési pont lerakása kattintással",
        },
        {
          figure: "key-esc",
          interaction: "shortpress",
          caption: "Mérés törlése",
        },
      ],
    },
  ];

  return (
    <aside className={`helpPanel ${helpOpen ? "active" : ""}`}>
      <div className="content">
        <div className="head">
          <h4>Műveletek</h4>
          <button
            className="close"
            onClick={(e) => {
              e.stopPropagation();
              setModelViewerHelp();
            }}
          >
            <Close />
          </button>
        </div>
        {data.map((section, i) => {
          return <RenderSection key={i} section={section} />;
        })}
      </div>
    </aside>
  );
};

const RenderSection = ({ section }) => {
  const grid =
    section.items.length >= 2
      ? section.items.length % 2 === 0
        ? "halfGrid"
        : "thirdGrid"
      : null;

  return (
    <section>
      <div className="head">
        {section.icon}
        <h5>{section.title}</h5>
      </div>
      <ul className={"items " + grid}>
        {section.items.map((item, i) => {
          return <RenderItem key={i} item={item} />;
        })}
      </ul>
    </section>
  );
};

const RenderItem = ({ item }) => {
  const [hover, setHover] = useState(false);

  // Hover handling
  const hoverBind = useHover(({ active }) => {
    setHover(active);
  });

  return (
    <li className={hover ? "active" : ""} {...hoverBind()}>
      <Figure type={item.figure} interaction={item.interaction} />
      <span>{item.caption}</span>
    </li>
  );
};

const Figure = ({ type, interaction }) => {
  switch (type) {
    case "mouse-left":
      return (
        <svg
          className={"figure " + interaction}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 80 48"
        >
          <g className="mouse drag">
            <path
              className="left"
              d="M30 16C30 12.134 33.134 9 37 9H40V23H30V16Z"
            />
            <path
              className="body"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37 8C32.5817 8 29 11.5817 29 16V32C29 36.4183 32.5817 40 37 40H43C47.4183 40 51 36.4183 51 32V16C51 11.5817 47.4183 8 43 8H37ZM43 10H41V22H49V16C49 12.6863 46.3137 10 43 10ZM49 24H31V32C31 35.3137 33.6863 38 37 38H43C46.3137 38 49 35.3137 49 32V24ZM31 22H39V10H37C33.6863 10 31 12.6863 31 16V22Z"
            />
          </g>
        </svg>
      );
    case "mouse-right":
      return (
        <svg
          className={"figure " + interaction}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 80 48"
        >
          <g className="mouse drag">
            <path
              className="right"
              d="M40 9H43C46.866 9 50 12.134 50 16V23H40V9Z"
            />
            <path
              className="body"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37 8C32.5817 8 29 11.5817 29 16V32C29 36.4183 32.5817 40 37 40H43C47.4183 40 51 36.4183 51 32V16C51 11.5817 47.4183 8 43 8H37ZM43 10H41V22H49V16C49 12.6863 46.3137 10 43 10ZM49 24H31V32C31 35.3137 33.6863 38 37 38H43C46.3137 38 49 35.3137 49 32V24ZM31 22H39V10H37C33.6863 10 31 12.6863 31 16V22Z"
            />
          </g>
        </svg>
      );
    case "mouse-scroll":
      return (
        <svg
          className={"figure " + interaction}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 80 48"
        >
          <g className="mouse">
            <path
              className="body"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29 16C29 11.5817 32.5817 8 37 8H43C47.4183 8 51 11.5817 51 16V32C51 36.4183 47.4183 40 43 40H37C32.5817 40 29 36.4183 29 32V16ZM31 32V24H49V32C49 35.3137 46.3137 38 43 38H37C33.6863 38 31 35.3137 31 32Z"
            />
            <path
              className="scroll"
              d="M38 14C38 12.8954 38.8954 12 40 12C41.1046 12 42 12.8954 42 14V18C42 19.1046 41.1046 20 40 20C38.8954 20 38 19.1046 38 18V14Z"
            />
          </g>
        </svg>
      );
    case "key-tab":
      return (
        <div className={"figure " + interaction}>
          <div className="key">Tab</div>
        </div>
      );
    case "key-shift":
      return (
        <div className={"figure " + interaction}>
          <div className="key">Shift</div>
        </div>
      );
    case "key-esc":
      return (
        <div className={"figure " + interaction}>
          <div className="key">Esc</div>
        </div>
      );
    default:
      return null;
  }
};

const Help = {
  HelpButton,
  HelpPanel,
};

export default Help;
