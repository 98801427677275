// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { ArrowDown, ArrowDropDown, Download } from "@icons";
import { Checkbox } from "@molecules";
import {
  handleListPanelActiveTab,
  handleListPanelOpen,
  handleSelection,
} from "@service-roof";
import { StoreRequests } from "@store-roof";
import {
  createInteractionGeometry,
  createUniqueArray,
  exportDataToCSV,
  listItemsReducer,
  round,
  useSummaryCalculator,
} from "@utils-roof";
import { StoreModel, resetTool } from "../StoreModel";
import { ListPanelGroupSelect } from "./ListPanelGroupSelect";
import { ListPanelItem } from "./ListPanelItem";

// LIST PANEL
const ListPanel = () => {
  const { activeRequestData } = useSnapshot(StoreRequests);
  const { model } = useSnapshot(StoreModel);
  const { listPanelOpen, listPanelActiveTab } = useSnapshot(StoreModel.view);
  const { selection } = useSnapshot(StoreModel.cursor);
  const { poiUID } = useSnapshot(StoreModel.pointer);

  const itemTypes = ["sides", "edges"];
  const [panelData, setPanelData] = useState(null);
  const [summary, setSummary] = useState({ area: 0, slope: 0, length: 0 });

  const [groupSelectOpen, setGroupSelectOpen] = useState(false);
  const [groupCheckboxState, setGroupCheckboxState] = useState(0);
  const [typesArray, setTypesArray] = useState([]);


  // Initialize Tabs and Items
  useEffect(() => {
    setPanelData([
      { label: "Síkok", items: listItemsReducer(model.sides, ["Alap", "Fal"]) },
      { label: "Élek", items: listItemsReducer(model.edges, []) },
    ]);
  }, [model]);

  // Handle GroupCheckboxState
  useEffect(() => {
    if (!panelData || !listPanelOpen) return;
    
    const selectedItems = selection.items;
    const panelItems = panelData[listPanelActiveTab].items;
    
    const hasAllSelected = panelItems.length === 0
      ? false
      : selectedItems.length >= panelItems.length &&
        panelItems.every(obj => {
          return selectedItems.some(selectedObj => selectedObj.uID === obj.uID);
        });
    
    const hasAnySelected = panelItems.some(obj => {
      return selectedItems.some(selectedObj => selectedObj.uID === obj.uID);
    });
    
    setGroupCheckboxState(hasAllSelected ? 2 : hasAnySelected ? 1 : 0);
  }, [panelData, listPanelOpen, listPanelActiveTab, selection]);

  // Set checkbox all switch
  const setGroupCheckbox = () => {
    // Reset selection if there is any
    if(selection.items.length >= 1) {
      resetTool(0);
    }
    // Add all if needed
    if(groupCheckboxState < 2) {
      panelData[listPanelActiveTab].items.forEach(item => {
        handleSelectionChange(item.uID);
      });
    }
  };

  // Handle section changes
  const handleSelectionChange = (uID) => {
    const newItem = createInteractionGeometry(
      model,
      itemTypes[listPanelActiveTab],
      uID
    );

    handleSelection(
      uID,
      itemTypes[listPanelActiveTab],
      newItem.geometry,
      newItem.shiftPosition,
      newItem.centerPoint
    );
  };

  // Handle summary totals
  useEffect(() => {
    setSummary(useSummaryCalculator(
      selection,
      model.sides,
      model.edges
    ));
  }, [selection]);

  // Set filter types array
  useEffect(() => {
    if (!panelData) return;
    setTypesArray(createUniqueArray(panelData[listPanelActiveTab]));
  }, [panelData, listPanelActiveTab]);


  if (!panelData) return;
  return (
    <div className={`modellistpanel ${listPanelOpen ? "opened" : ""}`}>
      <button className="modellistpanel__header" onClick={handleListPanelOpen}>
        <h5>Elemlista</h5>
        <ArrowDown />
      </button>

      {listPanelOpen && (
        <div className="modellistpanel__container">
          <div className="modellistpanel__tab">
            {panelData.map((tab, i) => {
              return (
                <button
                  key={i}
                  className={i === listPanelActiveTab ? "active" : ""}
                  onClick={() => handleListPanelActiveTab(i)}
                >
                  {tab.label}
                </button>
              );
            })}
          </div>
          <div className="modellistpanel__content">
            <div className="modellistpanel__content-actions">
              <div>
                <button onClick={() => setGroupCheckbox()}>
                  <Checkbox
                    state={groupCheckboxState === 0
                      ? 'false'
                      : groupCheckboxState === 1
                        ? 'mixed'
                        : 'true'
                    } />
                </button>

                <button onClick={() => setGroupSelectOpen(!groupSelectOpen)}>
                  <span
                    className={`modellistpanel__content-actions__dropdown ${
                      groupSelectOpen ? "active" : ""
                    }`}
                  >
                    <ArrowDropDown />
                  </span>
                </button>
              </div>
              <button
                className="export"
                onClick={() =>
                  exportDataToCSV(panelData, activeRequestData.request_id)
                }
              >
                <Download />
                <span>XLS</span>
              </button>
            </div>

            {groupSelectOpen && (
              <div className="modellistpanel__content-groupselection">
                <ListPanelGroupSelect
                  typesArray={typesArray}
                  panelItems={panelData[listPanelActiveTab].items}
                  selectedItems={selection.items}
                  handleSelectionChange={handleSelectionChange}
                />
              </div>
            )}

            <div className="modellistpanel__content-list">
              {panelData[listPanelActiveTab].items.map((item, i) => {
                return (
                  <ListPanelItem
                    key={i}
                    item={item}
                    itemTypes={itemTypes}
                    listPanelActiveTab={listPanelActiveTab}
                    isHovered={poiUID === item.uID}
                    isSelected={selection.items?.some((selectedItem) => item.uID === selectedItem.uID)}
                    handleSelectionChange={handleSelectionChange}
                  />
                );
              })}
            </div>
          </div>

          <div className="modellistpanel__summary">
            <h5>Összesen</h5>
            <ul className="meta">
              {listPanelActiveTab === 0 && (
                <>
                  <li>
                    <span className="span--bold">
                      {round(summary.area, 2)} m²
                    </span>
                  </li>
                  <li>
                    <span className="span--bold">
                      {round(summary.slope, 2)}°
                    </span>
                  </li>
                </>
              )}
              {listPanelActiveTab === 1 && (
                <li>
                  <span className="span--bold">
                    {round(summary.length, 2)} m
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListPanel;