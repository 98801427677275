// Imports
import { getDomain } from "api/common";

// INIT
//
// Initial call to get all data that needs to be
// represented uppon login or page refresh

// Init call
export const getInit = (domain_id) => {
  // Get the domain details after we got the domain_id
  getDomain(domain_id);
};
