// Imports
import { useSnapshot } from "valtio";
import { Account, FilterPanel, SearchBar } from "@partials";
import { StoreApp } from "@store-common";

export const NavbarRight = () => {
  const { services, activeService } = useSnapshot(StoreApp);

  return (
    <div className="navbar-right">
      {services[activeService].search.enabled && (<SearchBar />)}
      {services[activeService].filter.enabled && (<FilterPanel />)}
      <Account />
    </div>
  );
};
