import { useEffect, useState } from "react";

export const useDynamicHeightCalculation = (recalculate) => {
  const [div1Height, setDiv1Height] = useState(0);
  const [div2Height, setDiv2Height] = useState(0);
  const [result, setResult] = useState(0);

  useEffect(() => {
    // Function to update div heights
    const updateDivHeights = () => {
      const div1 = document.querySelector("aside .panel .panel__header");
      const div2 = document.querySelector(
        "aside .panel .panel__content-geo__sum"
      );

      if (div1) setDiv1Height(div1.clientHeight);
      if (div2) setDiv2Height(div2.clientHeight);
    };

    // Initial call to update heights
    updateDivHeights();

    // Event listener for window resize
    window.addEventListener("resize", updateDivHeights);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", updateDivHeights);
    };
  }, [recalculate]);
  useEffect(() => {
    const viewHeight = window.innerHeight;
    const newResult = viewHeight - div1Height - div2Height - 5.5 * 16; // 5.5rem converted to pixels (16px per rem)
    setResult(newResult);
  }, [div1Height, div2Height, recalculate]);

  return result;
};
