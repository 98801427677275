// Imports
import { Done, Substract } from "@icons";

export const Checkbox = ({state}) => {
  return (
    <div
      className="checkbox"
      aria-checked={state}
      role="checkbox"
    >
      {state === "true" && <Done/>}
      {state === "mixed" && <Substract/>}
    </div>
  );
};
