// Imports
import { setProductsGeoStore } from "@store-geostore";


// Sending a request with selected position
export const getGeoStoreProducts = async () => {
  await fetch(`https://graphql.contentful.com/content/v1/spaces/2bhkoex608qi/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`,
    },
    body: JSON.stringify({ query }),
  })
    .then((response) => response.json())
    .then((response) => {
      setProductsGeoStore(response.data.servicesCardCollection.items);
    })
    .catch((error) => console.log(error));
};


const query = `{
  servicesCardCollection {
    items {
      order
      image {
        url
        title
      }
      title
      shortDescription
      unit
      unitPrice
      instantPurchase
      comment
      filters
      slug
      instantPurchase
      format
      resolution
      demoUrl
    }
  }
}`;