import { useState } from "react";
import { CheckboxDone, Information } from "@icons";
import { setPanelInfoActive, setPanelInfoActiveSlug } from "@store-common";

export const CheckboxGEO = ({ onChange, data }) => {
  const { id, title, checkbox, slug, resolution, format } = data;
  const [isChecked, setIsChecked] = useState(checkbox);

  const handleChange = () => {
    const updatedCheckbox = !isChecked;
    setIsChecked(updatedCheckbox);
    if (onChange) {
      onChange(id, updatedCheckbox);
    }
  };

  const toggleInfo = (e, slug) => {
    e.stopPropagation();
    setPanelInfoActiveSlug(slug);
    setPanelInfoActive(true);
  };

  return (
    <div
      className={`checkbox__geo${isChecked ? " checked" : ""}`}
      onClick={handleChange}
    >
      <div
        className="checkbox__geo-checkbox"
        aria-checked={isChecked}
        role="checkbox"
      >
        {isChecked && <CheckboxDone color={"green"} />}
      </div>
      <div className="checkbox__geo-text">
        <div>
          <h5>{title}</h5>
          <div className="checkbox__geo-text__details">
            <div className="checkbox__geo-text__details-formats">
              {format.map((item, i) => (
                <span key={i}>
                  {item} {format.length !== i + 1 && "+ "}
                </span>
              ))}
            </div>
            <span className="checkbox__geo-text__details-circle"></span>
            <span>{resolution}</span>
          </div>
        </div>
        <div className="checkbox__geo-text__info">
          <button onClick={(e) => toggleInfo(e, slug)}>
            <Information />
          </button>
        </div>
      </div>
    </div>
  );
};
