// Imports
import { useRef } from "react";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import { useFrame } from "@react-three/fiber";
import { StoreModel } from "@service-roof";

// POINTER
export const Pointer = ({ model, camera, control }) => {
  const { ready } = useSnapshot(StoreModel.model);
  const { poi, n, pointerScaleFactor, snapMargin, visible, panelHovered } = useSnapshot(StoreModel.pointer);
  const { points } = useSnapshot(StoreModel.ruler);

  const raycaster = new THREE.Raycaster();

  function snapCursor(pos) {
    StoreModel.pointer.poi.copy(pos);
    StoreModel.pointer.snapped = true;
  }

  function checkSnapCursor(faceIndex, obj) {
    let tp = [ new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3() ];
    for (let i = 0; i < tp.length; i++) {
      tp[i].fromBufferAttribute(obj.geometry.attributes.position, faceIndex * 3 + i);
    }

    if (poi.distanceTo(tp[0]) < snapMargin) {
      snapCursor(tp[0]);
    } else if (poi.distanceTo(tp[1]) < snapMargin) {
      snapCursor(tp[1]);
    } else if (poi.distanceTo(tp[2]) < snapMargin) {
      snapCursor(tp[2]);
    } else if (points.length >= 3 && poi.distanceTo(points[0]) < snapMargin) {
      snapCursor(points[0]);
    } else {
      StoreModel.pointer.snapped = null;
    }
  }

  function handleIntersection(intersection, object) {
    if (object) {
      // Get pointed poiLayer
      StoreModel.pointer.poiLayer = object.geometry.userData.layer;

      // Get pointed poiType
      StoreModel.pointer.poiType = object.geometry.userData.type;

      // Get pointed sideIndex
      if (object.geometry.userData.type !== "edges") {
        const feature = StoreModel.model[object.geometry.userData.type].filter(e => e.properties.LAYER === object.geometry.userData.layer);
        feature.forEach((f) => {
          if (f.faceIndexes.includes(intersection.faceIndex)) {
            StoreModel.pointer.poiUID = f.uID;
          }
        });
      } else {
        StoreModel.pointer.poiUID = object.geometry.userData.uID;
      }

      // Get pointer faceIndex
      StoreModel.pointer.poiFaceIndex = intersection.faceIndex;

      // Position the pointer
      StoreModel.pointer.poi.copy(intersection.point);

      // Check snapping if current tool is ruler
      if (StoreModel.tool.currentTool === 1) {
        checkSnapCursor(intersection.faceIndex, object);
      }

      // Rotate the pointer
      StoreModel.pointer.n.copy(intersection.face.normal);
      StoreModel.pointer.n.transformDirection(object.matrixWorld);
      StoreModel.pointer.la.copy(poi).add(n);
    }
  }

  useFrame(({ mouse }) => {
    raycaster.setFromCamera(mouse, camera.current);

    if (ready) {
      const intersects = raycaster.intersectObject(model.current);

      // Scale cursor
      StoreModel.pointer.pointerScale = control.current.getDistance() * pointerScaleFactor;

      // Check intersection and update pointer
      if (intersects.length > 0 && visible && !panelHovered) {
        const intersection = intersects[0];
        const object = intersection.object;

        handleIntersection(intersection, object);
      }
    }
  });

  return (
    <mesh>
      <Point />
    </mesh>
  );
};

const Point = () => {
  const { poi, la, visible, snapped, pointerScale, poiType } = useSnapshot(StoreModel.pointer);
  const { currentView } = useSnapshot(StoreModel.view);

  const pointRef = useRef();

  useFrame(() => {
    pointRef.current.position.copy(poi);
    pointRef.current.lookAt(la);
    pointRef.current.visible = visible;
  });

  return (
    <mesh ref={pointRef}>
      <mesh visible={poiType !== "edges"}>
        <torusGeometry
          attach="geometry"
          args={[
            snapped ? pointerScale * 0.25 : pointerScale * 0.2,
            pointerScale * 0.01,
            10,
            60,
          ]}
        />
        <meshBasicMaterial
          attach="material"
          color={snapped ? "#00FF7F" : currentView === 2 ? "#A1A1AA" : "#FFF"}
        />
      </mesh>
      <mesh>
        <sphereGeometry
          attach="geometry"
          args={[pointerScale * 0.06, 24, 16]}
        />
        <meshBasicMaterial attach="material" color={"#00FF7F"} />
      </mesh>
    </mesh>
  );
};