import { StoreApp, StoreDomain } from "@store-common";
import { useSnapshot } from "valtio";

export const Legal = () => {
  const { activeService, services } = useSnapshot(StoreApp);
  const { parent_id } = useSnapshot(StoreDomain);

  return (
    <div className="footer__legal">
      <span>© Envirosense Hungary Kft.</span>
      <hr/>
      <a
        href={services[activeService].terms}
        target="_blank"
        rel="noreferrer"
      >
        ÁSZF
      </a>
      {(services[activeService].id === 1 && parent_id === "db75f7a4-5ae5-48f1-b534-e86b08b91305") && (
        <>
          <hr/>
          <a
            href={`https://teto.envimap.hu/ENVIROSENSE_HUNGARY_Kft-ESZF-ENVIMAP-Teto_TerranPartner.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            ESZF
          </a>
        </>
      )}
    </div>
  );
};
