import { useState } from "react";
import { Close } from "@icons";
import { Accordion } from "@molecules";
import { Discounts } from "@store-geostore";
import { ModalLayout, useModalContext } from "..";

export const ModalDiscount = () => {
  const { setIsModalOpen } = useModalContext();
  const [expanded, setExpanded] = useState(0);

  const toggleAccordion = (i) => {
    setExpanded(i);
  };

  return (
    <ModalLayout>
      <div className="modal-content__header">
        <h2>Kedvezmények</h2>
        <button onClick={() => setIsModalOpen(false)}>
          <Close />
        </button>
      </div>
      <div className="modal-content__body">
        <p>Csökkentse kiadásait az EnviMAP álltal kínált kedvezményekkel</p>
        <div className="modal-content__body body-accordion">
          {Discounts.map((item, i) => (
            <Accordion
              key={i}
              item={item}
              i={i}
              expanded={expanded}
              toggleAccordion={toggleAccordion}
            />
          ))}
        </div>
      </div>
      <div className="modal-content__footer discount">
        <p>
          A kedvezméynek nem hallmozhatóak. Több kedvezmény esetén mindig a
          nagyobb kedvezmény kerül levonásra. A feltűntetett kedvezményekben
          történő változtatások jogát fenntartjuk.
        </p>
      </div>
    </ModalLayout>
  );
};
