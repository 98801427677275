// Imports
import { PDFLogoTerran } from "@icons";

// Hardcoded Superdomain specific data
export const SuperDomainData = [
  {
    // Terrán
    domain_id: "db75f7a4-5ae5-48f1-b534-e86b08b91305",
    pdfLogo: <PDFLogoTerran />,
    marketingDesc: `Ön a magyar tulajdonú Envirosense Hungary Kft. és a szintén hazai, több mint száz éves Terrán Tetőcserép Gyártó Kft. együttműködésével fejlesztett EnviMAP© Tető szolgáltatás által biztosított tetőadatokat használja.
      Az EnviMAP© Tető olyan innovatív, egyedi fejlesztés, amely jelenleg felöleli az ország 2100 településének épület-geometriai adatállományát. Különleges légi lézerszkenner segítségével, LOD2 részletességű tetősíkokat modellez. Az Envirosense térinformatikusainak és a Terrán mérnökeinek összefogásával készített rendszerből így egyedülálló, részletes tetőgeometriai méretinformációk állnak a felhasználóink rendelkezésére.`,
  }
];
