// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Button, SegmentedControl } from "@molecules";
import { StoreNewRequest } from "@store-forest";
import { forestPackages, checkPricing } from "./utils";
import { postNewRequestForest } from "@apis-forest";
import { useToastContext } from "@partials";

export const PanelNewRequestForest = ({setAside}) => {
  const { newRequestData } = useSnapshot(StoreNewRequest);
  const addToast = useToastContext();
  const baseClass = "panel__content-box__request-forest";
  const [formData, setFormData] = useState({
    selectedPackage: 0,
    totalPrice: 0,
  });

  // Add features to cluster source
  useEffect(() => {
    setFormData((prevData) => {
      return {
        ...prevData,
        totalPrice: checkPricing(formData.selectedPackage, newRequestData.request_data.total_area),
      };
    });
  }, [newRequestData]);

  // Handle input change
  const handleInput = (e) => {
    const { value } = e.target;

    setFormData((prevData) => {
      return {
        ...prevData,
        selectedPackage: value,
        totalPrice: checkPricing(value, newRequestData.request_data.total_area),
      };
    });
  };

  // Handle submit
  const handleSubmit = (e) => {
    // Prevent form to default function
    e.preventDefault();

    const request_data = {
      ...newRequestData.request_data,
      package: formData.selectedPackage,
    };
    
    // Creating request body
    let body = {
      ...newRequestData,
      request_data: JSON.stringify(request_data),
      resolved_addr:JSON.stringify(newRequestData.resolved_addr),
    };
    // Call endpoint
    postNewRequestForest(body, addToast, setAside);
  };

  return (
    <div className={baseClass}>
      <form onSubmit={handleSubmit}>
        <div className={`${baseClass}__top`}>
          <div className={`${baseClass}__switch`}>
            <div className={`${baseClass}__head`}>
              <p>Kérjük válasszon csomagot!</p>
            </div>
            <SegmentedControl
              segments={forestPackages}
              name={"requestPlan"}
              onChange={handleInput}
              value={formData.selectedPackage}
              forest
            />
          </div>
          <div className={`${baseClass}__checkbox`}>
            <div className={`${baseClass}__checkbox-head`}>
              <p>A csomag tartalma:</p>
            </div>
            <div>
              {forestPackages[formData.selectedPackage].items.map((item, index) => {
                return (
                  <div className={`${baseClass}__items`} key={index}>
                    <img src={item.img} />
                    <div>
                      <div className={`${baseClass}__items-title`}>
                        <h4> {item.name} </h4>
                        <span>{item.type}</span>
                      </div>
                      <span> {item.description} </span>{" "}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={`${baseClass}__bottom`}>
          <hr />
          <div className={`${baseClass}__total`}>
            <span>Fizetendő</span>
            <span>{formData.totalPrice.toLocaleString('hu-HU')} Ft*</span>
          </div>
          <div className={`${baseClass}__button`}>
            <Button
              iconPosition="after"
              text="Adatok igénylése"
              type="submit"
              value="submit"
            />
          </div>
          <span className={`${baseClass}__miniscule`}>
            *Az itt feltűntetett árak nettó árak, az Áfá-t nem tartalmazzák.
            A kijelölt területek minimum 1 hektáras mérettel kerülnek felszámításra.
          </span>
        </div>
      </form>
    </div>
  );
};
