// Imports
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import { StoreModel } from "@service-roof";
import { setCanvasImage } from "@store-roof";
import { Edges, Plane } from "@react-three/drei";

// BASE PLANE
const BasePlane = () => {
  const { loaded, bounds, basePlaneMargin } = useSnapshot(StoreModel.model);
  const { currentView, ortomapChangeTrack, ortoAvailable } = useSnapshot(
    StoreModel.view
  );

  const basePlaneRef = useRef();
  const textureRef = useRef();
  const mapCanvasRef = useRef(document.createElement("canvas"));
  const [canvas, setCanvas] = useState(null);
  const [canvasSize, setCanvasSize] = useState(0);

  useEffect(() => {
    if (!bounds || !basePlaneMargin) return;
    setCanvasSize(bounds.max + basePlaneMargin * 2);
  }, [bounds, basePlaneMargin]);

  useEffect(() => {
    if (ortomapChangeTrack === 0) return;

    if (!ortoAvailable) {
      setCanvasImage(null);
      return;
    }

    // Defaults
    const size = [1500, 1500];
    const projectionZoomFactor = 150 / canvasSize;

    // Scale canvas
    mapCanvasRef.current.width = size[0];
    mapCanvasRef.current.height = size[1];

    // Get context
    const mapContext = mapCanvasRef.current.getContext("2d");

    // Copy map canvas to texture canvas
    Array.prototype.forEach.call(
      document.querySelectorAll("#ortomap .ol-layer canvas"),
      function (canvas) {
        if (canvas.width > 0) {
          mapContext.drawImage(
            canvas,
            size[0] * -(projectionZoomFactor / 2 - 0.5),
            size[1] * -(projectionZoomFactor / 2 - 0.5),
            size[0] * projectionZoomFactor,
            size[1] * projectionZoomFactor
          );
        }
      }
    );

    // Set Canvas
    setCanvas(mapCanvasRef.current);
    // Set Canvas Image
    setCanvasImage(mapCanvasRef.current.toDataURL());
  }, [ortomapChangeTrack, ortoAvailable, canvasSize]);

  if (loaded && ortomapChangeTrack !== 0 && ortoAvailable) {
    return (
      <Plane
        visible={currentView === 1}
        ref={basePlaneRef}
        args={[canvasSize, canvasSize]}
        rotation={[Math.PI / -2, 0, 0]}
        position={[bounds.xMid, -0.01, bounds.zMid]}
      >
        <meshBasicMaterial side={THREE.DoubleSide}>
          <canvasTexture
            ref={textureRef}
            attach="map"
            image={canvas}
            needsUpdate={currentView === 1}
          />
        </meshBasicMaterial>
        <Edges color={"#EEEEEE"} />
      </Plane>
    );
  }
};

export default BasePlane;
