// Imports
import React, { useState } from "react";
import { Checkbox } from "@molecules";
import { transformLayerName } from "@utils-roof";
import { StoreModel } from "../StoreModel";

// LIST PANEL ITEM
export const ListPanelItem = React.memo(function ListPanelItem({
  item,
  itemTypes,
  listPanelActiveTab,
  isHovered,
  isSelected,
  handleSelectionChange,
}){
  const [itemHovered, setItemHovered] = useState(false);

  const handleHover = () => {
    setItemHovered(true);
    StoreModel.pointer.highlightVisible = true;
    StoreModel.pointer.poiUID = item.uID;
    StoreModel.pointer.poiType = itemTypes[listPanelActiveTab];
  };

  const handleLeave = () => {
    setItemHovered(false);
    StoreModel.pointer.highlightVisible = false;
    StoreModel.pointer.poiUID = null;
    StoreModel.pointer.poiType = null;
  };

  return (
    <button
      className={`listpanelitem ${
        itemHovered || isHovered ? "hovered" : ""
      }`}
      onClick={() => handleSelectionChange(item.uID)}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <Checkbox
        state={isSelected ? "true" : "false"}
      />
      <h5>
        {transformLayerName(item.properties.LAYER)} #{item.tID}
      </h5>
      <ul className="meta">
        {item.properties.area_3d && (
          <li>
            <span>{item.properties.area_3d} m²</span>
          </li>
        )}
        {item.properties.slope && (
          <li>
            <span>{item.properties.slope}°</span>
          </li>
        )}
        {item.properties.length_3d && (
          <li>
            <span>{item.properties.length_3d} m</span>
          </li>
        )}
      </ul>
    </button>
  );
});