// Create list for map filter
export const createFilterData = (requests) => {
  const filterData = {
    dates: [
      {
        name: "Bármikor",
        reqIDs: []
      },
      {
        name: "Elmúlt 10 napban igényelt",
        reqIDs: []
      },
      {
        name: "Elmúlt 30 napban igényelt",
        reqIDs: []
      }
    ],
    domains: [],
    counties: [],
  };

  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  requests.forEach((req) => {

    // Group request by Date
    const createdAt = new Date(req.created_at);
    const requestId = req.request_id;

    if (createdAt) {
      filterData.dates[0].reqIDs.push(requestId);
    }
    if (createdAt >= tenDaysAgo) {
      filterData.dates[1].reqIDs.push(requestId);
    }
    if (createdAt >= thirtyDaysAgo) {
      filterData.dates[2].reqIDs.push(requestId);
    }

    // Group request by domain
    const domain = filterData.domains.find((d) => d.uID === req.domain_id);

    if (!domain) {
      filterData.domains.push({
        name: req.domain_name,
        uID: req.domain_id,
        reqIDs: [requestId],
      });
    } else {
      domain.reqIDs.push(requestId);
    }

    // Group request by counties
    const county = filterData.counties.find((c) => c.name === req.address.county);

    if (!county) {
      filterData.counties.push({
        name: req.address.county,
        reqIDs: [requestId],
      });
    } else {
      county.reqIDs.push(requestId);
    }
  });

  // Sort filter items
  filterData.domains.sort((a, b) => b.reqIDs.length - a.reqIDs.length);
  filterData.counties.sort((a, b) => b.reqIDs.length - a.reqIDs.length);

  return filterData;
};

export default createFilterData;