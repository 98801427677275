// Imports
import { proxy } from "valtio";

// STORE
export const StoreNewRequest = proxy({
  buildingType: 0,
  buildingFeature: 0,
});

// Set selected building type
export const setSelectedType = (index) => {
  StoreNewRequest.buildingType = index;
};

// Set selected feature to be requested
export const setSelectedFeature = (index) => {
  StoreNewRequest.buildingFeature = index;
};
