export const PurchasingProcess = ({ step, i }) => {
  const { title, desc } = step;
  return (
    <div className="process">
      <div className="process__index">
        <span>{i + 1}</span>
      </div>
      <div className="process__content">
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
    </div>
  );
};
