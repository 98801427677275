// Imports
import {
  NavbarLeft,
  NavbarRight
} from "@partials";


export const Navbar = () => {
  return (
    <div className="navbar">
      <NavbarLeft />
      <NavbarRight />
    </div>
  );
};
