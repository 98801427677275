import { vatValidator } from "@apis-common";
import { capitalizeFirstLetterOfWords } from "@utils-common";

const updateFormData = (prevData, fieldName, value) => {
  return prevData.map((field) =>
    field.name === fieldName
      ? {
          ...field,
          value: value,
        }
      : field
  );
};

export const autoCompleteField = async (
  e,
  completeType = "completeByVAT",
  setFormData
) => {
  if (e.target.value !== "") {
    let companiesList = [];

    if (completeType === "completeByVAT" && e.target.value.length === 13) {
      companiesList = await vatValidator(e.target.value, "vatnum");
    } else if (completeType === "completeByName") {
      companiesList = await vatValidator(e.target.value, "name");
    } else {
      console.warn("Bad complete type was added");
      return;
    }

    if (companiesList.length > 0) {
      const companyData = companiesList[0];
      const fieldName =
        completeType === "completeByVAT" ? "name" : "vatnum";
      let value = "";

      if (companyData.type === "companies") {
        value =
          completeType === "completeByVAT"
            ? companyData.short_name[0].name
            : companyData.tax_number[0].tax_number.toString();
      } else if (companyData.type === "self_employed") {
        value =
          completeType === "completeByVAT"
            ? `${capitalizeFirstLetterOfWords(companyData.name)} E.V.`
            : companyData.tax_number.toString();
      }

      setFormData((prevData) => updateFormData(prevData, fieldName, value));
    } else {
      console.warn("No company was founded");
    }
  } else {
    console.warn("Empty input");
  }
};
