// Imports
import PinAvailable from "@images-common/pins/PinAvailableDefaultForest.svg";
import PinProcessing from "@images-common/pins/PinProcessingDefault.svg";
import PinSaved from "@images-common/pins/PinSavedDefaultForest.svg";
import { Icon, Style } from "ol/style";

// Icon type variable
let iconType;

// Pin constructor
const pinConstructor = (src) =>
  new Style({
    image: new Icon({
      anchor: [0.5, 1],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      src: src,
    }),
  });

// Icon type switcher //
// This function return an icon style based on passed argument.
// The possible arguments that can be used to call the function:
// select (def)| available | availableSel | denied | deniedSel
// processing | processingSel

export const PinStyle = (type = "select", selected = false) => {
  switch (true) {
    case type === "Available" && selected === false:
      iconType = pinConstructor(PinAvailable);
      break;
    case type === "Pending" && selected === false:
      iconType = pinConstructor(PinProcessing);
      break;
    case (type === "Archived" || type === "Saved") && selected === false:
      iconType = pinConstructor(PinSaved);
      break;
    default:
      break;
  }
  return iconType;
};
