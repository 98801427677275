// Imports
import { Footer, Maptools } from "@partials";

export const MainBottom = ({ map, setKey }) => {
  return (
    <>
      <div className="main-bottom">
        <Maptools map={map} setKey={setKey} />
        <Footer map={map} />
      </div>
    </>
  );
};
