// Imports
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { transformLayerName } from "./utils";

// Export data to CSV
export const exportDataToCSV = (panelData, request_id) => {
  const layerName = "Megnevezés";
  const area = "Terület (m²)";
  const slope = "Dőlésszög (°)";
  const length = "Hossz (m)";

  const CSVData = [
    [layerName, area, slope, length],
    ...panelData[0].items.map((elem) => [
      `${transformLayerName(elem.properties.LAYER)} #${elem.tID}`,
      elem.properties.area_3d,
      elem.properties.slope,
      "",
    ]),
    ...panelData[1].items.map((elem) => [
      `${transformLayerName(elem.properties.LAYER)} #${elem.tID}`,
      "",
      "",
      elem.properties.length_3d,
    ]),
  ];

  const csvOptions = {
    filename: `TET-${request_id}`,
    showLabels: true,
    useTextFile: false,
    useBom: true,
  };

  const csvExporter = new ExportToCsv(csvOptions);
  return csvExporter.generateCsv(CSVData);
};
