import { addDaysToDate } from "@utils-common";

const calculateTotalPrice = (data, multiplier) => {
  const totalPrice = data
    .filter((item) => item.checkbox && item.instantPurchase)
    .reduce((acc, item) => acc + item.price * multiplier, 0);
  return Math.round(totalPrice);
};

const mappingSelectedLayers = (panelData) => {
  const checkedPanelData = panelData.filter((item) => item.checkbox);
  return checkedPanelData;
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const createRequestObject = (
  data,
  panelInfo,
  totalAmount,
  panelInfoDiscount,
  requestComment
) => {
  const { totalArea, polygons } = panelInfo;
  const selectedLayers = data.map((layer) => {
    return {
      title: layer.title,
      price: layer.price,
      instantPurchase: layer.instantPurchase,
    };
  });

  const instantPurchase = checkPanelDataInstantPurchase(
    mappingSelectedLayers(data)
  );

  const price = panelInfoDiscount
    ? totalAmount - panelInfoDiscount.discount
    : totalAmount;

  return {
    totalAreaNumbers: polygons.length,
    totalArea: panelInfo.total_area,
    totalPolygons: totalArea,
    totalPrice: price,
    selectedLayers: selectedLayers,
    polygons: polygons,
    available_from: "2023-04-14 20:42:09.100904",
    available_till: addDaysToDate(getCurrentDate(), 21),
    survey_date: getCurrentDate(),
    instantPurchase: !instantPurchase,
    requestComment: requestComment,
  };
};

const mapProductsData = (productsData) => {
  const mappedProdcutsData = productsData.map((item) => ({
    checkbox: false,
    id: item.order,
    img: item.image.url,
    title: item.title,
    tags: item.filters,
    description: item.shortDescription,
    price: item.unitPrice,
    unit: item.unit,
    slug: item.slug,
    instantPurchase: item.instantPurchase,
    format: item.format,
    resolution: item.resolution,
  }));
  return mappedProdcutsData;
};

const checkPanelDataInstantPurchase = (panelData) =>
  panelData.some((product) => !product.instantPurchase);

export {
  calculateTotalPrice,
  mappingSelectedLayers,
  createRequestObject,
  mapProductsData,
  checkPanelDataInstantPurchase,
};
