// Imports
import { cookieGetToken, urls } from "api/common";
import { setActiveRequestPCD } from "@store-roof";

// Check error
const CheckError = (response) => {
  if (response.status === 200) {
    return response.blob();
  } else {
    throw Error(response.statusText);
  }
};

// Get PCD File
export const getPCDFile = async (request_id, message) => {
  await fetch(urls.getFile + request_id + "?extention=pcd", {
    method: "GET",
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    // Return response data as blob and checking errors
    .then((response) => CheckError(response, message))
    .then((bytes) => {
      // Create Object URL
      const pcdObj = URL.createObjectURL(bytes);
      // Set PCD
      setActiveRequestPCD(pcdObj);
    })
    .catch((error) => console.log(error));
};
