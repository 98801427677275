// Imports
import { cookieGetToken, urls } from "api/common";

// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    return response.blob();
  } else {
    // Send error message to user
    message(["error", "Probléma a csomag letöltése közben."]);
    throw Error(response.statusText);
  }
};

// Login
export const getZipFile = async (request_id, message) => {
  await fetch(urls.getFile + request_id + "/zip", {
    method: "GET",
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    // Return response data as blob and checking errors
    .then((response) => CheckError(response, message))
    .then((bytes) => {
      // Create file name
      const fileName = `ENV-${request_id}`;

      // Create link element in DOM
      let elm = document.createElement("a");

      // Set link element content
      elm.href = URL.createObjectURL(bytes);

      // Set download attribute and filename param
      elm.setAttribute("download", fileName);

      // Trigger element to download zipped file
      elm.click();
    })
    .catch((error) => console.log(error));
};
