// Imports
import { Brand, ServiceSwitcher } from "@partials";

export const NavbarLeft = () => {
  return (
    <div className="navbar-left">
      <Brand />
      <ServiceSwitcher />
    </div>
  );
};