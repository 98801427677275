import { useSnapshot } from "valtio";
import { StoreModel } from "@service-roof";

export const Aside = ({ children }) => {
  const { fullScreen } = useSnapshot(StoreModel.view);
  return (
    <aside className={`aside${fullScreen ? " fullscreen" : ""}`}>
      {children}
    </aside>
  );
};
