// Imports
import { useEffect, useState } from "react";
import { resetTool } from "../StoreModel";
import { Done } from "@icons";
import { transformLayerName } from "@utils-roof";

// Filter panel
export const ListPanelGroupSelect = ({
  typesArray,
  selectedItems,
  panelItems,
  handleSelectionChange,
}) => {
  const [selectedType, setSelectedType] = useState(null);

  // Handle single type selection
  const handleChange = (e) => {
    if (selectedType === e.target.name) {
      resetTool(0);
      setSelectedType(null);
      return;
    }

    const matchingItems = panelItems.filter(item => item.properties.LAYER === e.target.name);
  
    if (matchingItems.length) {
      resetTool(0);
      matchingItems.forEach(item => handleSelectionChange(item.uID));
      setSelectedType(e.target.name);
    }
  };

  // Handle Selection changes
  useEffect(() => {
    if (selectedItems.length > 0) {
      const matchingItems = panelItems.filter(panelItem =>
        selectedItems.some(selectedItem => selectedItem.uID === panelItem.uID)
      );
  
      const hasSameLayer = matchingItems.every(item =>
        item.properties.LAYER === matchingItems[0].properties.LAYER
      );
  
      if (hasSameLayer) {
        const scopedType = selectedType || matchingItems[0]?.properties.LAYER;
        const countMatch = matchingItems.length === panelItems.filter(item =>
          item.properties.LAYER === scopedType
        ).length;
        setSelectedType(countMatch ? scopedType : null);
      } else {
        setSelectedType(null);
      }
    } else {
      setSelectedType(null);
    }
  }, [selectedItems, panelItems]);

  return (
    <>
      {typesArray.map((type, index) => (
        <ListPanelFilterSelectCheckbox
          key={index}
          name={type}
          label={transformLayerName(type)}
          checked={type === selectedType}
          handleChange={handleChange}
        />
      ))}
    </>
  );
};

// Filter panel checkbox
const ListPanelFilterSelectCheckbox = ({
  name,
  label,
  handleChange,
  checked,
}) => {
  return (
    <div className="checkbox-container">
      <label>
        <input
          name={name}
          type="checkbox"
          checked={checked}
          onChange={(e) => handleChange(e)}
        />
        <span className="checkbox" aria-hidden="true">
          {checked && <Done />}
        </span>
        <span className={`checkbox-label ${checked ? "active" : ""}`}>
          Összes {label}
        </span>
      </label>
    </div>
  );
};
