// Imports
import { cookieGetToken, urls } from "api/common";
import { CheckError } from "utils/common";

// Login
export const patchRequest = async (body, message) => {
  await fetch(urls.getRequests + body.id, {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      lat: body.lat,
      lon: body.lon,
      building_type: body.building_type,
      building_features: 0,
      status: body.status,
      comment: body.comment,
      resolved_addr: body.resolved_addr,
      request_data: body.request_data,
    }),
  })
    .then((response) =>
      CheckError(
        response,
        message,
        false,
        "Probléma lépett fel az adatok leadásakor."
      )
    )
    .then((actualData) => {
      console.log(actualData);
    })
    .catch((error) => console.log(error));
};
