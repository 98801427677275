// Imports
import { setServiceStatus } from "@store-common";


// Sending a request with selected position
export const getServiceStatus = async () => {
  await fetch(`https://graphql.contentful.com/content/v1/spaces/2bhkoex608qi/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`,
    },
    body: JSON.stringify({ query }),
  })
    .then((response) => response.json())
    .then((response) => {
      setServiceStatus(response.data.serviceStatusCollection.items);
    })
    .catch((error) => console.log(error));
};


const query = `{
  serviceStatusCollection {
    items {
      active
      message
    }
  }
}`;