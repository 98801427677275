// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { getZipFile } from "@apis-roof";
import { Help, PinDenied, PinProcessing } from "@icons";
import { Button } from "@molecules";
import { useToastContext } from "@partials";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ModelViewer, ModelViewerEmpty } from "@service-roof";
import { StoreDomain } from "@store-common";
import { StoreRequests } from "@store-roof";
import { formatAddress } from "@utils-common";
import { PDFRoofConverter } from "@utils-roof";
import QRGenerator from "utils/service-roof/useQRCode";

export const PanelPrevRequest = () => {
  const { activeRequestData } = useSnapshot(StoreRequests);
  const [requestDataLoaded, setRequestDataLoaded] = useState(false);

  // Handle loading state
  useEffect(() => {
    if (activeRequestData.request_id !== 0) setRequestDataLoaded(true);
  }, [activeRequestData]);

  return (
    <>
      {!requestDataLoaded ? (
        <p>Adatok betöltés...</p>
      ) : activeRequestData.status === "Available" ? (
        <RequestView data={activeRequestData} />
      ) : (
        <RequestStatus
          status={activeRequestData.status}
          comment={activeRequestData.comment}
          request_id={activeRequestData.request_id}
        />
      )}
    </>
  );
};

const RequestView = ({ data }) => {
  const {
    request_id,
    address,
    created_at,
    user,
    domain,
    building_type,
    coordinates,
    updated_at,
    model_json,
  } = data;
  const { canvasIMG } = useSnapshot(StoreRequests);
  const { is_super_domain, parent_id, domain_id } = useSnapshot(StoreDomain);
  const addToast = useToastContext();

  // Model metadata
  const modelMetaData = [
    {
      name: "10° feletti tetőfelület",
      value: model_json ? model_json.calculations.roof_area : "-",
      unit: " m²",
    },
    {
      name: "Lapostető felület",
      value: model_json ? model_json.calculations.flatroof_area : "-",
      unit: " m²",
    },
    {
      name: "Átlag dőlésszög",
      value: model_json ? model_json.calculations.avg_angle : "-",
      unit: "°",
    },
    {
      name: "Taréjgerinc hossz",
      value: model_json ? model_json.calculations.horizontal_ridge_length : "-",
      unit: " m",
    },
    {
      name: "Élgerinc hossz",
      value: model_json ? model_json.calculations.edge_ridge_length : "-",
      unit: " m",
    },
    {
      name: "Oromszegély hossz",
      value: model_json ? model_json.calculations.gable_length : "-",
      unit: " m",
    },
    {
      name: "Eresz hossz",
      value: model_json ? model_json.calculations.drain_length : "-",
      unit: " m",
    },
    {
      name: "Vápa hossz",
      value: model_json ? model_json.calculations.vapa_length : "-",
      unit: " m",
    },
    {
      name: "Manzárdtöres hossz",
      value: model_json ? model_json.calculations.manzard_length : "-",
      unit: " m",
    },
    {
      name: "Hajlásszögtörés hossz",
      value: model_json ? model_json.calculations.angular_break_length : "-",
      unit: " m",
    },
    {
      name: "Falszegély hossz",
      value: model_json ? model_json.calculations.wall_edge_length : "-",
      unit: " m",
    },
    {
      name: "Egyéb szegély hossz",
      value: model_json ? model_json.calculations.other_edge_length : "-",
      unit: " m",
    },
  ];

  // Format dates
  const formatDate = (unFormatedDate) => {
    let formatedDate = unFormatedDate.slice(0, 16);
    formatedDate = formatedDate.replaceAll("T", " ");
    formatedDate = formatedDate.replaceAll("-", ".");
    return formatedDate;
  };

  // Download zipped project files
  const downloadZIP = () => {
    getZipFile(request_id, addToast);
  };

  return (
    <div className="panel__content-box__viewer">
      <div className="panel__content-box__viewer__reqinfo">
        <ul>
          <li>
            <span>Igénylés ideje</span>
            <span className="span--bold">{formatDate(created_at)}</span>
          </li>
          <li>
            <span>Igényelte</span>
            <span className="span--bold">
              {user
                ? `${user.last_name} ${user.first_name}`
                : "Terrán Partner API"
              }
            </span>
            {!domain.is_super_domain && <span>{domain.domain_name}</span>}
          </li>
          <li>
            <span>Típusa</span>
            <span className="span--bold">{building_type}</span>
          </li>
        </ul>
      </div>

      {model_json ? <ModelViewer /> : <ModelViewerEmpty />}

      {model_json && (
        <>
          <div className="panel__content-box__viewer__meta">
            <ul>
              {modelMetaData.map((item, index) => {
                if (!item.value || item.value === 0 || item.value === "0.00")
                  return null;
                return (
                  <li key={index}>
                    <span>{item.name}</span>
                    <span className="span--bold">
                      {item.value}
                      {item.unit}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="panel__content-box__viewer__qrcode">
            <QRGenerator
              value={`https://map.envimap.hu/teto?tet=${request_id}`}
            />
          </div>
          <div className="panel__content-box__viewer__actions">
            <Button text="Fájlok letöltése" action={downloadZIP} />

            {canvasIMG !== null && (
              <PDFDownloadLink
                className="pdf__download"
                document={
                  <PDFRoofConverter
                    id={request_id}
                    address={formatAddress(address)}
                    coordinates={coordinates}
                    modelMetaData={modelMetaData}
                    canvasIMG={canvasIMG}
                    superdomain={is_super_domain ? domain_id : parent_id}
                  />
                }
                fileName={`TET-${request_id}.pdf`}
              >
                {() => <Button text={"Adatlap nyomtatása"} secondary />}
              </PDFDownloadLink>
            )}
          </div>
        </>
      )}

      <div className="panel__content-box__viewer__footer">
        <div className="panel__content-box__viewer__footer__main">
          <a
            className="panel__content-box__viewer__footer__main__report"
            href={`mailto:info@teto.envimap.hu?subject=Probléma%20jelzése:%20TET-${request_id}`}
          >
            <Help />
            <span className="span--bold">Probléma jelzése</span>
          </a>
          <div className="panel__content-box__viewer__footer__main__updated">
            <span>Adatok frissítve:</span>
            <span className="span--bold">{formatDate(updated_at)}</span>
          </div>
        </div>
        <span className="span--minuscule">
          Az adatok tájékoztató jellegűek, az esetleges pontatlanságokból eredő
          károkért felelősséget nem vállalunk.
        </span>
      </div>
    </div>
  );
};

const RequestStatus = ({ status, comment, request_id }) => {
  const icons = {
    Pending: <PinProcessing color="black" />,
    Denied: <PinDenied color="white" />,
  };
  const headlines = {
    Pending: "Feldolgozás folyamatban",
    Denied: "Adatigény elutasítva",
  };
  const leads = {
    Pending:
      "A kiválasztott épülethez tartozó tető adatok feldolgozását megkezdtük. Amint elkészültünk, emailben értesítést küldünk.",
    Denied: "Az épülethez tartozó adatok feldolgozása sikertelen volt.",
  };

  return (
    <div className="panel__content-box__status">
      <span className={`panel__content-box__status-icon ${status}`}>
        {icons[status]}
      </span>
      <div className="panel__content-box__status-content">
        <h2>{headlines[status]}</h2>
        <p>{leads[status]}</p>
      </div>
      {comment && status === "Denied" && (
        <div className="panel__content-box__status-content__comment">
          <div className="panel__content-box__status-content__comment-message">
            <p>{comment}</p>
          </div>
          <a
            className="panel__content-box__status-content__comment-contact"
            href={`mailto:info@teto.envimap.hu?subject=TET-${request_id}`}
          >
            Kapcsolatfelvétel
          </a>
        </div>
      )}
    </div>
  );
};
