// Imports
import { useSnapshot } from "valtio";
import { getZipFile, patchRequest } from "@apis-forest";
import { Bin, Download } from "@icons";
import { Button, ButtonClose } from "@molecules";
import { useToastContext } from "@partials";
import { StoreRequests, setPanelBottomActive } from "@store-forest";
import { formatStatus } from "@utils-common";
import { deselectFeatures } from "../map/functions.MapForest";

export const PanelBottom = ({ map }) => {
  const { panelBottomActive, activeRequestData, prevRequests } =
    useSnapshot(StoreRequests);
  const addToast = useToastContext();

  const patchSavedRequest = () => {
  
    const request_id = activeRequestData.request_id.replace(/\D/g, "");
    const selectedRequest = prevRequests.filter(
      (req) => req.request_id === activeRequestData.request_id
    );

    const body = {
      id: request_id,
      lat: 0,
      lon: 0,
      building_type: "Lakóingatlan",
      building_features: 1,
      status: "Pending",
      comment: "",
      resolved_addr: JSON.stringify(selectedRequest[0].address),
      request_data: JSON.stringify(selectedRequest[0].request_data),
    };
    patchRequest(body, addToast);
  };

  const deleteSavedRequest = () => {
    console.log("Clicked");
  };

  // Download zipped project files
  const downloadZIP = () => {
    const request_id = activeRequestData.request_id.replace(/\D/g, "");
    getZipFile(request_id, addToast);
  };

  return (
    <div className={`panel__bottom ${panelBottomActive ? "active" : ""}`}>
      <div className="panel__bottom-header">
        <div>
          <div>
            <h4>{activeRequestData.request_id}</h4>
            <div className="panel__bottom-header__meta">
              <span>{activeRequestData.polygons} terület - </span>
              <span>{activeRequestData.area} ha</span>
            </div>
            <div className="panel__bottom-header__city">
              <span>{activeRequestData.city}</span>
            </div>
          </div>
        </div>
        <ButtonClose
          action={() => {
            setPanelBottomActive(false);
            // Remove selected feature
            deselectFeatures(map);
          }}
        />
      </div>
      <hr />
      <div className="panel__bottom-body">
        <div className="panel__bottom-body-data">
          <div className="panel__bottom-body-data-col">
            <span>Állapot</span>
            <span className="span--bold">
              <span
                className={`status-circle ${activeRequestData.status}`}
              ></span>{" "}
              {formatStatus(activeRequestData.status)}
            </span>
          </div>
          {/*
          <div className="panel__bottom-body-data-col">
            <span>
              {(activeRequestData.status === "Available" ||
                activeRequestData.status === "Archived") &&
                "Elérhető"}
              {activeRequestData.status === "Pending" && "Várható teljesítés"}
              {activeRequestData.status === "Saved" && "Hozzáadva"}
            </span>
            <span className="span--bold">
              {activeRequestData.deadline}
              {(activeRequestData.status === "Available" ||
                activeRequestData.status === "Archived") &&
                "-ig"}
            </span>
          </div>
          */}
          <div className="panel__bottom-body-data-col">
            <span>Csomag</span>
            <span className="span--bold">
              {activeRequestData.plan}
            </span>
          </div>
          <div className="panel__bottom-body-data-col">
            <span>Igényelte</span>
            <span className="span--bold">
              {activeRequestData.user.first_name +
                " " +
                activeRequestData.user.last_name}
            </span>
          </div>
        </div>

        {activeRequestData.status === "Available" && (
          <Button
            iconPosition="after"
            icon={<Download />}
            text="Csomag letöltése"
            type="button"
            value="download"
            action={downloadZIP}
          />
        )}
        {activeRequestData.status === "Pending" && (
          <form action="mailto:youraddr@domain.tld">
            <Button secondary text="Kapcsolatfelvétel" type="submit" />
          </form>
        )}
        {activeRequestData.status === "Archived" && (
          <Button
            iconPosition="after"
            icon={<Download />}
            text="Újraigénylés"
            type="button"
            value="download"
          />
        )}
        {activeRequestData.status === "Saved" && (
          <div className="saved-request">
            <button onClick={deleteSavedRequest}>
              <Bin />
            </button>
            <Button
              iconPosition="after"
              icon={<Download />}
              text="Adatok igénylése"
              type="button"
              action={patchSavedRequest}
            />
          </div>
        )}
      </div>
    </div>
  );
};
