import { ArrowRight, Done } from "@icons";
import { Button } from "@molecules";
import { ModalLayout, useModalContext } from "..";

export const ModalRegistrationSuccess = () => {
  const { setModalType } = useModalContext();
  return (
    <ModalLayout>
      <div className="modal-content__body center">
        <div className="modal-content__body-icon">
          <span>
            <Done color="green" />
          </span>
        </div>
        <div className="modal-content__body-text">
          <h2>Sikeres regisztráció</h2>
          <p>
            EnviMAP fiókja hamarosan aktiválásra kerül, melyről újabb értesítést küldünk. Az aktiválás után fiókjába belépve tudja megrendeléseit leadni.
          </p>
        </div>
        <div className="modal-content__body-form">
          <Button
            iconPosition="after"
            icon={<ArrowRight />}
            text="Bejelentkezés"
            type="submit"
            action={() => setModalType("login")}
          />
        </div>
      </div>
    </ModalLayout>
  );
};
