// Imports
import { Coordinates, SurveyDate, Legal, ScaleLineControl } from "@partials";
import { useSnapshot } from "valtio";
import { StoreApp } from "@store-common";

export const Footer = ({map}) => {
  const { services, activeService } = useSnapshot(StoreApp);
  const widgets = services[activeService].widgets;

  return (
    <div className="footer">
      <div className="footer-left">
        {widgets.coordinates &&
          <Coordinates map={map}/>
        }
        {widgets.survey &&
          <SurveyDate map={map}/>
        }
        {widgets.scale &&
          <ScaleLineControl map={map}/>
        }
      </div>
      <div className="footer-right">
        <Legal />
      </div>
    </div>
  );
};
