// Imports
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { cookieGetTour, cookieSetTour } from "@apis-common";
import { getInit, getGeoStoreProducts } from "@apis-geostore";
import { Aside, Main, MainTop } from "@layout";
import { TourCard, PanelUpload } from "@partials";
import { MapGeostore, PanelGeostore } from "@service-geostore";
import { StoreApp, StoreUser, setTourOpen } from "@store-common";

// OPENLAYERS MAP COMPONENT
export const ServiceGeostore = () => {
  const { domain_id } = useSnapshot(StoreUser.user);
  const { userLoggedIn } = useSnapshot(StoreUser);
  const { tourOpen, uploadActive } = useSnapshot(StoreApp);

  // Tour
  useEffect(() => {
    const tourState = cookieGetTour();

    if (tourState === undefined) {
      cookieSetTour(true);
      setTourOpen(true);
    }
    if (tourState === "false") {
      setTourOpen(false);
    }
  }, []);

  // Initial data call
  useEffect(() => {
    // Get domain if the user logged in
    if (userLoggedIn) getInit(domain_id);
    // Get list of products
    getGeoStoreProducts();
  }, [domain_id]);

  return (
    <>
      {tourOpen && <TourCard />}
      <Main>
        <MainTop />
        <MapGeostore />
      </Main>
      <Aside>
        {uploadActive
          ? <PanelUpload />
          : <PanelGeostore />
        }
      </Aside>
    </>
  );
};
