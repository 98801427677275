// Imports
import * as olExtent from "ol/extent";
import { MultiPolygon, Polygon } from "ol/geom";
import * as olProj from "ol/proj";
import { getArea, getLength } from "ol/sphere";

export const formatLength = (line) => {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " km";
  } else {
    output = Math.round(length * 100) / 100 + " m";
  }
  return output;
};

export const formatArea = (polygon, unit) => {
  const area = getArea(polygon);
  let output;
  if (unit === "ha") {
    output = Math.round(area / 100) / 100 + " ha";
  } else {
    output = Math.round(area / 10000) / 100 + " km\xB2";
  }
  return output;
};

export const extractNumber = (str) => {
  const numericString = str.replace(/[^0-9.]/g, "");
  const parsedNumber = parseFloat(numericString);
  return parsedNumber;
};

export const getPolygonCenter = (polygon) => {
  const extent = polygon.getExtent();
  const center = olExtent.getCenter(extent);
  const lonLat = olProj.transform(center, "EPSG:3857", "EPSG:4326");
  const lon = lonLat[0];
  const lat = lonLat[1];
  return { lat, lon };
};

export const calculateArea = (coordinates, ext, type) => {
  const geometry =
    type === "MultiPolygon"
      ? new MultiPolygon(coordinates)
      : new Polygon(coordinates);

  const area = getArea(geometry, {
    projection: ext === "geojson" ? "EPSG:3857" : "EPSG:4326",
  });
  let output;
  if (area > 1000) {
    output = Math.round((area / 10000) * 100) / 100 + " ha";
  } else {
    output = Math.round(area * 100) / 100 + " m\xB2";
  }
  return output;
};

export const transformGeometry = (originalGeometry) => {
  let transformedGeometry = [];

  originalGeometry.map((geometry) =>
    transformedGeometry.push(geometry.coordinates)
  );

  return transformedGeometry;
};
