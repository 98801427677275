// Imports
import { useContext } from "react";
import { useSnapshot } from "valtio";
import { User } from "@icons";
import { StoreUser } from "@store-common";
import { ModalContext } from "../modal";

// Account link
export const Account = () => {
  const { setIsModalOpen } = useContext(ModalContext);
  const { userLoggedIn } = useSnapshot(StoreUser);

  const handleAccountLinkClick = (e) => {
    if (!userLoggedIn) {
      e.preventDefault();
      setIsModalOpen(true);
    }
  };

  return (
    <a
      id="tourSteps4"
      className="navbar-right__profile"
      href={userLoggedIn ? "https://account.envimap.hu/" : "#"}
      onClick={(e) => handleAccountLinkClick(e)}
    >
      <User />
    </a>
  );
};
