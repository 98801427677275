// Imports
import { urls } from "./urls";

export const Registration = async (body, setModalType, message) => {
  await fetch(urls.registration, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((actualData) => {
      if (actualData.status === 200) return setModalType("registrationSuccess");
    })
    .catch((error) => {
      console.log(error);
      message(["error", "Hiba lépett fel a regisztráció közben!"]);
    });
};
