export const purchasingProcess = [
  {
    title: "Igény leadása",
    desc: "Térképes felületünkön kijelölheti az igényeinek megfelelő területeket és kiválaszthatja, mely termékeinkre van szüksége.",
  },
  {
    title: "Díjbekérő",
    desc: "A beérkezett igény rögzítését követően rendszerünk díjbekérőt küld, mely kiegyenlítésére 8 nap áll rendelkezésére. Amennyiben 8 napon belül a díjbekérő nem kerül kiegyenlítésre, a megrendelés törlésre kerül felületünkről.",
  },
  {
    title: "Gyártás",
    desc: "A díjbekérő kiegyenlítése után néhány napon belül térinformatikai adatai elérhetővé válnak a felületen. A folyamatnak a megrendelés mennyiségétől függően változhat az elkészülési ideje.",
  },
  {
    title: "Átadás",
    desc: "Az elkészült állapotról automatikus értesítést küld EnviMAP© Téradatbank. Az állományokat innentől kezdve 1 hónapig letöltheti az EnviMAP© Személyes Profilból, valamint ezen a térképes felületen.",
  },
];
