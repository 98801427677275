// Imports
import earcut from "earcut";
import * as THREE from "three";
import { StoreModel } from "@service-roof";
import { computeWindingNormal, orderVertices } from "@utils-roof";

// SIDES
export const parseSides = (data, index) => {
  let groups = [];
  let typeIDNames = [
    "Alap",
    "Fal",
    "Teto",
    "Laposteto",
    "Tetoablak",
    "Tetoelem",
    "Kemeny",
    "Gepeszetielem",
  ];
  let typeIDs = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  data.forEach((feature, i) => {
    let mV = []; // Mixed vertices array
    let pV = []; // Computed position vertices array

    // Determine typeID
    let type = typeIDNames.findIndex((e) => e === feature.properties.LAYER);
    typeIDs[type] += 1;

    // Calculate WindingNormal
    let wN = computeWindingNormal(feature.geometry.coordinates[0].slice(0, 3));

    // Flatten Data
    let flatten = earcut.flatten(feature.geometry.coordinates);

    // Mix vertices according to winding rirection
    if (feature.properties.LAYER === "Teto" || feature.properties.LAYER === "Laposteto") {
      if (wN.x > 0 && wN.y > 0) {
        mV = orderVertices(flatten.vertices, [1, 2, 0]);
      } else if (wN.x > 0 && wN.y < 0) {
        mV = orderVertices(flatten.vertices, [2, 1, 0]);
        wN.negate();
      } else if (wN.x < 0 && wN.y > 0) {
        mV = orderVertices(flatten.vertices, [2, 1, 0]);
      } else if (wN.x < 0 && wN.y < 0) {
        mV = orderVertices(flatten.vertices, [1, 2, 0]);
        wN.negate();
      } else if (wN.x === 0 && wN.z === 0) {
        mV = orderVertices(flatten.vertices, [2,0,1]);
        wN.negate();
      }
    }

    // Tiriangulate
    let triangles = earcut(
      mV.length > 0 ? mV : flatten.vertices,
      flatten.holes,
      flatten.dimensions
    );

    triangles.forEach((corner) => {
      pV.push(
        new THREE.Vector3(
          flatten.vertices[corner * 3],
          flatten.vertices[corner * 3 + 1],
          flatten.vertices[corner * 3 + 2]
        )
      );
    });

    // Save to StoreModels
    StoreModel.model.sides.push({
      uID: index,
      tID: typeIDs[type],
      properties: feature.properties,
      vertices: pV,
      normal: wN,
      faceIndexes: [],
    });

    // Group side
    if (!groups[feature.properties.LAYER]) {
      groups[feature.properties.LAYER] = [
        {
          i: i,
          vertices: pV,
        },
      ];
    } else {
      groups[feature.properties.LAYER].push({
        i: i,
        vertices: pV,
      });
    }

    index++;
  });

  // Geometry loop
  const features = Object.entries(groups).map(([key, value]) => {

    let pArr = [];
    let faceIndex = 0;
    const geometry = new THREE.BufferGeometry();

    value.forEach((side) => {
      // Create positions array
      side.vertices.forEach((v) => {
        pArr.push(v.x, v.y, v.z);
      });
      // Push faceIndexes to StoreModel
      for (let i = 0; i < side.vertices.length / 3; i++) {
        StoreModel.model.sides[side.i].faceIndexes.push(faceIndex);
        faceIndex++;
      }
    });

    const positions = new Float32Array(pArr);
    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    geometry.computeVertexNormals();
    geometry.computeBoundingBox();

    geometry.userData.layer = key;
    geometry.userData.type = "sides";

    return geometry;
  });

  return { features, index: index };
};

// EDGES
export const parseEdges = (data, index) => {
  let typeIDNames = [
    "Gerinc",
    "Tarejgerinc",
    "Elgerinc",
    "Oromszegely",
    "Eresz",
    "Vapa",
    "Manzardtores",
    "Hajlasszogtores",
    "Falszegely",
    "Egyebszegely",
  ];
  let typeIDs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const features = data.map((feature) => {

    // Invalid file handling
    if (
      feature.geometry.coordinates.length <= 1 ||
      !feature.properties.LAYER
    ) {
      return;
    }

    let pV = []; // Computed position vertices array

    // Determine typeID
    let type = typeIDNames.findIndex((e) => e === feature.properties.LAYER);
    typeIDs[type] += 1;

    feature.geometry.coordinates.forEach((vector) => {
      pV.push(new THREE.Vector3(vector[0], vector[1], vector[2]));
    });

    // Save to StoreModels
    StoreModel.model.edges.push({
      uID: index,
      tID: typeIDs[type],
      properties: feature.properties,
      vertices: pV,
    });

    // Create curve
    const curveSegments = [];
    for (let i = 1; i < pV.length; i++) {
      const curveSegment = new THREE.LineCurve3(pV[i-1], pV[i]);
      curveSegments.push(curveSegment);
    }
    const curvePath = new THREE.CurvePath();
    curvePath.curves = curveSegments;

    // Create cap circle
    const circle = new THREE.Shape();
    circle.absarc(0, 0, 0.06);

    // Create extrude
    const extrudeSettings = {
      steps: curveSegments.length + 1,
      bevelEnabled: false,
      extrudePath: curvePath,
    };

    // Create geometry
    const geometry = new THREE.ExtrudeGeometry(circle, extrudeSettings);
    geometry.userData.type = "edges";
    geometry.userData.layer = feature.properties.LAYER;
    geometry.userData.uID = index;

    index++;

    return geometry;
  });

  return { features, index: index };
};