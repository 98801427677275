// Imports
import { MapConfig } from "@partials";
import { Add, Extent, Substract } from "@icons";

export const Zoom = ({ map }) => {
  if (!map) return;
  
  // Animate function
  const animateView = (options) => {
    const view = map.getView();
    view.animate(options);
  };

  // Zoom in and out
  const setZoom = (delta) => {
    animateView({
      zoom: map.getView().getZoom() + delta,
      duration: 200,
    });
  };

  // Zoom to initial extent
  const setMaxExtent = () => {
    animateView({
      center: MapConfig.baseViewWMCenter,
      zoom: MapConfig.initZoom,
      duration: 600,
    });
  };

  return (
    <div className="maptools__zoom">
      <button onClick={setMaxExtent}>
        <Extent />
      </button>
      <div>
        <button onClick={() => setZoom(+1)}>
          <Add />
        </button>
        <hr />
        <button onClick={() => setZoom(-1)}>
          <Substract />
        </button>
      </div>
    </div>
  );
};
