// Imports
import { proxy } from "valtio";
import { StoreDomain, StoreUser, setAside } from "@store-common";
import { transform } from "ol/proj";

// Initial data structures
const initActiveRequestData = {
  request_id: 0,
  building_type: "",
  building_features: 0,
  status: "",
  comment: "",
  created_at: "",
  updated_at: "",
  user: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
  },
  domain: {
    domain_id: "",
    domain_name: "",
  },
  model_json: null,
  pcd_file: null,
};

// STORE
export const StoreRequests = proxy({
  // Previous requests
  prevRequests: [],
  activePrevPin: null,

  // Detailed data for active request
  activeRequestData: initActiveRequestData,

  // New request
  newRequestPin: null,
  newRequestPinAddress: "",

  // Change tracking
  requestChangeTrack: 0,

  // Request canvas
  canvasIMG: null,
});

// Set previous requests from API
export const setRequests = (requests) => {
  let prevRequestsArray = [];

  // Helper function for resolved address parsing
  // TODO: Addresses will need to be stored with the new address object format
  function requiresJsonParse(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return true;
    }
    return false;
  }

  // Mapping data and add to array
  requests.map((request) => {
    let requestObj = {
      request_id: request.Map.request_id,
      status: request.Map.status,
      lat: request.Map.lat,
      lng: request.Map.lon,
      address: requiresJsonParse(request.Map.resolved_addr)
        ? {
            formatted: request.Map.resolved_addr,
            county: "?"
          }
        : JSON.parse(request.Map.resolved_addr),
      created_at: request.Map.created_at,
      user_id: request.User?.id,
      partner_id: request.Partner?.id,
      domain_id: request.Domain.domain_id,
      domain_name: request.Domain.domain_name,
      visible: true,
    };
    return prevRequestsArray.push(requestObj);
  });

  // Filter requests if user is not admin and cross sharing is disabled
  if (!StoreUser.user.is_admin && !StoreDomain.cross_sharing) {
    prevRequestsArray = prevRequestsArray.filter(
      (request) => request.user_id === StoreUser.user.id
    );
  }

  StoreRequests.prevRequests = prevRequestsArray;
};

// Filter requests based on id
export const filterRequests = (ids) => {
  StoreRequests.prevRequests.forEach((request) => {
    request.visible = ids.includes(request.request_id);
  });
};

export const resetFilterRequests = () => {
  StoreRequests.prevRequests.forEach((request) => {
    request.visible = true;
  });
};


// Set selected pin based on initial URL Search Param
export const getInitialUrlId = (id) => {
  let findRequestID = StoreRequests.prevRequests.find((e) => e.id === id);

  if (findRequestID) {
    const coords = transform(
      [findRequestID.lng, findRequestID.lat],
      "EPSG:4326",
      "EPSG:3857"
    );
    setActivePrevPin({
      id: findRequestID.id,
      coordinates: coords,
    });
  }
};

// Set selected pin when user click on a clusterPin
export const setActivePrevPin = (pin) => {
  StoreRequests.activePrevPin = pin;
  setAside(true);
};

// Clear selected pin when user closes panel - fired with setAside(false)
export const clearActivePrevPin = () => {
  StoreRequests.activePrevPin = null;
  clearActiveRequestData();
};

// Set detailed data for user scoped request
export const setActiveRequestData = (request) => {

  // Reset Canvas
  StoreRequests.canvasIMG = null;
  
  // Store request data
  StoreRequests.activeRequestData = {
    request_id: request.Map.request_id,
    address: request.Map.resolved_addr,
    building_type: request.Map.building_type,
    building_features: request.Map.building_features,
    coordinates: {
      lat: request.Map.lat,
      lng: request.Map.lon,
    },
    status: request.Map.status,
    comment: request.Map.comment,
    created_at: request.Map.created_at,
    updated_at: request.Map.updated_at,
    user: request.User,
    partner: request.Partner,
    domain: request.Domain,
    model_json: request.model_json,
    pcd_file: null
  };
};

// Set PCD file for the LiDAR view
export const setActiveRequestPCD = (blob) => {
  StoreRequests.activeRequestData.pcd_file = blob;
};

// Clear all data for user scoped request
export const clearActiveRequestData = () => {
  StoreRequests.activeRequestData = initActiveRequestData;
};

// Set new request pin when user click on the map
export const setNewRequestPin = (lng, lat, availability) => {
  StoreRequests.newRequestPin = {
    lat: lat,
    lng: lng,
    available: availability,
  };
  setAside(true);
};

// Clear new request pin when user closes panel - fired with setAside(false)
export const clearNewRequestPin = () => {
  StoreRequests.newRequestPin = null;
};

// Set resolved Address from Google API
export const setNewRequestPinAddress = (address) => {
  StoreRequests.newRequestPinAddress = address;
};

// Set freshly requested pin
export const setFreshRequest = (request_id, body) => {
  // Build request object
  const requestObj = {
    request_id: request_id,
    status: "Pending",
    lat: body.lat,
    lng: body.lon,
    address: body.resolved_addr,
  };

  // Push request object to store array
  StoreRequests.prevRequests.push(requestObj);

  // Modify change track to initiate render
  setRequestsChangeTrack(request_id);

  // Close Panel
  setAside(false);
};

// Set marker StoreApp
export const setRequestsChangeTrack = (request_id) => {
  StoreRequests.requestChangeTrack = request_id;
};

// Set canvasImage
export const setCanvasImage = (dataURL) => {
  StoreRequests.canvasIMG = dataURL;
};

export const closePanel = () => {
  clearActivePrevPin();
  clearNewRequestPin();
  clearActiveRequestData();
  setAside(false);
};
