// Imports
import { useSnapshot } from "valtio";
import { StoreModel } from "@service-roof";
import Fullscreen from "./Fullscreen";
import ListPanel from "./ListPanel";
import Help from "./Help";
import Toolbar from "./Toolbar";
import ViewActions from "./ViewActions";
import Viewbar from "./Viewbar";

// HUD
const HUD = ({ model }) => {
  const { fullScreen } = useSnapshot(StoreModel.view);
  const { currentTool } = useSnapshot(StoreModel.tool);

  return (
    <>
      {fullScreen && (
        <>
          {currentTool === 0 && <ListPanel />}
          <Toolbar model={model} />
          <Viewbar />
          <ViewActions />
          <Help.HelpButton />
        </>
      )}
      <Fullscreen />
    </>
  );
};

export default HUD;
