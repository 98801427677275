import test from "../../assets/images/common/tour/test.gif";
import step01Illustration from "../../assets/images/common/tour/tour01.gif";

export const tourSteps = [
  {
    title: "Terület kijelölése",
    desc: "Ceruza eszközünk segítségével megrajzolhatja a megrendelni kívánt területek poligonját.",
    illustration: step01Illustration,
    targetID: "tourSteps0",
    position: "bottom",
  },
  {
    title: "Böngészés",
    desc: "Curzor eszközünk segítségével szabadon közelíthet vagy távolíthat a térképen.",
    illustration: test,
    targetID: "tourSteps1",
    position: "bottom",
  },
  {
    title: "Mérés",
    desc: "Vonalzó eszközünk segítségével mérési műveleteket végezhet a térképen.",
    illustration: test,
    targetID: "tourSteps2",
    position: "bottom",
  },
  {
    title: "Keresés",
    desc: "A kereső ikonra kattintva lokációk megadásával kereshet a térképen.",
    illustration: test,
    targetID: "tourSteps3",
    position: "top",
  },
  {
    title: "Profil",
    desc: "A profil ikonra kattintva saját felhasználói profiljába juthat el.",
    illustration: test,
    targetID: "tourSteps4",
    position: "top",
  },
  {
    title: "Bemutató vége!",
    desc: "Bízunk benne, hogy az oldalunk hasznos lesz számukra. Kellemes böngészést!",
    illustration: "",
    targetID: "tourSteps4",
    position: "top",
  },
];

export const tourDefaultStyle = {
  display: "none",
  position: "absolute",
};
