// Imports
import { Logo } from "@icons";

export const Brand = () => {
  return (
    <div className="navbar-left__logo">
      <a href="https://account.envimap.hu/">
        <Logo />
      </a>
    </div>
  );
};