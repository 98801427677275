import { Add, Bin, Close, Cursor, Edit, Ruler, Upload } from "@icons";
import { getArea } from "ol/extent";
import { getLength } from "ol/sphere";
import { drawVectorLayer } from "./layer.toolbar";
import { zoomToLayerExtent } from "@partials";

// Tool icons for mapping
const toolIconsArr = [
  { key: "add", component: Add },
  { key: "cursor", component: Cursor },
  { key: "ruler", component: Ruler },
  { key: "edit", component: Edit },
  { key: "upload", component: Upload },
  { key: "bin", component: Bin },
  { key: "close", component: Close },
];

const toolsPolygonEdit = {
  edit: true,
  bin: true,
  upload: true,
  close: true,
};

// Active switch for buttons
const activeColorSwitch = (key, activeTool) =>
  `${activeTool === key ? "black" : "white"}`;

// OL - Measure utils
const formatLength = (line) => {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " km";
  } else {
    output = Math.round(length * 100) / 100 + " m";
  }
  return output;
};

const formatArea = (polygon) => {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " km\xB2";
  } else {
    output = Math.round(area * 100) / 100 + " m\xB2";
  }
  return output;
};

const resetPrevDataFromMap = (map) => {
  if (map) {
    const layers = map.getLayers();
    const previousLayer = layers
      .getArray()
      .find((layer) => layer.get("title") === "previous-layer");
    if (previousLayer) {
      return layers.remove(previousLayer);
    } else {
      console.warn("Didn't find layer called: previous-layer");
    }
  } else {
    console.warn("Map is undefined");
  }
};

const getFeature = (map, pixel, scopedLayer) => map.forEachFeatureAtPixel(pixel, feature => feature, {
  layerFilter: layer => layer === scopedLayer
});

const checkDrawVectorLayerExist = (map) => {
  let drawVectorLayerExist = false;
  map.getLayers().forEach(function(layer) {
    if (layer.get('title') === 'draw-layer') {
      drawVectorLayerExist = true;
    }
  });
  if (!drawVectorLayerExist) {
    // Add layer
    map.addLayer(drawVectorLayer);

    // Add change event
    let drawFeatureAddTimeout, drawFeaturesLength;
    drawVectorLayer.getSource().on('change', () => {
      clearTimeout(drawFeatureAddTimeout);
  
      drawFeatureAddTimeout = setTimeout(() => {
        const currentDrawFeaturesLength = drawVectorLayer.getSource().getFeatures().length;
  
        if (currentDrawFeaturesLength > 0) {
          if (currentDrawFeaturesLength !== drawFeaturesLength) {
            drawFeaturesLength = currentDrawFeaturesLength;
            zoomToLayerExtent(map, drawVectorLayer, 100);
          }
        }
      }, 500);
    });

  }
};


export {
  toolIconsArr,
  toolsPolygonEdit,
  formatLength,
  formatArea,
  activeColorSwitch,
  resetPrevDataFromMap,
  getFeature,
  checkDrawVectorLayerExist
};
