// Imports
import { useSnapshot } from "valtio";
import { ArrowDropDown, Bin, Close, Filter } from "@icons";
import { StoreApp, setFilterPanelOpen } from "@store-common";
import { Checkbox, Radio } from "@molecules";
import { useEffect, useState } from "react";
import { StoreRequests, filterRequests } from "@store-roof";
import { createFilterData } from "@utils-roof";

// Filter
export const FilterPanel = () => {
  const { filterPanelOpen } = useSnapshot(StoreApp);
  const { prevRequests } = useSnapshot(StoreRequests);
  const [ filterData, setFilterData ] = useState(null);
  const [ filterActive, setFilterActive ] = useState(false);
  const [ selectedFilters, setSelectedFilters ] = useState({ dates:[0], domains:[], counties:[] });
  const [ filteredIDs, setFilteredIDs ] = useState([]);


  // Collect IDs from filter selections
  const getIDsForSelection = (filteredIDs, filterKey) => {
    if (selectedFilters[filterKey]) {
      selectedFilters[filterKey].forEach((index) => {
        const reqIDs = filterData[filterKey][index].reqIDs;
        filteredIDs.push(...reqIDs.filter((reqID) => !filteredIDs.includes(reqID)));
      });
    }
    return filteredIDs;
  };

  // Handle filtering
  const handleFiltering = () => {
    let dateFilteredIDs = [];
    let domainFilteredIDs = [];
    let cityFilteredIDs = [];

    getIDsForSelection( dateFilteredIDs, 'dates');
    getIDsForSelection( domainFilteredIDs, 'domains');
    getIDsForSelection( cityFilteredIDs, 'counties');

    let computedIDs = dateFilteredIDs.length > 0
      ? domainFilteredIDs.length > 0
        ? domainFilteredIDs.filter((id) => dateFilteredIDs.includes(id))
        : dateFilteredIDs
      : domainFilteredIDs;

    computedIDs = computedIDs.length > 0
      ? cityFilteredIDs.length > 0
        ? cityFilteredIDs.filter((id) => computedIDs.includes(id))
        : computedIDs
      : cityFilteredIDs;

    setFilteredIDs(computedIDs);
  };

  // Handle reseting all filters
  const handleResetAll = () => {
    setSelectedFilters({ dates:[0], domains:[], counties: [] });
  };

  // Set filterData from prevRequests array
  useEffect(() => {
    if(!prevRequests.length || filterData) return;
    setFilterData(createFilterData(prevRequests));
  }, [prevRequests]);

  // Filter requests if selected filters change
  useEffect(() => {
    if(!filterData) return;
    handleFiltering();
    setFilterActive(
      !selectedFilters.dates.includes(0) || 
      selectedFilters.domains.length > 0 || 
      selectedFilters.counties.length > 0
    );
  }, [selectedFilters]);

  // Do the request filtering
  useEffect(() => {
    filterRequests(filteredIDs);
  }, [filteredIDs]);
  
  return (
    <div className={`navbar-right__filter ${filterPanelOpen ? "active" : ""}`}>
      <div
        className="navbar-right__filter__head"
        onClick={() => setFilterPanelOpen(!filterPanelOpen)}
      >
        {filterPanelOpen
          ? (
            <>
              <h4>Szűrés</h4>
              <Close />
            </>
          ) : (
            <>
              <Filter/>
              {filterActive && (
                <div className={`navbar-right__filter__head__activeNum ${!filteredIDs.length ? "zero" : ""}`}>
                  {filteredIDs.length}
                </div>
              )}
            </>
          )
        }
      </div>

      {filterPanelOpen && (
        <div className="navbar-right__filter__panel">
          <div className="navbar-right__filter__panel-content">

            {filterData && (
              <>
                <FilterGroup
                  groupTitle="Dátumok szerint"
                  radio
                  itemTag="dates"
                  data={filterData}
                  dataID="name"
                  state={selectedFilters}
                  setState={setSelectedFilters}
                  renderItem={(item, i) => (
                    <>
                      <Radio state={selectedFilters.dates.includes(i)} />
                      <span>{item.name}</span>
                      <div className="itemsCount"><span>{item.reqIDs.length}</span></div>
                    </>
                  )}
                />

                {filterData.domains.length > 1 && (
                  <FilterGroup
                    groupTitle="Domainek szerint"
                    itemTag="domains"
                    data={filterData}
                    dataID="uID"
                    state={selectedFilters}
                    setState={setSelectedFilters}
                    renderItem={(item, i) => (
                      <>
                        <Checkbox state={selectedFilters.domains.includes(i) ? "true" : "false"} />
                        <span>{item.name}</span>
                        <div className="itemsCount"><span>{item.reqIDs.length}</span></div>
                      </>
                    )}
                    displayLength={5}
                  />
                )}

                <FilterGroup
                  groupTitle="Megyék szerint"
                  itemTag="counties"
                  data={filterData}
                  dataID="name"
                  state={selectedFilters}
                  setState={setSelectedFilters}
                  renderItem={(item, i) => (
                    <>
                      <Checkbox state={selectedFilters.counties.includes(i) ? "true" : "false"} />
                      <span>{item.name}</span>
                      <div className="itemsCount"><span>{item.reqIDs.length}</span></div>
                    </>
                  )}
                  displayLength={10}
                />
              </>
            )}
          </div>

          {filterActive && (
            <div
              className={`navbar-right__filter__panel-result ${!filteredIDs.length ? "noresults" : ""}`}
              onClick={handleResetAll}
            >
              <span className="span--bold">
                {filteredIDs.length > 0
                  ? `${filteredIDs.length} találat`
                  : "Nincs találat"
                }
              </span>
              <div>
                <span>Szűrők törlése</span>
                <Bin color="white"/>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};


const FilterGroup = ({...props}) => {
  const [ displayAll, setDisplayAll ] = useState(typeof props.displayLength !== "number");

  const handleChange = (e) => {
    const index = props.data[props.itemTag].findIndex((d) => d[props.dataID] === e.target.id);

    if (props.radio) {
      props.setState((prevState) => {
        return {
          ...prevState,
          [props.itemTag]:[index]
        };
      });
    } else {
      props.setState((prevState) => {
        return {
          ...prevState,
          [props.itemTag]: prevState[props.itemTag].includes(index)
            ? prevState[props.itemTag].filter((i) => i !== index)
            : [...prevState[props.itemTag], index]
        };
      });
    }
  };

  const handleReset = () => {
    props.setState((prevState) => {
      return { ...prevState, [props.itemTag]: []};
    });
  };

  const toogleDisplayAll = () => {
    setDisplayAll(!displayAll);
  };
  
  return (
    <div className="filterGroup">
      
      <div className="filterGroup__head">
        <h5>{props.groupTitle}</h5>
        {!props.radio && props.state[props.itemTag].length > 0 && (
          <span onClick={handleReset}>Összes</span>
        )}
      </div>

      <div className="filterGroup__content">
        <ul className="filterGroup__content--items">
          {props.data[props.itemTag]
            .slice(0, displayAll ? props.data[props.itemTag].length : props.displayLength)
            .map((item, i) => (
              <li
                key={i}
                id={item[props.dataID]}
                onClick={(e) => handleChange(e)}
              >
                {props.renderItem(item, i)}
              </li>
            ))
          }
        </ul>

        {(props.displayLength && props.data[props.itemTag].length >= props.displayLength) && (
          <div
            className={`filterGroup__content--more ${displayAll ? "showless" : ""}`}
            onClick={toogleDisplayAll}
          >
            <ArrowDropDown />
            <span>
              {displayAll
                ? "Kevesebb"
                : `További ${props.data[props.itemTag].length - props.displayLength} mutatása`
              }
            </span>
          </div>
        )}
      </div>
    </div>
  );
};