// Imports
import { useEffect, useRef } from "react";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import {
  StoreModel,
  handleCursorPanelClose,
  handlePointerClick,
} from "@service-roof";
import { Close, Copy } from "@icons";
import { Billboard, Bounds, Html, useBounds } from "@react-three/drei";
import { useHover } from "@use-gesture/react";
import {
  createInteractionGeometry,
  getAvarageCenterPoint,
  getBearingCardinal,
  round,
  transformLayerName,
  weightedAverage,
} from "@utils-roof";

// CURSOR TOOL
export const CursorTool = () => {
  return (
    <mesh>
      <Highlight />
      <Selection />
    </mesh>
  );
};

const Highlight = () => {
  const { currentTool } = useSnapshot(StoreModel.tool);
  const { highlightVisible, poiUID, poiType, panelHovered } = useSnapshot(StoreModel.pointer);
  const { model } = useSnapshot(StoreModel);
  const { ready } = useSnapshot(StoreModel.model);

  if (currentTool === 0 && highlightVisible && ready && poiUID !== null && !panelHovered) {
    const highlightedItem = createInteractionGeometry(model, poiType, poiUID);
    
    return (
      <mesh
        geometry={highlightedItem.geometry}
        position={highlightedItem.shiftPosition}
        onClick={(e) => {
          e.stopPropagation();
          handlePointerClick(highlightedItem.geometry, highlightedItem.shiftPosition, highlightedItem.centerPoint);
        }}
      >
        <meshBasicMaterial
          side={THREE.DoubleSide}
          transparent
          opacity={poiType !== "edges" ? 0.4 : 0.8}
          color={"#00FF7F"}
        />
      </mesh>
    );
  }
};

const Selection = () => {
  const { selection } = useSnapshot(StoreModel.cursor);
  const { listPanelOpen } = useSnapshot(StoreModel.view);

  if (selection.visible && selection.items.length > 0) {
    return (
      <>
        <Bounds fit damping={4} margin={1.5}>
          <SelectedItems />
        </Bounds>
        {!listPanelOpen &&
          <SelectedPanel center={getAvarageCenterPoint(selection.items)} />
        }
      </>
    );
  }
};

const SelectedItems = () => {
  const { selectionChangeTrack } = useSnapshot(StoreModel.cursor);
  const bounds = useBounds();

  useEffect(() => {
    bounds.refresh().fit();
  }, [selectionChangeTrack, bounds]);

  const geometries = StoreModel.cursor.selection.items.map((item, i) => {
    return (
      <mesh
        key={i}
        geometry={item.g}
        position={item.sP}
      >
        <meshBasicMaterial
          transparent
          opacity={0.8}
          color={"#00FF7F"}
        />
      </mesh>
    );
  });

  return <mesh>{geometries}</mesh>;
};

const SelectedPanel = ({ center }) => {
  const { selection } = useSnapshot(StoreModel.cursor);
  const { model } = useSnapshot(StoreModel);

  const panelRef = useRef();
  const multiSelection = selection.items.length >= 2;

  const items = selection.items.map((item) => {
    return model[item.type].filter((e) => e.uID === item.uID)[0];
  });

  // Area (SUM)
  let totalArea = null;
  items.forEach((item) => {
    totalArea += item.properties.area_3d;
  });
  totalArea = round(totalArea, 2);

  // Tilt Angle (AVG)
  let tiltWeights = [];
  let tiltAngles = [];
  items.forEach((item) => {
    tiltWeights.push(item.properties.area_3d);
    tiltAngles.push(item.properties.slope);
  });
  let avgTiltAngle = weightedAverage(tiltWeights, tiltAngles);
  avgTiltAngle = isNaN(avgTiltAngle) ? null : round(avgTiltAngle, 2);

  // Bearing (AVG)
  let bearingWeights = [];
  let bearingAngles = [];
  items.forEach((item) => {
    bearingWeights.push(item.properties.area_3d);
    bearingAngles.push(item.properties.aspect);
  });
  let avgBearingAngle = round(
    weightedAverage(bearingWeights, bearingAngles),
    0
  );

  // Edge Length
  let edgeLength = 0;
  items.forEach((item) => {
    edgeLength = edgeLength + item.properties.length_3d;
  });
  edgeLength = round(edgeLength, 2);

  // Solar panel width
  let solarWidth = 0;
  items.forEach((item) => {
    solarWidth = solarWidth + item.properties.width;
  });
  solarWidth = round(solarWidth / items.length, 2);

  // Solar panel height
  let solarHeight = 0;
  items.forEach((item) => {
    solarHeight = solarHeight + item.properties.height;
  });
  solarHeight = round(solarHeight / items.length, 2);

  // Hover hook
  const bind = useHover(
    ({ active }) => {
      StoreModel.pointer.panelHovered = active;
    },
    { keys: false }
  );

  // Title
  const RenderTitle = () => {
    if (multiSelection) {
      return <h4>{selection.items.length} elem</h4>;
    } else {
      const displayLayerName = transformLayerName(items[0].properties.LAYER);
      return (
        <h4>
          {displayLayerName} #{items[0].tID}
        </h4>
      );
    }
  };

  // Meta
  const RenderMeta = ({ multiSelection }) => {
    return (
      <ul className="meta">
        {!isNaN(totalArea) && totalArea !== "0.00" && (
          <RenderParameter
            label="Terület"
            value={totalArea}
            metric=" m²"
            multiLabel="+"
            multiLabelTitle="Kijelölt síkok összege"
          />
        )}
        {!isNaN(avgTiltAngle) && !multiSelection && (
          <RenderParameter label="Dőlésszög" value={avgTiltAngle} metric="°" />
        )}
        {!isNaN(avgBearingAngle) && !multiSelection && (
          <RenderParameter
            label="Tájolás"
            value={
              getBearingCardinal(avgBearingAngle) + " / " + avgBearingAngle
            }
            metric="°"
          />
        )}
        {!isNaN(edgeLength) && (
          <RenderParameter
            label="Hossz"
            value={edgeLength}
            metric=" m"
            multiLabel="+"
            multiLabelTitle="Kijelölt élek hossza"
          />
        )}
        {!isNaN(solarWidth) && (
          <RenderParameter
            label="Panel szélesség"
            value={solarWidth}
            metric=" m"
            multiLabel="μ"
            multiLabelTitle="Kijelölt síkok átlaga"
          />
        )}
        {!isNaN(solarHeight) && (
          <RenderParameter
            label="Panel hosszúság"
            value={solarHeight}
            metric=" m"
            multiLabel="μ"
            multiLabelTitle="Kijelölt síkok átlaga"
          />
        )}
      </ul>
    );
  };

  // Meta Parameter
  const RenderParameter = ({
    label,
    value,
    metric,
    multiLabel,
    multiLabelTitle,
  }) => {
    if (value !== null) {
      return (
        <li>
          <span className="label">{label}</span>
          <span className="value">
            {value}
            {metric}
            <div
              className={`multi-label ${multiSelection ? "active" : ""}`}
              title={multiLabelTitle}
            >
              {multiLabel}
            </div>
          </span>
        </li>
      );
    }
  };

  const handleCursorPanelCopy = () => {
    const copyData = [
      totalArea,
      avgTiltAngle,
      avgBearingAngle,
      edgeLength,
      solarWidth,
      solarHeight,
    ];
    let copyValues = [];

    copyData.forEach((data) => {
      !isNaN(data) && copyValues.push(data);
    });
    navigator.clipboard.writeText(copyValues.join("\n"));
  };


  return (
    <Billboard ref={panelRef} follow={true} position={center}>
      <Html className="cursor-panel" center position={[0, 0, 0.5]}>
        <div className="wrapper" {...bind()}>
          <div className="head">
            <RenderTitle />
            <button
              className="close"
              onClick={(e) => {
                e.stopPropagation();
                handleCursorPanelClose();
              }}
            >
              <Close />
            </button>
          </div>
          <RenderMeta multiSelection={multiSelection} />
          <button
            className="copy"
            onClick={(e) => {
              e.stopPropagation();
              handleCursorPanelCopy();
            }}
          >
            <Copy />
            <span>Adatok másolása</span>
          </button>
        </div>
      </Html>
    </Billboard>
  );
};
