export const handleInput = (e, setFormData) => {
  const { name, value, type, checked } = e.target;

  setFormData((prevFormData) => {
    const updatedFormData = prevFormData.map((item) =>
      item.name === name
        ? {
            ...item,
            [type === "checkbox" ? "value" : "value"]:
              type === "checkbox" ? checked : value,
          }
        : item
    );

    return updatedFormData;
  });
};


export const dataObject = (formData) => formData.reduce((acc, field) => {
  acc[field.name] = field.value;
  return acc;
}, {});
