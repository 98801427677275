// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { cookieRemoveGeoStore } from "@apis-common";
import { Upload } from "@partials";
import {
  StoreApp,
  setAside, // setToolbarHidden,
  // setFileUploadMultiStep,
} from "@store-common";
import { setPanelType } from "@store-forest";
import { resetPanelInfoGeoStore } from "@store-geostore";
import { preprocessToolsObject } from "@utils-common";
import { clearDraw, createDraw, eraseDraw } from "./draw.toolbar";
import {
  activeColorSwitch,
  resetPrevDataFromMap,
  toolIconsArr,
  toolsPolygonEdit,
} from "./utils.toolbar";

export const Toolbar = ({ map, tools, setKey }) => {
  // Create reducer compatibility
  const preprocessedToolsObj = preprocessToolsObject(tools);

  // States
  const { activeService, asideActive } = useSnapshot(StoreApp);
  const [activeTool, setActiveTool] = useState("");
  const [toolsDisplayed, setToolsDisplayed] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  // Click handler
  const onClickEvent = (key) => {
    // Set active button
    setActiveTool(key);

    // Additional tasks for "cursor" key
    if (key === "cursor") {
      clearDraw(map);
      if (activeService === 1) setKey(key);
    }

    // Additional tasks for "add" key
    if (key === "add") {
      // clearDraw(map);
      createDraw(map, "Polygon", activeService);

      // Set toolbar
      setToolsDisplayed(toolsPolygonEdit);
      const initActiveTool = Object.keys(toolsPolygonEdit);

      // Set active toolbar item
      setActiveTool(initActiveTool[0]);

      // Open new request panel with data
      setPanelType("area");
    }

    // Additional tasks for "ruler" key
    if (key === "ruler") {
      clearDraw(map);
      createDraw(map, "LineString", activeService);
      if (activeService === 1) setKey(key);
    }

    // Additional tasks for "edit" key
    if (key === "edit") {
      createDraw(map, "Polygon", activeService);
      // Set new panel
      setPanelType("area");
    }

    // Additional tasks for "bin" key
    if (key === "bin") {
      eraseDraw(map, activeService);

      // Remove saved data
      cookieRemoveGeoStore();

      // Remove paneldata
      if (activeService === 0) {
        // If actice service is GeoStore clear cookie polygon
        resetPrevDataFromMap(map);

        resetPanelInfoGeoStore();
      }
    }

    // Additional tasks for "bin" key
    if (key === "upload") {
      clearDraw(map);
      Upload(map, activeService);
    }

    // Additional tasks for "close" key
    if (key === "close") {
      clearDraw(map);

      // Set toolbar
      setToolsDisplayed(preprocessedToolsObj);
      const initActiveTool = Object.keys(preprocessedToolsObj);

      // Set active toolbar item
      setActiveTool(initActiveTool[0]);

      // Close aside panel
      setAside(false);
    }
  };

  // ESC key event
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        if (map) {
          // Set active button to "cursor"
          setActiveTool("cursor");
          // Remove interaction
          clearDraw(map);
          // Close aside if it's open
          setAside(false);
          if (activeService === 1) setKey("cursor");
        }
      }
    };

    // Subscribe to the ESC keydown event
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: unsubscribe from the ESC keydown event
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [map, setActiveTool]);

  // Init active tool
  useEffect(() => {
    if (
      firstRender &&
      (preprocessedToolsObj !== null || preprocessedToolsObj !== undefined)
    ) {
      setToolsDisplayed(preprocessedToolsObj);
      const initActiveTool = Object.keys(preprocessedToolsObj);
      setActiveTool(initActiveTool[0]);

      setFirstRender(false);
    }
  }, [preprocessedToolsObj, firstRender]);

  useEffect(() => {
    setToolsDisplayed(preprocessedToolsObj);
    const initActiveTool = Object.keys(preprocessedToolsObj);
    setActiveTool(initActiveTool[0]);
  }, [activeService, tools]);

  // ClearDraw if aside has been closed
  useEffect(() => {
    if (!asideActive) {
      setToolsDisplayed(preprocessedToolsObj);
      setActiveTool(Object.keys(preprocessedToolsObj)[0]);
    }
  }, [asideActive]);

  return (
    <div className="toolbar">
      {Object.keys(toolsDisplayed).map((key, index) => {
        const matchedIcon = toolIconsArr.find((item) => item.key === key);
        const Icon = matchedIcon ? matchedIcon.component : null;
        const originalToolIndex = toolIconsArr.findIndex(
          (tool) => tool.key === key
        );
        return (
          <button
            id={`tourSteps${originalToolIndex}`}
            key={index}
            className={`${activeTool === key ? "active" : key}`}
            onClick={() => onClickEvent(key)}
          >
            {Icon && <Icon color={activeColorSwitch(key, activeTool)} />}
          </button>
        );
      })}
    </div>
  );
};
