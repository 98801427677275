import { useEffect, useRef } from 'react';
import { ScaleLine } from 'ol/control';

export const ScaleLineControl = ({ map }) => {
  const scaleLineRef = useRef(null);

  useEffect(() => {
    if (!map) return;

    const scaleLine = new ScaleLine({
      target: scaleLineRef.current,
    });

    map.addControl(scaleLine);
    return () => {map.removeControl(scaleLine);};
  }, [map]);

  return (
    <div
      className="footer__scaleline"
      ref={scaleLineRef}
    />
  );
};