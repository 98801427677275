export const InputVAT = ({
  label,
  type = "text",
  placeholder,
  value,
  onChange,
  name,
  required = false,
  disabled = false,
  hasError,
  onBlur
}) => {
  const formatValue = (inputValue) => {
    const numericOnly = inputValue.replace(/[^0-9]/g, "");
    return numericOnly;
  };

  const insertHyphens = (inputValue) => {
    if (inputValue.length >= 8) {
      let formattedValue = `${inputValue.slice(0, 8)}`;
      if (inputValue.length >= 9) {
        formattedValue += `-${inputValue.slice(8, 9)}`;
      }
      if (inputValue.length >= 10) {
        formattedValue += `-${inputValue.slice(9, 11)}`;
      }
      return formattedValue;
    }
    return inputValue;
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = insertHyphens(formatValue(inputValue));
    onChange(formattedValue);
  };

  const formattedInputValue = insertHyphens(formatValue(value));

  return (
    <div className={`input${hasError ? " error" : ""}`}>
      <label>
        {label}
        <input
          type={type}
          name={name}
          value={formattedInputValue}
          onChange={handleChange}
          placeholder={placeholder}
          autoComplete={type === "password" ? "on" : ""}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
        />
      </label>
    </div>
  );
};
