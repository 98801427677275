import { Close } from "@icons";

// Imports
export const PanelNoData = () => {
  const baseClass = "panel__content-box__request-forest-no-data";

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__icon`}> <Close color="white" /> </div>
      <h2>Nincsenek</h2>
      <h2>Elérhető adatok</h2>
      <p>A kijelölt terület egy részéhez sajnos jelenleg nem rendelkezünk adatokkal. Kérjük csak az ortofotó által is fedett területet jelöljön ki.</p>
    </div>
  );
};
