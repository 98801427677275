import { ArrowDropDown } from "@icons";
import { AccordionContent } from "./AccordionContent";

export const Accordion = ({ item, i, expanded, toggleAccordion }) => {
  const { title, content, badge } = item;

  return (
    <div className="accordion">
      <div className="accordion__header" onClick={() => toggleAccordion(i)}>
        <h4 className="accordion__header-title">{title}</h4>
        <div className="accordion__header-right">
          <span>{badge}</span>
          <div
            className={`accordion__header-icon${
              expanded === i ? " expanded" : ""
            }`}
          >
            <ArrowDropDown />
          </div>
        </div>
      </div>
      <div className={`accordion__content${expanded === i ? " expanded" : ""}`}>
        <AccordionContent content={content} />
      </div>
    </div>
  );
};
