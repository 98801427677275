// Imports
import { formatArea, formatLength } from "@utils-forest";
import { PinStyle } from "./Pin";
import { LineString, Point } from "ol/geom";
import MultiPoint from "ol/geom/MultiPoint.js";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";

// Current styles
export const circleStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: "#FFFFFF",
    }),
    stroke: new Stroke({
      color: "#00FF7F",
      width: 3,
    }),
  }),
  geometry: (feature) => {
    // return the coordinates of the first ring of the polygon
    const coordinates = feature.getGeometry().getCoordinates()[0];
    return new MultiPoint(coordinates);
  },
});
export const style = new Style({
  fill: new Fill({
    color: "rgba(0, 255, 127, 0.2)",
  }),
  stroke: new Stroke({
    color: "#00FF7F",
    width: 2,
  }),
});
export const labelStyle = new Style({
  text: new Text({
    fill: new Fill({
      color: "#FFFFFF",
    }),
    font: "bold 14px IBM Plex Sans, Calibri",
    padding: [3, 3, 3, 3],
    textBaseline: "bottom",
    offsetY: -15,
  }),
});
export const modifyStyle = new Style({
  text: new Text({
    text: "Változtatáshoz húzza arébb",
    fill: new Fill({
      color: "#FFFFFF",
    }),
    font: "14px IBM Plex Sans, Calibri",
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    padding: [3, 3, 3, 3],
    textBaseline: "bottom",
    offsetY: -15,
  }),
});
export const segmentStyle = new Style({
  text: new Text({
    fill: new Fill({
      color: "#FFFFFF",
    }),
    font: "bold 14px IBM Plex Sans, Calibri",
    padding: [3, 3, 3, 3],
    textBaseline: "bottom",
    offsetY: -15,
  }),
});

// Selected style
export const selectedStyle = new Style({
  fill: new Fill({
    color: "rgba(0, 255, 127, 0.5)",
  }),
  stroke: new Stroke({
    color: "#00FF7F",
    width: 4,
  }),
});

// Previous requests styles
export const pendingStyle = new Style({
  fill: new Fill({
    color: "#f5f53d80",
  }),
  stroke: new Stroke({
    color: "#F5F53D",
    width: 3,
  }),
});
export const editingStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.5)",
  }),
  stroke: new Stroke({
    color: "#FFFFFF",
    width: 3,
  }),
});

// Upload styles 
export const uploadStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)",
  }),
  stroke: new Stroke({
    color: "#FFFFFF",
    width: 3,
  }),
});
export const selectedUpload = new Style({
  fill: new Fill({
    color: "rgba(0, 255, 127, 0.30)",
  }),
  stroke: new Stroke({
    color: "#00FF7F",
    width: 3,
  }),
});

// Style functions
const segmentStyles = [segmentStyle];
export const styleFunction = (feature, segments, drawType) => {
  // Drawing lines for measuring tools
  let styles = [style];
  const geometry = feature.getGeometry();
  const type = geometry.getType();
  let point, label, line;
  if (!drawType || drawType === type) {
    if (type === "Polygon") {
      point = geometry.getInteriorPoint();
      label = formatArea(geometry);
      line = new LineString(geometry.getCoordinates()[0]);
    } else if (type === "LineString") {
      point = new Point(geometry.getLastCoordinate());
      label = formatLength(geometry);
      line = geometry;
    }
  }
  if (segments && line) {
    let count = 0;
    line.forEachSegment((a, b) => {
      const segment = new LineString([a, b]);
      const label = formatLength(segment);
      if (segmentStyles.length - 1 < count && type === "Polygon") {
        segmentStyles.push(segmentStyle.clone());
      }
      if (type === "LineString") {
        segmentStyles.push(segmentStyle.clone());
      }
      const segmentPoint = new Point(segment.getCoordinateAt(0.5));
      styles.push(circleStyle);
      segmentStyles[count].setGeometry(segmentPoint);
      segmentStyles[count].getText().setText(label);
      styles.push(segmentStyles[count]);
      count++;
    });
  }
  if (label && type === "Polygon") {
    labelStyle.setGeometry(point);
    labelStyle.getText().setText(label);
    styles.push(labelStyle);
  }
  return styles;
};
export const prevStyleFunction = (feature) => {
  const featureStatus = feature.get("status");
  let styles;

  if (featureStatus === "Available") styles = [style];
  if (featureStatus === "Pending") styles = [pendingStyle];
  if (featureStatus === "Archived") styles = [editingStyle];
  if (featureStatus === "Saved") styles = [editingStyle];
  if (featureStatus === "Upload") styles = [uploadStyle];

  return styles;
};
export const selectStyleUpload = (feature) => {
  const color = feature.get("COLOR") || "rgba(0, 255, 127, 0.30)";
  selectedUpload.getFill().setColor(color);
  return selectedUpload;
};

// Cluster styles
// Cluster styles
const styleCache = {};
export const clusterStyleFunction = (feature) => {
  const size = feature.get("features").length;
  let style = styleCache[size];

  // If feature = 1
  if (size === 1) {
    const status = feature.values_.features[0].values_.status;
    const ID = feature.values_.features[0].values_.id;
    let style = status !== "Upload" ? PinStyle(status) : null;

    // If the status equal to "Upload", we add text to the polygon
    if (status === "Upload") {
      const geometry = feature.values_.features[0].getGeometry();
      const type = geometry.getType();
      if (type === "Polygon") {
        const point = geometry.getInteriorPoint();
        const label = "ID: " + ID; 
        labelStyle.setGeometry(point);
        labelStyle.getText().setText(label);
        labelStyle.getText().setFill(new Fill({ color: "white" }));
        labelStyle.getText().setOffsetY(-10); 
        style = labelStyle; 
      }
    }
    return style;
  }

  if (!style && size > 1) {
    style = new Style({
      image: new CircleStyle({
        radius: 20,
        stroke: new Stroke({
          color: "#00FF7F",
          width: 5,
        }),
        fill: new Fill({
          color: "#0A0A29",
        }),
      }),
      text: new Text({
        text: size.toString(),
        scale: 1.5,
        fill: new Fill({
          color: "#fff",
        }),
      }),
    });
    
    styleCache[size] = style;
  }
  return style;
};

