// Sending a request with selected position
export const getTermsAndConds = async ( service ) => {
  await fetch(`https://graphql.contentful.com/content/v1/spaces/2bhkoex608qi/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`,
    },
    body: JSON.stringify({ query }),
  })
    .then((response) => response.json())
    .then((response) => {
      const allTermsAndConds = response.data.envimapTermsAndConsCollection.items;
      const currentDate = new Date();

      console.log(allTermsAndConds);

      // Filter out items with a date later than the current date
      const serviceFilteredItems = allTermsAndConds.filter(item => item.service[0] === service);

      console.log(serviceFilteredItems);

      // Filter out items with a date later than the current date
      const timeFilteredItems = serviceFilteredItems.filter(item => new Date(item.activeFrom) <= currentDate);

      // Select the item with the later date
      const latestItem = timeFilteredItems.reduce((prev, current) => {
        const prevDate = new Date(prev.activeFrom);
        const currentDate = new Date(current.activeFrom);
        
        return prevDate > currentDate ? prev : current;
      });

      if (latestItem.pdf.url) {
        window.location.href = latestItem.pdf.url;
      }
    })
    .catch((error) => console.log(error));
};


const query = `{
  envimapTermsAndConsCollection {
    items {
      service
      version
    	activeFrom
      pdf {
				url
      }
    }
  }
}`;