const AccordionContentList = ({ contentItem }) => (
  <ul>
    {contentItem.map((contentListItem, i) => (
      <li key={i}>
        <span /> {contentListItem}
      </li>
    ))}
  </ul>
);

export const AccordionContent = ({ content }) => (
  <>
    {content.map((contentItem, i) =>
      Array.isArray(contentItem) ? (
        <AccordionContentList key={i} contentItem={contentItem} />
      ) : (
        <p key={i}>{contentItem}</p>
      )
    )}
  </>
);
