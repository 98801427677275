import { G, Path, Svg } from "@react-pdf/renderer";

// Set default icon color
//
// To add a new color, create a new case with the value and
// name of the color.

export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="24"
      fill="none"
      viewBox="0 0 104 24"
    >
      <path
        fill="#00FF7F"
        d="M67.391 20.808l.474-.963a1.868 1.868 0 00.066.963h-.54zm.982-1.993l7.006-14.22a2.75 2.75 0 01.978-1.104 2.859 2.859 0 011.562-.453v-.006c.57 0 1.1.159 1.557.453v.005c.417.27.755.642.982 1.099h.002l7.078 14.333c-.792-1.072-2.552-1.963-4.846-2.487L77.919 6.77l-4.76 9.66c-2.228.506-3.956 1.358-4.786 2.386zm19.564.913l.533 1.08h-.572a1.88 1.88 0 00.039-1.08z"
      ></path>
      <path
        fill="#0A0A29"
        d="M48.542 20.762l.056-15.386a2.22 2.22 0 01.546-1.448 2.624 2.624 0 011.41-.833v-.002a2.814 2.814 0 011.672.128c.514.207.952.565 1.233 1.043l4.162 7.048 4.183-7.07c.283-.48.72-.837 1.234-1.043a2.81 2.81 0 011.673-.127 2.613 2.613 0 011.41.837h.006c.345.404.544.908.544 1.45h-.006l-.004 15.483h-3.293l.003-12.916-3.473 5.871a2.454 2.454 0 01-.95.908c-.394.214-.847.33-1.328.33v.005c-.475 0-.928-.118-1.328-.336a2.455 2.455 0 01-.95-.907l-3.46-5.861-.046 12.826h-3.294z"
      ></path>
      <path
        fill="#0A0A29"
        fillRule="evenodd"
        d="M89.034 20.762l.029-15.164c0-.354.07-.683.21-.987.141-.308.348-.587.62-.832.27-.244.575-.43.916-.557.34-.127.702-.19 1.084-.189l4.662.02v-.005h.005c2.054 0 3.913.76 5.259 1.986C103.167 6.26 104 7.956 104 9.828c0 1.861-.828 3.55-2.168 4.778-1.337 1.225-3.187 1.986-5.236 1.998h-4.262l-.008 4.158h-3.292zm3.321-14.728l-.015 7.57h4.244a4.335 4.335 0 002.91-1.115c.743-.68 1.201-1.62 1.201-2.66 0-1.042-.464-1.985-1.213-2.667-.748-.682-1.784-1.104-2.927-1.104v-.442l-.002.436-4.198-.018z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#0A0A29"
        d="M68.51 18.536c.423-.233.886-.448 1.383-.643 2.099-.82 4.897-1.32 7.932-1.32 2.87 0 5.546.451 7.614 1.204h.006c.736.267 1.403.579 1.987.928l1.038 2.102H84.85l-.008-.017v.021a11.557 11.557 0 00-.624-.247v-.002c-1.683-.612-3.927-.98-6.395-.98-2.594 0-4.934.408-6.64 1.075-.126.05-.248.1-.366.15h-3.427l1.12-2.27z"
      ></path>
      <path
        fill="#0A0A29"
        fillRule="evenodd"
        d="M8.428 9.324c1.487 0 2.563.307 3.228.92.665.612.997 1.473.997 2.583 0 1.052-.325 1.863-.973 2.432-.65.57-1.741.854-3.276.854H2.445v.822c0 .461.146.84.44 1.135.292.296.787.443 1.483.443h7.668v2.227H4.368c-.791 0-1.46-.093-2.006-.28-.546-.188-.997-.451-1.353-.79a2.97 2.97 0 01-.772-1.19A4.537 4.537 0 010 16.979v-3.892c0-.533.08-1.03.237-1.492.158-.46.416-.86.772-1.2.356-.338.807-.601 1.353-.789.546-.187 1.215-.28 2.006-.28h4.06zM2.445 14.32h5.888c.68 0 1.159-.123 1.436-.368.277-.245.415-.576.415-.994 0-.433-.142-.779-.427-1.038-.285-.26-.736-.39-1.353-.39H4.368c-.696 0-1.19.152-1.483.455-.293.303-.44.677-.44 1.124v1.21z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#0A0A29"
        d="M21.128 9.324c2.137 0 3.723.436 4.76 1.308 1.036.873 1.554 2.101 1.554 3.687v6.421h-2.54V14.32c0-.908-.297-1.6-.89-2.076s-1.555-.713-2.884-.713H17.02c-.206 0-.309.108-.309.324v8.886h-2.516v-9.643c0-.677.135-1.142.404-1.394.269-.253.72-.379 1.353-.379h5.175zM30.267 9.324l4.178 9.168c.095.23.301.346.618.346.332 0 .554-.116.664-.346l4.345-9.168h2.706l-4.843 10.184c-.27.548-.637.933-1.104 1.157-.467.223-1.056.335-1.768.335-.728 0-1.326-.115-1.793-.346-.467-.23-.819-.612-1.056-1.146L27.61 9.324h2.658zM46.006 9.324V20.74H43.49V9.324h2.516zM46.3 6.066c0 .776-.69 1.405-1.542 1.405-.852 0-1.543-.629-1.543-1.405s.69-1.405 1.543-1.405c.852 0 1.542.63 1.542 1.405z"
      ></path>
    </svg>
  );
};

export const PDFLogo = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="32"
      fill="none"
      viewBox="0 0 140 32"
    >
      <G>
        <Path
          fill="#00FF7F"
          d="M89.8545 27.744L90.4868 26.4601C90.4562 26.6174 90.4398 26.7766 90.4398 26.9376C90.4398 27.2116 90.4864 27.4804 90.5744 27.7443H89.8545V27.744ZM91.1634 25.0871L100.506 6.12606C100.809 5.50986 101.258 5.00996 101.809 4.65433C102.416 4.26254 103.127 4.05117 103.892 4.0508V4.04297C104.652 4.04297 105.36 4.25434 105.968 4.64613V4.65396C106.524 5.01257 106.975 5.50986 107.277 6.11861L107.28 6.11749L116.718 25.2292C115.661 23.7999 113.314 22.6126 110.256 21.9133L103.892 9.02629L97.5459 21.9066C94.5745 22.5817 92.2706 23.7168 91.1634 25.0879V25.0871ZM117.249 26.3046L117.96 27.744H117.197C117.285 27.4804 117.332 27.2112 117.332 26.9373C117.332 26.7233 117.303 26.5123 117.249 26.3046H117.249Z"
        />
        <Path
          fill="#0A0A29"
          d="M65.7233 27.6829L65.7974 7.16844C65.7998 6.44263 66.0663 5.77163 66.5255 5.23781C66.9884 4.70026 67.6388 4.30213 68.4058 4.12618L68.4054 4.12432C69.1749 3.94948 69.9529 4.02031 70.6352 4.29468C71.3204 4.57053 71.9036 5.04844 72.2794 5.68515L77.8277 15.0826L83.4048 5.6557C83.7825 5.01712 84.3658 4.53997 85.0501 4.26523C85.7357 3.99049 86.5146 3.9204 87.2812 4.09636C88.0482 4.27268 88.6986 4.6723 89.1603 5.21209H89.1688C89.6297 5.75113 89.8949 6.42288 89.8949 7.14458H89.8863L89.8814 27.7899H85.4909L85.495 10.5686L80.8639 18.3966C80.5614 18.9084 80.1255 19.3192 79.5975 19.6062C79.0716 19.8922 78.4679 20.0457 77.8265 20.0457V20.0536C77.1933 20.0536 76.5896 19.8963 76.0559 19.6059C75.5271 19.3177 75.0912 18.9065 74.79 18.3962L70.176 10.5812L70.1142 27.6833L65.7233 27.6829Z"
        />
        <Path
          fill="#0A0A29"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.712 27.6829L119.75 7.46368C119.751 6.99249 119.845 6.55298 120.03 6.14851C120.219 5.73696 120.495 5.36567 120.857 5.038C121.216 4.71294 121.625 4.46504 122.079 4.29579C122.532 4.12655 123.015 4.04268 123.523 4.04454L129.74 4.07138V4.06355H129.746C132.485 4.06504 134.964 5.07714 136.759 6.71178C138.555 8.34791 139.667 10.6085 139.667 13.1046C139.667 15.5854 138.563 17.8374 136.775 19.475C134.994 21.1074 132.527 22.1233 129.795 22.1382H124.112L124.102 27.6833L119.712 27.6829ZM124.14 8.04559L124.12 18.139H129.778C131.293 18.1308 132.665 17.5638 133.659 16.652C134.649 15.7457 135.259 14.4921 135.259 13.1042C135.259 11.716 134.641 10.4593 133.643 9.54976C132.645 8.64017 131.265 8.07765 129.74 8.07765V7.48828L129.738 8.06982L124.14 8.04559Z"
        />
        <Path
          fill="#0A0A29"
          d="M92.347 24.7151C92.9114 24.4038 93.5282 24.1175 94.1912 23.858C96.9888 22.7643 100.72 22.0963 104.766 22.0963C108.593 22.0963 112.162 22.6987 114.919 23.7022H114.927C115.908 24.0593 116.797 24.4742 117.575 24.9402L118.96 27.7432H114.135L114.124 27.7204V27.7484C113.863 27.6354 113.585 27.5255 113.291 27.4185L113.293 27.4159C111.048 26.6002 108.056 26.1104 104.766 26.1104C101.306 26.1104 98.1868 26.6535 95.9123 27.5426C95.7437 27.6086 95.5812 27.6753 95.4244 27.7432H90.8543L92.347 24.7151Z"
        />
        <Path
          fill="#0A0A29"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2367 12.4323C14.2202 12.4323 15.6552 12.8409 16.5413 13.6577C17.4274 14.4744 17.8707 15.623 17.8707 17.1025C17.8707 18.5057 17.438 19.5867 16.5728 20.3457C15.7076 21.1047 14.2517 21.4846 12.2048 21.4846H4.26002V22.5802C4.26002 23.1952 4.45526 23.6996 4.84572 24.0936C5.23619 24.4877 5.89556 24.6845 6.82384 24.6845H17.0476V27.6537H6.82384C5.76868 27.6537 4.87724 27.5288 4.1491 27.279C3.42097 27.0293 2.81972 26.6785 2.34494 26.2267C1.87016 25.7749 1.52717 25.2466 1.31638 24.6413C1.10519 24.0359 1 23.3678 1 22.6376V17.4485C1 16.7372 1.1056 16.0744 1.31638 15.4593C1.52717 14.8442 1.87016 14.3111 2.34494 13.8593C2.81972 13.4075 3.42097 13.0571 4.1491 12.807C4.87724 12.5572 5.76868 12.4323 6.82384 12.4323H12.2367ZM4.26043 19.0917H12.1103C13.0177 19.0917 13.6558 18.9284 14.0254 18.6015C14.3945 18.2749 14.5791 17.8328 14.5791 17.2755C14.5791 16.6988 14.3892 16.2377 14.0094 15.8917C13.6296 15.5458 13.0283 15.3728 12.2052 15.3728H6.82425C5.89597 15.3728 5.23619 15.5745 4.84613 15.9782C4.45567 16.3819 4.26043 16.8815 4.26043 17.4772V19.0917Z"
        />
        <Path
          fill="#0A0A29"
          d="M29.1709 12.4323C32.0195 12.4323 34.1352 13.0139 35.517 14.1766C36.8991 15.3393 37.59 16.9776 37.59 19.0917V27.6537H34.2031V19.0917C34.2031 17.8809 33.8073 16.9583 33.0162 16.3242C32.225 15.6901 30.9431 15.3728 29.1705 15.3728H23.6945C23.4203 15.3728 23.2832 15.5171 23.2832 15.8052V27.6537H19.9282V14.7961C19.9282 13.8929 20.1075 13.273 20.4664 12.9367C20.825 12.6005 21.4266 12.4323 22.2706 12.4323H29.1709Z"
        />
        <Path
          fill="#0A0A29"
          d="M41.3563 12.4323L46.9272 24.6558C47.0537 24.9633 47.3283 25.1173 47.7503 25.1173C48.1936 25.1173 48.4891 24.9633 48.6365 24.6558L54.4288 12.4323H58.0371L51.5801 26.0108C51.2211 26.7411 50.7308 27.2552 50.1083 27.553C49.4857 27.8509 48.6999 28 47.7503 28C46.7799 28 45.983 27.8464 45.3605 27.5389C44.7379 27.2313 44.2685 26.7221 43.9521 26.0108L37.8115 12.4323H41.3563Z"
        />
        <Path
          fill="#0A0A29"
          d="M62.3412 12.4323V27.6537H58.9863V12.4323H62.3412Z"
        />
        <Path
          fill="#0A0A29"
          d="M62.734 8.08808C62.734 9.12263 61.8131 9.96129 60.6771 9.96129C59.5411 9.96129 58.6202 9.12263 58.6202 8.08808C58.6202 7.05352 59.5411 6.21486 60.6771 6.21486C61.8131 6.21486 62.734 7.05352 62.734 8.08808Z"
        />
      </G>
    </Svg>
  );
};

export const PDFFooterLogo = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="12"
      fill="none"
      viewBox="0 0 52 12"
    >
      <G>
        <Path
          d="M33.6956 10.404L33.9327 9.92254C33.9212 9.98153 33.915 10.0412 33.915 10.1016C33.915 10.2044 33.9325 10.3051 33.9655 10.4041L33.6956 10.404ZM34.1864 9.40768L37.6897 2.29727C37.8036 2.0662 37.9718 1.87874 38.1784 1.74537C38.406 1.59845 38.6726 1.51919 38.9598 1.51905V1.51611C39.2447 1.51611 39.51 1.59538 39.7381 1.7423V1.74523C39.9466 1.87971 40.1157 2.0662 40.229 2.29448L40.2302 2.29406L43.7693 9.46094C43.373 8.92498 42.4929 8.47974 41.346 8.21749L38.9596 3.38486L36.5799 8.21497C35.4655 8.46813 34.6016 8.89352 34.1864 9.40768ZM43.9685 9.86424L44.2351 10.404H43.9491C43.9821 10.3051 43.9996 10.2042 43.9996 10.1015C43.9996 10.0212 43.9887 9.94211 43.9685 9.86424Z"
          fill="#00FF7F"
        />
        <Path
          d="M24.2712 10.3811L24.299 2.68816C24.2999 2.41599 24.3999 2.16436 24.5721 1.96418C24.7457 1.7626 24.9895 1.6133 25.2772 1.54732C25.5657 1.48175 25.8573 1.50762 26.1132 1.6105C26.3701 1.71395 26.5889 1.89316 26.7298 2.13193L28.8104 5.65596L30.9018 2.12089C31.0435 1.88142 31.2622 1.70249 31.5188 1.59946C31.7759 1.49643 32.068 1.47015 32.3554 1.53613C32.6431 1.60226 32.887 1.75211 33.0601 1.95453H33.0633C33.2361 2.15667 33.3356 2.40858 33.3356 2.67922H33.3324L33.3305 10.4212H31.6841L31.6856 3.96321L29.949 6.89871C29.8355 7.09065 29.6721 7.2447 29.4741 7.35234C29.2768 7.45956 29.0505 7.51716 28.8099 7.51716V7.52009C28.5725 7.52009 28.3461 7.4611 28.146 7.3522C27.9477 7.24414 27.7842 7.08995 27.6712 6.89857L25.941 3.96796L25.9178 10.3812L24.2712 10.3811Z"
          fill="#0A0A29"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.5168 10.3811L44.5314 2.79888C44.5317 2.62218 44.5669 2.45737 44.6362 2.30569C44.707 2.15136 44.8105 2.01213 44.9463 1.88925C45.0811 1.76735 45.2342 1.67439 45.4044 1.61092C45.5745 1.54746 45.7556 1.516 45.9462 1.5167L48.2775 1.52677V1.52383H48.2798C49.3068 1.52439 50.2366 1.90393 50.9096 2.51692C51.5833 3.13047 52 3.97817 52 4.91422C52 5.84454 51.5861 6.68903 50.9158 7.30313C50.2477 7.91529 49.3226 8.29622 48.2981 8.30181H46.1671L46.1631 10.3812L44.5168 10.3811ZM46.1775 3.0171L46.1702 6.80212H48.2918C48.8599 6.79904 49.3743 6.58642 49.7473 6.24449C50.1183 5.90465 50.3473 5.43453 50.3473 4.91408C50.3473 4.39349 50.1155 3.92225 49.7412 3.58116C49.3668 3.24006 48.8493 3.02912 48.2777 3.02912V2.80811L48.2768 3.02618L46.1775 3.0171Z"
          fill="#0A0A29"
        />
        <Path
          d="M34.2551 9.26815C34.4668 9.15142 34.6981 9.04406 34.9467 8.94677C35.9958 8.53661 37.3951 8.28611 38.9123 8.28611C40.3474 8.28611 41.6856 8.51201 42.7195 8.88833H42.7227C43.0906 9.02225 43.424 9.17784 43.7158 9.35258L44.2349 10.4037H42.4256L42.4214 10.3952V10.4056C42.3235 10.3633 42.2193 10.322 42.1092 10.2819L42.1097 10.2809C41.2678 9.97508 40.1459 9.79139 38.9122 9.79139C37.6147 9.79139 36.445 9.99507 35.5921 10.3285C35.5289 10.3532 35.468 10.3782 35.4092 10.4037H33.6954L34.2551 9.26815Z"
          fill="#0A0A29"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.21378 4.66213C4.95757 4.66213 5.49569 4.81534 5.82798 5.12162C6.16028 5.42791 6.3265 5.85861 6.3265 6.41345C6.3265 6.93963 6.16427 7.34502 5.8398 7.62964C5.51533 7.91426 4.96939 8.05671 4.20181 8.05671H1.22251V8.46756C1.22251 8.69822 1.29572 8.88735 1.44215 9.03512C1.58857 9.18288 1.83584 9.25669 2.18394 9.25669H6.01784V10.3701H2.18394C1.78825 10.3701 1.45396 10.3233 1.18091 10.2296C0.907864 10.136 0.682395 10.0044 0.504352 9.83501C0.326309 9.66558 0.197689 9.46749 0.118644 9.24047C0.0394457 9.01345 0 8.76294 0 8.48909V6.54317C0 6.27645 0.0395992 6.0279 0.118644 5.79724C0.197689 5.56658 0.326309 5.36668 0.504352 5.19725C0.682395 5.02782 0.907864 4.89642 1.18091 4.80262C1.45396 4.70896 1.78825 4.66213 2.18394 4.66213H4.21378ZM1.22266 7.15938H4.16635C4.50663 7.15938 4.74591 7.09815 4.88451 6.97555C5.02295 6.85309 5.09218 6.6873 5.09218 6.47831C5.09218 6.26205 5.02096 6.08913 4.87852 5.9594C4.73609 5.82967 4.51062 5.76481 4.20196 5.76481H2.18409C1.83599 5.76481 1.58857 5.84044 1.4423 5.99183C1.29587 6.14323 1.22266 6.33055 1.22266 6.55394V7.15938Z"
          fill="#0A0A29"
        />
        <Path
          d="M10.5641 4.66213C11.6323 4.66213 12.4257 4.8802 12.9439 5.31622C13.4622 5.75223 13.7213 6.36662 13.7213 7.15938V10.3701H12.4512V7.15938C12.4512 6.70533 12.3028 6.35935 12.0061 6.12156C11.7094 5.88377 11.2287 5.76481 10.5639 5.76481H8.51044C8.40761 5.76481 8.35619 5.81891 8.35619 5.92697V10.3701H7.09807V5.54855C7.09807 5.20983 7.1653 4.97736 7.29991 4.85127C7.43436 4.72517 7.65998 4.66213 7.97647 4.66213H10.5641Z"
          fill="#0A0A29"
        />
        <Path
          d="M15.1336 4.66213L17.2227 9.24592C17.2701 9.36125 17.3731 9.41898 17.5314 9.41898C17.6976 9.41898 17.8084 9.36125 17.8637 9.24592L20.0358 4.66213H21.3889L18.9675 9.75407C18.8329 10.0279 18.6491 10.2207 18.4156 10.3324C18.1822 10.4441 17.8875 10.5 17.5314 10.5C17.1675 10.5 16.8686 10.4424 16.6352 10.3271C16.4017 10.2117 16.2257 10.0208 16.107 9.75407L13.8043 4.66213H15.1336Z"
          fill="#0A0A29"
        />
        <Path
          d="M23.003 4.66213V10.3701H21.7448V4.66213H23.003Z"
          fill="#0A0A29"
        />
        <Path
          d="M23.1503 3.03303C23.1503 3.42098 22.8049 3.73549 22.3789 3.73549C21.9529 3.73549 21.6076 3.42098 21.6076 3.03303C21.6076 2.64507 21.9529 2.33057 22.3789 2.33057C22.8049 2.33057 23.1503 2.64507 23.1503 3.03303Z"
          fill="#0A0A29"
        />
      </G>
    </Svg>
  );
};

export const PDFLogoTerran = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="135"
      height="36"
      viewBox="0 0 135 36"
    >
      <G>
        <Path
          fill="#BC1D1D"
          fillRule="evenodd"
          d="M92.762 34.52a2.01 2.01 0 01-1.407.578 2.01 2.01 0 01-1.406-.578 2.32 2.32 0 01-.511-1.57v-2.231c0-1.336.71-2.153 1.914-2.153a1.836 1.836 0 011.422.578 2.31 2.31 0 01.503 1.575v2.23a2.32 2.32 0 01-.504 1.57zm-2.028-.754c.082.086.18.152.286.195a.818.818 0 00.668 0 .778.778 0 00.28-.195c.16-.239.239-.524.22-.813v-2.23c.019-.29-.06-.578-.22-.82a.71.71 0 00-.277-.204.72.72 0 00-.34-.058.761.761 0 00-.335.054.775.775 0 00-.282.207c-.16.243-.234.532-.214.82v2.231c-.02.29.058.578.214.817zm0 0"
        />
        <Path
          fill="#BC1D1D"
          d="M92.965 27l-.93 1.285h-.808L92.004 27zm0 0M90.91 27l-.71 1.285h.812L91.89 27zm0 0"
        />
        <Path
          fill="#BC1D1D"
          fillRule="evenodd"
          d="M79.926 34.52a2.017 2.017 0 01-1.406.578 2.01 2.01 0 01-1.407-.578c-.355-.438-.535-1-.508-1.57v-2.231a2.314 2.314 0 01.508-1.574 2.01 2.01 0 011.407-.579 2.01 2.01 0 011.406.579c.355.44.539 1.003.508 1.574v2.23a2.317 2.317 0 01-.508 1.57zm-2.028-.754a.81.81 0 00.282.207c.11.047.222.066.34.058a.73.73 0 00.613-.266c.164-.238.238-.527.219-.816v-2.23c.02-.29-.059-.578-.22-.82a.887.887 0 00-.28-.196.888.888 0 00-.668 0 .95.95 0 00-.286.195 1.323 1.323 0 00-.21.82v2.231c-.02.29.054.578.21.817zm0 0"
        />
        <Path
          fill="#BC1D1D"
          d="M79.52 28.027a.524.524 0 01-.38.145.532.532 0 01-.386-.145.59.59 0 01-.113-.175.646.646 0 01-.036-.211.49.49 0 01.036-.211.484.484 0 01.113-.18.549.549 0 01.387-.145c.07-.003.14.008.207.032a.507.507 0 01.316.504.534.534 0 01-.031.207.54.54 0 01-.113.18zm0 0M78.277 28.027a.566.566 0 01-.754 0 .53.53 0 01-.109-.18.473.473 0 01-.035-.206.541.541 0 01.633-.527c.101.019.195.066.27.136a.546.546 0 01.109.184.623.623 0 010 .414.54.54 0 01-.114.18zm0 0"
        />
        <Path
          fill="#BC1D1D"
          fillRule="evenodd"
          d="M24.422 35.094a2.02 2.02 0 001.406-.574c.356-.438.535-1 .508-1.57v-2.231a2.334 2.334 0 00-.508-1.574 2.01 2.01 0 00-1.406-.579c-.527 0-1.031.207-1.41.579a2.334 2.334 0 00-.508 1.574v2.23c-.027.57.152 1.133.508 1.57.383.372.886.575 1.41.575zm-.34-1.121a.775.775 0 01-.281-.207 1.305 1.305 0 01-.211-.817v-2.23c-.024-.29.05-.578.21-.82a.904.904 0 01.286-.196.883.883 0 01.668 0c.105.047.2.113.281.195a1.3 1.3 0 01.215.82v2.231c.023.29-.055.574-.215.813a.702.702 0 01-.277.21.658.658 0 01-.336.055.77.77 0 01-.34-.054zm0 0"
        />
        <Path
          fill="#BC1D1D"
          d="M32.344 28.684h-3.547v1.039h1.234v5.261h1.074v-5.261h1.239zm0 0M38.195 28.684v1.039h-1.238v5.261h-1.074v-5.261h-1.23v-1.04zm0 0M43.41 28.684v2.593h-1.48v-2.593H40.87v6.3h1.059V32.31h1.48v2.675h1.055v-6.3zm0 0"
        />
        <Path
          fill="#BC1D1D"
          fillRule="evenodd"
          d="M50.79 34.52a2.006 2.006 0 01-2.813 0 2.32 2.32 0 01-.512-1.57v-2.231a2.337 2.337 0 01.512-1.574 2.01 2.01 0 011.406-.579 2.01 2.01 0 011.406.579c.356.44.54 1.003.512 1.574v2.23a2.32 2.32 0 01-.512 1.57zm-2.02-.754c.074.09.171.16.277.207a.77.77 0 00.34.054.665.665 0 00.336-.054.743.743 0 00.28-.211c.161-.239.24-.524.216-.813v-2.23c.02-.29-.055-.578-.215-.82a.95.95 0 00-.285-.196.883.883 0 00-.668 0 .887.887 0 00-.281.195c-.16.243-.235.532-.215.82v2.231c-.02.29.054.578.215.817zm0 0"
        />
        <Path
          fill="#BC1D1D"
          d="M58.031 28.684h-1.047l.063 3.953-1.715-3.953h-1.023v6.3h1.05l-.058-3.964 1.707 3.964h1.023zm0 0"
        />
        <Path
          fill="#BC1D1D"
          fillRule="evenodd"
          d="M66.082 34.984l-.281-1.226h-1.418l-.27 1.226H63l1.547-6.3h1.066l1.543 6.3zm-1.46-2.25h.952l-.48-2.246zm0 0"
        />
        <Path
          fill="#BC1D1D"
          d="M72.469 35.883c.25-.094.476-.238.668-.426l.008.012c.351-.434.53-.989.496-1.551v-5.223h-1.067v5.211a1.14 1.14 0 01-.222.813.859.859 0 01-.66.207h-.098v1.07h.097a1.95 1.95 0 00.778-.113zm0 0M84.387 34.984h1.011l.094.012 1.512-6.3h-1.098c-.355 1.511-.71 3.038-.972 4.59l-.95-4.59H82.88zm0 0"
        />
        <Path
          fill="#BC1D1D"
          fillRule="evenodd"
          d="M99.133 31.727c.148-.13.27-.29.351-.47v.013c.118-.258.176-.54.172-.82.02-.473-.144-.93-.457-1.278a1.632 1.632 0 00-1.219-.477h-1.714v6.29h1.796c.243.011.485-.028.711-.114.227-.086.434-.219.61-.387a1.837 1.837 0 00.48-1.343 1.782 1.782 0 00-.234-.914 1.415 1.415 0 00-.496-.5zm-1.168 2.222h-.63v-1.656h.599a.92.92 0 01.66.223.81.81 0 01.176.289c.039.105.054.222.046.336a.855.855 0 01-.207.613.918.918 0 01-.644.195zm.207-4.203a.708.708 0 01.25.149v-.012c.062.078.11.168.14.265.028.098.04.2.032.301a.932.932 0 01-.172.606.714.714 0 01-.234.152.671.671 0 01-.278.035h-.578v-1.527h.555a.638.638 0 01.285.031zm0 0"
        />
        <Path
          fill="#BC1D1D"
          d="M103.738 32.293v1.66h2.137v1.031h-3.191v-6.3h3.105v1.03h-2.05v1.563h1.89v1.016zm0 0M112.5 28.684h-1.047l.063 3.953-1.715-3.953h-1.02v6.3h1.047l-.055-3.964 1.704 3.964h1.023zm0 0"
        />
        <Path
          fill="#E29000"
          fillRule="evenodd"
          d="M101.332 5.297h4.43l6.64 15.848h-6.156L100.703 6.89zm0 0"
        />
        <Path
          fill="#BC1D1D"
          fillRule="evenodd"
          d="M88.969 21.145h6.086L103.43 0h-5.547zm0 0"
        />
        <Path
          fill="#666"
          fillRule="evenodd"
          d="M52.387 5.293h-7.489v15.848h4.41V16.34h1.922l3.192 4.8h5.062l-3.78-5.527c1.991-.836 3.26-2.418 3.26-4.863v-.05c0-1.571-.484-2.782-1.413-3.688-1.063-1.09-2.715-1.719-5.164-1.719zm2.156 5.727c0 1.156-.84 1.878-2.355 1.878h-2.899V9.094h2.875c1.473 0 2.379.61 2.379 1.879zm0 0M67.867 5.293h7.496c2.446 0 4.098.629 5.16 1.719.93.906 1.415 2.117 1.415 3.687v.051c0 2.445-1.274 4.027-3.266 4.863l3.781 5.528H77.38l-3.2-4.797h-1.925v4.8h-4.387zm7.293 7.605c1.516 0 2.352-.722 2.352-1.878v-.047c0-1.27-.903-1.88-2.38-1.88h-2.87v3.805zm0 0"
        />
        <Path
          fill="#666"
          d="M119.355 5.297h4.098l6.52 8.371V5.297h4.347v15.848h-3.847l-6.774-8.696v8.696h-4.344zm0 0M23.012 5.297h12.746V9.03h-8.399v2.399h7.606v3.465h-7.606v2.511h8.516v3.739H23.012zm0 0M.45 9.145h4.757v12h4.39v-12h4.755V5.297H.449zm0 0"
        />
      </G>
    </Svg>
  );
};

// Set default icon color
//
// To add a new color, create a new case with the value and
// name of the color.

let fillColor;
export const ColorSwitch = (propColor) => {
  switch (propColor) {
    case "green":
      fillColor = "#00FF7F";
      break;
    case "white":
      fillColor = "#FFF";
      break;
    case "red":
      fillColor = "#FF0055";
      break;
    case "darkGray":
      fillColor = "#A1A1AA";
      break;
    default:
      fillColor = "#0A0A29";
      break;
  }
  return fillColor;
};

// Icons
//
// To add an icon, create an arrow function with the name of the icon,
// give it the input value "props", call the ColorSwitch function at
// the beginning with the value "props.color" and return the svg code.

export const Add = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M21 11.001h-8v-8h-2v8H3v2h8v8h2v-8h8v-2z"
      ></path>
    </svg>
  );
};

export const ArrowBackward = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
      ></path>
    </svg>
  );
};

export const ArrowDown = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"
      ></path>
    </svg>
  );
};

export const ArrowDropDown = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill={fillColor} d="M6.667 10.666L12 16l5.333-5.334H6.667z"></path>
    </svg>
  );
};

export const ArrowRight = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12 4l-1.413 1.413L16.173 11H4v2h12.173l-5.586 5.587L12 20l8-8-8-8z"
      ></path>
    </svg>
  );
};

export const ArrowUp = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g>
        <path
          fill={fillColor}
          d="M8 5.333l-4 4 .94.94L8 7.22l3.06 3.053.94-.94-4-4z"
        ></path>
      </g>
    </svg>
  );
};

export const AutoRotate = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M3 11C3 10.2021 3.67281 9.21056 5.37615 8.35889C7.01967 7.53713 9.36016 7 12 7C14.6398 7 16.9803 7.53713 18.6239 8.35889C20.3272 9.21056 21 10.2021 21 11C21 11.6483 20.568 12.4213 19.446 13.168C18.9862 13.474 18.8615 14.0947 19.1675 14.5545C19.4735 15.0143 20.0942 15.139 20.554 14.833C21.9436 13.9082 23 12.6034 23 11C23 9.03646 21.4342 7.52801 19.5183 6.57004C17.5425 5.58216 14.883 5 12 5C9.11699 5 6.45748 5.58216 4.48172 6.57004C2.56577 7.52801 1 9.03646 1 11C1 12.9635 2.56577 14.472 4.48172 15.43C5.99067 16.1844 7.89844 16.7023 10 16.9048V21L17 16L10 11V14.8946C8.18517 14.7 6.59043 14.2483 5.37615 13.6411C3.67281 12.7894 3 11.7979 3 11Z"
      />
    </svg>
  );
};

export const Bin = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M15 3v2h6v2H3V5h6V3h6zM11 12H9v6h2v-6zM13 12h2v6h-2v-6z"
      ></path>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M7 22c-1.103 0-2-.897-2-2V8h14v12c0 1.103-.897 2-2 2H7zm10-12H7v10h10.001L17 10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Close = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M20.749 4.707l-1.415-1.414-7.292 7.293-7.293-7.293-1.415 1.414L10.627 12l-7.293 7.293 1.415 1.414 7.293-7.293 7.292 7.293 1.415-1.414L13.456 12l7.293-7.293z"
      ></path>
    </svg>
  );
};

export const CheckboxDone = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={fillColor}
      viewBox="0 0 16 16"
    >
      <g>
        <path
          fill="#fff"
          stroke="#fff"
          d="M6 10.093L3.554 7.646 3.2 7.293l-.354.353-.933.934-.353.353.353.354 3.733 3.733.354.354.354-.354 8-8 .353-.353-.354-.354-.933-.933-.353-.354-.354.354L6 10.093z"
        ></path>
      </g>
    </svg>
  );
};

export const Copy = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"
      />
    </svg>
  );
};

export const Convert = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4V4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z"
      />
    </svg>
  );
};

export const Cursor = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      {props.multi ? (
        <>
          <path
            fill={fillColor}
            d="M10.4207 21.9996C9.99753 21.9996 9.61017 21.7438 9.44702 21.348L2.07876 3.4537C1.91771 3.06213 2.00824 2.60951 2.30823 2.30846C2.60823 2.00742 3.06085 1.91794 3.45347 2.07899L21.3478 9.44725C21.7678 9.62093 22.0299 10.0441 21.9973 10.4978C21.9636 10.9514 21.6436 11.3314 21.2015 11.443L13.3932 13.3945L11.4417 21.2028C11.3312 21.6428 10.9501 21.9638 10.4965 21.9964C10.4723 21.9986 10.4449 21.9996 10.4207 21.9996V21.9996ZM4.98712 4.9884L10.1765 17.5881L11.5049 12.2725C11.5996 11.8956 11.8943 11.5998 12.2722 11.5062L17.5889 10.1767L4.98712 4.9884V4.9884Z"
          />
          <path
            fill={fillColor}
            d="M17 15C17 14.4477 17.4477 14 18 14C18.5523 14 19 14.4477 19 15V17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H19V21C19 21.5523 18.5523 22 18 22C17.4477 22 17 21.5523 17 21V19H15C14.4477 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17H17V15Z"
          />
        </>
      ) : (
        <path
          fill={fillColor}
          d="M10.4207 21.9996C9.99753 21.9996 9.61017 21.7438 9.44702 21.348L2.07876 3.4537C1.91771 3.06213 2.00824 2.60951 2.30823 2.30846C2.60823 2.00742 3.06085 1.91794 3.45347 2.07899L21.3478 9.44725C21.7678 9.62093 22.0299 10.0441 21.9973 10.4978C21.9636 10.9514 21.6436 11.3314 21.2015 11.443L13.3932 13.3945L11.4417 21.2028C11.3312 21.6428 10.9501 21.9638 10.4965 21.9964C10.4723 21.9986 10.4449 21.9996 10.4207 21.9996V21.9996ZM4.98712 4.9884L10.1765 17.5881L11.5049 12.2725C11.5996 11.8956 11.8943 11.5998 12.2722 11.5062L17.5889 10.1767L4.98712 4.9884V4.9884Z"
        />
      )}
    </svg>
  );
};

export const Discount = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={fillColor}
        d="M9.325 2.12a.5.5 0 01.055.705l-6 7a.5.5 0 01-.76-.65l6-7a.5.5 0 01.705-.055z"
      ></path>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M2.5 3.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM4 3a.5.5 0 100 1 .5.5 0 000-1zM8 7a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-.5 1.5a.5.5 0 111 0 .5.5 0 01-1 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Done = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
      ></path>
    </svg>
  );
};

export const Download = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M16.293 9.294l1.414 1.414L12 16.415l-5.707-5.707 1.414-1.414L11 12.587V2.001h2v10.586l3.293-3.293zM18 20.001v-2h2v2c0 1.102-.896 2-2 2H6c-1.104 0-2-.898-2-2v-2h2v2h12z"
      ></path>
    </svg>
  );
};

export const Edit = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M20.821 3.179A3.997 3.997 0 0017.974 2a3.997 3.997 0 00-2.846 1.179L3.719 14.587l-.003.004a1.274 1.274 0 00-.261.461L2.03 20.756a.997.997 0 00.263.949l.001.001.001.001a1 1 0 00.949.264l5.704-1.427a1.266 1.266 0 00.465-.264L20.822 8.873A3.996 3.996 0 0022 6.026a4.002 4.002 0 00-1.178-2.847h-.001zm-6.41 3.549l2.863 2.863-8.568 8.568-2.863-2.863 8.568-8.568zM4.375 19.626l.595-2.374 1.78 1.781-2.375.593zM19.409 4.594c.764.765.764 2.099 0 2.864l-.719.72-2.866-2.865.721-.719c.765-.764 2.099-.765 2.864 0z"
      ></path>
    </svg>
  );
};

export const Edit2 = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill={fillColor}
          d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
        ></path>
      </g>
    </svg>
  );
};

export const Expand = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      {props.expanded ? (
        <>
          <path
            fill={fillColor}
            d="M4 18.6L5.4 20L10 15.4V19H12V12H5V14H8.6L4 18.6Z"
          />
          <path
            fill={fillColor}
            d="M20 5.4L18.6 4L14 8.6V5H12V12H19V10H15.4L20 5.4Z"
          />
        </>
      ) : (
        <path
          fill={fillColor}
          d="M13 6H16.586L6 16.586V13H4V20H11V18H7.414L18 7.414V11H20V4H13V6Z"
        />
      )}
    </svg>
  );
};

export const Extent = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M9.293 10.707L5 6.414V9H3V3H9V5H6.414L10.707 9.293L9.293 10.707Z"
      />
      <path
        fill={fillColor}
        d="M6.414 19H9V21H3V15H5V17.586L9.293 13.293L10.707 14.707L6.414 19Z"
      />
      <path
        fill={fillColor}
        d="M15 21H21V15H19V17.586L14.707 13.293L13.293 14.707L17.586 19H15V21Z"
      />
      <path
        fill={fillColor}
        d="M15 3H21V9H19V6.414L14.707 10.707L13.293 9.293L17.586 5H15V3Z"
      />
    </svg>
  );
};

export const ExpandMore = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M13 6h3.586L6 16.586V13H4v7h7v-2H7.414L18 7.414V11h2V4h-7v2z"
      ></path>
    </svg>
  );
};

export const Features = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      {props.type === "side" && (
        <path
          fill={fillColor}
          d="M4.41421 11L12 3.41421L19.5858 11H19H18V12V21H6V12V11H5H4.41421Z"
        />
      )}
      {props.type === "edge" && (
        <path
          fill={fillColor}
          d="M12 2L22 12H19V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V12H2L12 2ZM6.82843 10H17.1716L12 4.82843L6.82843 10ZM7 12V20H17V12H7Z"
        />
      )}
      {props.type === "solar" && (
        <>
          <path fill={fillColor} d="M13 7H11V8H10V10H11V11H13V10H14V8H13V7Z" />
          <path
            fill={fillColor}
            d="M11 13H13V14H14V16H13V17H11V16H10V14H11V13Z"
          />
          <path
            fill={fillColor}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 2C5.44772 2 5 2.44772 5 3V21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V3C19 2.44772 18.5523 2 18 2H6ZM7 4V8H8V10H7V14H8V16H7V20H11V19H13V20H17V16H16V14H17V10H16V8H17V4H13V5H11V4H7Z"
          />
        </>
      )}
    </svg>
  );
};

export const GPS = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0013 3.06V1h-2v2.06A8.994 8.994 0 003.06 11H1v2h2.06A8.994 8.994 0 0011 20.94V23h2v-2.06A8.994 8.994 0 0020.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
      ></path>
    </svg>
  );
};

export const Hamburger = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill={fillColor} d="M1 5H23V7H1z"></path>
      <path fill={fillColor} d="M1 11H23V13H1z"></path>
      <path fill={fillColor} d="M1 17H23V19H1z"></path>
    </svg>
  );
};

export const HamburgerClose = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={props.clickEvent}
    >
      <path
        fill={fillColor}
        d="M4.929 3.515H26.929000000000002V5.515000000000001H4.929z"
        transform="rotate(45 4.929 3.515)"
      ></path>
      <path
        fill={fillColor}
        d="M3.515 19.071H25.515V21.071H3.515z"
        transform="rotate(-45 3.515 19.071)"
      ></path>
    </svg>
  );
};

export const Help = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12.0921 6.24099C9.96134 6.19083 8.21253 7.87612 8.16005 9.98245C8.15871 10.036 8.20268 10.0799 8.25626 10.0799H9.98558C10.0363 10.0799 10.0789 10.0404 10.0812 9.9898C10.1304 8.92669 11.0441 8.08933 12.1357 8.16455C13.0826 8.22988 13.8497 8.99692 13.9151 9.94372C13.9925 11.0657 13.1051 11.9999 11.9994 11.9999L11.1353 11.9999C11.0823 11.9999 11.0393 12.0429 11.0393 12.0959V13.8239C11.0393 13.8769 11.0823 13.9199 11.1353 13.9199H11.9994C14.1513 13.9199 15.8899 12.1504 15.8389 9.9873C15.791 7.95023 14.1294 6.28895 12.0921 6.24099Z"
      />
      <path
        fill={fillColor}
        d="M12 16.7999C12.5302 16.7999 12.96 16.3701 12.96 15.8399C12.96 15.3097 12.5302 14.8799 12 14.8799C11.4698 14.8799 11.04 15.3097 11.04 15.8399C11.04 16.3701 11.4698 16.7999 12 16.7999Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.3999C6.69811 2.3999 2.40002 6.69794 2.40002 11.9999C2.40002 17.3019 6.69811 21.5999 12 21.5999C17.302 21.5999 21.6 17.3019 21.6 11.9999C21.6 6.69794 17.302 2.3999 12 2.3999ZM12.0001 19.68C7.75853 19.68 4.32003 16.2415 4.32003 11.9999C4.32003 7.75836 7.75853 4.31991 12.0001 4.31991C16.2417 4.31991 19.6801 7.75836 19.6801 11.9999C19.6801 16.2415 16.2417 19.68 12.0001 19.68Z"
      />
    </svg>
  );
};

export const Home = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill={fillColor} d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z" />
    </svg>
  );
};

export const Information = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
      ></path>
    </svg>
  );
};
export const Mail = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
      ></path>
    </svg>
  );
};

export const LidarRes = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12 7.00098C13.1046 7.00098 14 6.10555 14 5.00098C14 3.89641 13.1046 3.00098 12 3.00098C10.8954 3.00098 10 3.89641 10 5.00098C10 6.10555 10.8954 7.00098 12 7.00098Z"
      />
      <path
        fill={fillColor}
        d="M18 10.5C19.1046 10.5 20 9.60457 20 8.5C20 7.39543 19.1046 6.5 18 6.5C16.8954 6.5 16 7.39543 16 8.5C16 9.60457 16.8954 10.5 18 10.5Z"
      />
      <path
        fill={fillColor}
        d="M8 8.5C8 9.60457 7.10457 10.5 6 10.5C4.89543 10.5 4 9.60457 4 8.5C4 7.39543 4.89543 6.5 6 6.5C7.10457 6.5 8 7.39543 8 8.5Z"
      />
      <path
        fill={fillColor}
        d="M12 14.001C13.1046 14.001 14 13.1055 14 12.001C14 10.8964 13.1046 10.001 12 10.001C10.8954 10.001 10 10.8964 10 12.001C10 13.1055 10.8954 14.001 12 14.001Z"
      />
      <path
        fill={fillColor}
        d="M20 15.5C20 16.6046 19.1046 17.5 18 17.5C16.8954 17.5 16 16.6046 16 15.5C16 14.3954 16.8954 13.5 18 13.5C19.1046 13.5 20 14.3954 20 15.5Z"
      />
      <path
        fill={fillColor}
        d="M6 17.5C7.10457 17.5 8 16.6046 8 15.5C8 14.3954 7.10457 13.5 6 13.5C4.89543 13.5 4 14.3954 4 15.5C4 16.6046 4.89543 17.5 6 17.5Z"
      />
      <path
        fill={fillColor}
        d="M14 19.001C14 20.1055 13.1046 21.001 12 21.001C10.8954 21.001 10 20.1055 10 19.001C10 17.8964 10.8954 17.001 12 17.001C13.1046 17.001 14 17.8964 14 19.001Z"
      />
    </svg>
  );
};

export const Upload = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M11 16V6.416L7.707 9.708 6.293 8.294 12 2.587l5.707 5.707-1.414 1.414L13 6.415V16h-2zM18 20.001v-2h2v2c0 1.102-.896 2-2 2H6c-1.104 0-2-.898-2-2v-2h2v2h12z"
      ></path>
    </svg>
  );
};

export const Password = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M10.219 15.73a1.446 1.446 0 11-2.892 0 1.446 1.446 0 012.892 0z"
      ></path>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M11.102 10.573L18.673 3l2.828 2.83-1.414 1.414-1.414-1.414-1.415 1.415 1.414 1.415-1.414 1.414-1.414-1.414-1.414 1.414 1.414 1.414-1.914 1.914.858.858c.378.378.586.88.586 1.414 0 .534-.208 1.036-.586 1.414l-3.949 3.949c-.378.378-.88.586-1.414.586a1.986 1.986 0 01-1.414-.586l-4.545-4.545a2.003 2.003 0 01-.001-2.828l3.951-3.949c.756-.756 2.072-.756 2.828 0l.858.858zM4.88 15.077l4.545 4.546 3.95-3.949-4.545-4.545-3.95 3.948z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Pin = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12 2C7.589 2 4 5.589 4 10C4 14.411 8.644 19.44 12 22.318C15.354 19.442 20 14.411 20 10C20 5.589 16.411 2 12 2ZM12 19.654C9.87 17.657 6 13.338 6 10C6 6.691 8.691 4 12 4C15.309 4 18 6.691 18 10C18 13.338 14.13 17.657 12 19.654Z"
      />
      <path
        fill={fillColor}
        d="M14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10Z"
      />
    </svg>
  );
};

export const PinDenied = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M21.456 5.414l-2.829-2.828-6.585 6.586-6.586-6.586-2.83 2.828L9.214 12l-6.586 6.586 2.829 2.828 6.586-6.586 6.585 6.586 2.829-2.828L14.87 12l6.586-6.586z"
      ></path>
    </svg>
  );
};

export const PinProcessing = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M5 18.828V21a1 1 0 001 1h12a1 1 0 001-1v-2.172c0-.79-.32-1.562-.879-2.121L13.414 12l4.707-4.707A2.98 2.98 0 0019 5.17V3a1 1 0 00-1-1H6a1 1 0 00-1 1v2.17c0 .802.312 1.555.879 2.121L10.586 12l-4.707 4.707A3.017 3.017 0 005 18.828zM9 4.999h6l-3 3-3-3z"
      ></path>
    </svg>
  );
};

export const Ruler = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M16.3828 0L0 16.3828L7.61709 23.9999L23.9999 7.61709L16.3828 0ZM16.3828 2.75061L21.2493 7.61709L7.61709 21.2493L2.75062 16.3828L3.869 15.2644L5.95463 17.3501L7.34506 15.9899L5.2292 13.874L6.3778 12.7556L7.22415 13.602L8.58434 12.2115L7.738 11.3954L8.88661 10.2468L10.9722 12.3325L12.3324 10.9723L10.2468 8.88662L11.3954 7.73801L12.2115 8.58436L13.602 7.22416L12.7556 6.37782L13.874 5.22921L15.9899 7.34507L17.35 5.95465L15.2644 3.86901L16.3828 2.75061Z"
      />
    </svg>
  );
};

export const Search = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={props.clickEvent}
    >
      <path
        fill={fillColor}
        d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      ></path>
    </svg>
  );
};

export const Substract = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill={fillColor} d="M21 11.001H3v2h18v-2z"></path>
    </svg>
  );
};

export const Star = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"
      />
    </svg>
  );
};

export const Filter = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 8.99902C20.654 8.99902 22 7.65302 22 5.99902C22 4.34502 20.654 2.99902 19 2.99902C17.698 2.99902 16.599 3.83702 16.185 4.99902H2V6.99902H16.185C16.599 8.16102 17.698 8.99902 19 8.99902ZM19 4.99902C19.551 4.99902 20 5.44802 20 5.99902C20 6.55202 19.551 6.99902 19 6.99902C18.449 6.99902 18 6.55202 18 5.99902C18 5.44802 18.449 4.99902 19 4.99902Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8.99902C7.698 8.99902 6.599 9.83702 6.185 10.999H2V12.999H6.185C6.599 14.161 7.699 14.999 9 14.999C10.301 14.999 11.401 14.161 11.815 12.999H22V10.999H11.815C11.401 9.83702 10.302 8.99902 9 8.99902ZM9 12.999C8.449 12.999 8 12.552 8 11.999C8 11.448 8.449 10.999 9 10.999C9.551 10.999 10 11.448 10 11.999C10 12.552 9.551 12.999 9 12.999Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.185 16.999C16.599 15.837 17.698 14.999 19 14.999C20.654 14.999 22 16.345 22 17.999C22 19.653 20.654 20.999 19 20.999C17.699 20.999 16.599 20.161 16.185 18.999H2V16.999H16.185ZM18 17.999C18 18.552 18.449 18.999 19 18.999C19.551 18.999 20 18.552 20 17.999C20 17.448 19.551 16.999 19 16.999C18.449 16.999 18 17.448 18 17.999Z"
      />
    </svg>
  );
};

export const User = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M16.002 8c0 2.205-1.794 4-4 4-2.205 0-4-1.795-4-4s1.794-4 4-4 4 1.795 4 4zM4.002 19c0-3.533 3.29-6 8-6 4.711 0 8 2.467 8 6v1h-16v-1z"
      />
    </svg>
  );
};

export const ViewHide = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M22.8 3.2l-1.6-1.6L1.6 20.8l1.6 1.6 4.554-4.46c1.228.521 2.587.86 4.046.86 6.222 0 10.633-6.162 10.817-6.424a.996.996 0 000-1.15c-.105-.15-1.577-2.209-3.972-3.956L22.8 3.2zm-5.613 5.498l-1.666 1.633a4.004 4.004 0 01-5.266 5.159l-.923.903c.781.255 1.608.407 2.468.407 4.18 0 7.552-3.572 8.731-4.999-.604-.728-1.781-2.016-3.343-3.103zm-3.4 3.331a2.003 2.003 0 01-1.799 1.762l1.799-1.762z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fillColor}
        d="M11.8 4.8c.89 0 1.742.126 2.55.343l-1.737 1.702A7.43 7.43 0 0011.8 6.8c-4.18 0-7.552 3.573-8.73 5a18.08 18.08 0 002.208 2.23l-1.44 1.411c-1.73-1.49-2.768-2.94-2.855-3.065a.996.996 0 010-1.15C1.167 10.961 5.578 4.8 11.8 4.8z"
      ></path>
    </svg>
  );
};

export const ViewShow = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M11.8 7.7998C9.59405 7.7998 7.80005 9.59481 7.80005 11.7998C7.80005 14.0058 9.59405 15.7998 11.8 15.7998C14.006 15.7998 15.8 14.0058 15.8 11.7998C15.8 9.59481 14.006 7.7998 11.8 7.7998ZM11.8 13.7998C10.697 13.7998 9.80005 12.9028 9.80005 11.7998C9.80005 10.6978 10.697 9.79981 11.8 9.79981C12.903 9.79981 13.8 10.6978 13.8 11.7998C13.8 12.9028 12.903 13.7998 11.8 13.7998Z"
      />
      <path
        fill={fillColor}
        d="M22.617 11.2248C22.4331 10.9618 18.022 4.7998 11.8 4.7998C5.57805 4.7998 1.16705 10.9618 0.983049 11.2248C0.739049 11.5698 0.739049 12.0308 0.983049 12.3758C1.16705 12.6378 5.57805 18.7998 11.8 18.7998C18.022 18.7998 22.4331 12.6378 22.617 12.3758C22.861 12.0298 22.861 11.5698 22.617 11.2248ZM11.8 16.7998C7.63405 16.7998 4.25105 13.2248 3.06905 11.7988C4.24805 10.3728 7.62005 6.7998 11.8 6.7998C15.966 6.7998 19.349 10.3758 20.531 11.8008C19.352 13.2278 15.98 16.7998 11.8 16.7998Z"
      />
    </svg>
  );
};

export const Warn = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M13 10h-2v6h2v-6zM13.25 18a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
      ></path>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M3 22a1.003 1.003 0 01-.895-1.448l9-18c.339-.677 1.45-.677 1.789 0l9 18A1.003 1.003 0 0121 22H3zm16.382-2L12 5.237 4.618 20h14.764z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
