// Imports
import PinSel from "@images-common/pins/Pin.svg";
import PinAvailable from "@images-common/pins/PinAvailableDefault.svg";
import PinAvailableSel from "@images-common/pins/PinAvailableSelected.svg";
import PinDenied from "@images-common/pins/PinDeniedDefault.svg";
import PinDeniedSel from "@images-common/pins/PinDeniedSelected.svg";
import PinProcessing from "@images-common/pins/PinProcessingDefault.svg";
import PinProcessingSel from "@images-common/pins/PinProcessingSelected.svg";
import { Icon, Style } from "ol/style";

// Icon type variable
let iconType;

// Pin constructor
const pinConstructor = (src) =>
  new Style({
    image: new Icon({
      anchor: [0.5, 1],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      src: src,
    }),
  });

// Icon type switcher //
// This function return an icon style based on passed argument.
// The possible arguments that can be used to call the function:
// select (def)| available | availableSel | denied | deniedSel
// processing | processingSel

export const PinStyle = (type = "select", selected = false) => {
  switch (true) {
    case type === "select":
      iconType = pinConstructor(PinSel);
      break;
    case type === "Available" && selected === false:
      iconType = pinConstructor(PinAvailable);
      break;
    case type === "Available" && selected === true:
      iconType = pinConstructor(PinAvailableSel);
      break;
    case type === "Denied" && selected === false:
      iconType = pinConstructor(PinDenied);
      break;
    case type === "Denied" && selected === true:
      iconType = pinConstructor(PinDeniedSel);
      break;
    case type === "Pending" && selected === false:
      iconType = pinConstructor(PinProcessing);
      break;
    case type === "Pending" && selected === true:
      iconType = pinConstructor(PinProcessingSel);
      break;
    default:
      break;
  }
  return iconType;
};
