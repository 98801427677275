import { Feature } from "ol";
import {
  setPanelBottomActive,
  setRequest,
} from "@store-forest";
import {
  clusterStyleFunction,
  prevStyleFunction,
  selectedStyle,
} from "./Styles";
import CrosshairCursor from "assets/images/common/Cursor";
import throttle from "lodash.throttle";
import Polygon from "ol/geom/Polygon.js";
import Select from "ol/interaction/Select.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { transform } from "ol/proj";
import { Vector as VectorSource } from "ol/source";
import { Cluster } from "ol/source.js";
import { previousPolygonLayer, zoomToFeatureExtent } from "@partials";

// Ref to currently selected interaction
let select = null;

// Utility function to preprocess requests
const createFeaturesFromRequests = (prevRequests) => {
  const newFeatures = [];
  prevRequests.forEach((request) => {
    const originalPolygons = request.request_data.polygons;
    const transformedPolygons = originalPolygons.map((polygon) => {
      return polygon.map((coordinates) =>
        transform(coordinates, "EPSG:4326", "EPSG:3857")
      );
    });
    const polygonGeometry = new Polygon(transformedPolygons);
    const newFeature = new Feature({
      geometry: polygonGeometry,
      status: request.status,
      data: request,
    });
    newFeatures.push(newFeature);
  });
  return newFeatures;
};

// Clicked on feature
export const changeInteraction = (map) => {
  // Add layer to previous layer
  const layers = map.getLayers().getArray();
  const previousLayer = layers.find(
    (layer) => layer.values_.title === "previous-layer"
  );

  if (select !== null) {
    map.removeInteraction(select);
  }

  select = new Select({ style: selectedStyle, layers: [previousLayer] });

  if (select !== null) {
    map.addInteraction(select);
    select.on("select", (e) => {
      const feature = e.selected[0];

      if(feature) {
        // Set data
        setRequest(feature.values_.data);
        //Zoom to feature extent
        zoomToFeatureExtent(map, feature, 200);
      }

      // Open or close panel bottom
      setPanelBottomActive(e.selected.length > 0);
    });
  } else {
    deselectFeatures(map);
  }
};

// Deselect features
export const deselectFeatures = (map) => {
  if (select) {
    select.getFeatures().clear();
    map.dispatchEvent(select);
  }
};

// Map hover events
export const handleMapHover = (map) => {
  map.on(
    "pointermove",
    throttle((e) => {
      const getFeature = (pixel) =>
        map.forEachFeatureAtPixel(
          pixel,
          (feature) => {
            return feature;
          },
          {layerFilter: (layer) => {
            return layer === previousPolygonLayer;
          },
          }
        );

      map.getViewport().style.cursor = getFeature(e.pixel)
        ? "pointer"
        : CrosshairCursor;
    }, 100)
  );
};

// Add previous request to map
export const addPreviousFeaturesToLayer = (prevRequests, previousLayer) => {
  const newFeatures = createFeaturesFromRequests(prevRequests);
  newFeatures.map((newFeature) =>
    previousLayer.getSource().addFeature(newFeature)
  );
  previousLayer.setStyle((feature) => {
    return prevStyleFunction(feature, true);
  });
};

// Add clusters to map
export const addClusterLayerToMap = (prevRequests, map) => {
  if (map) {
    const newFeatures = createFeaturesFromRequests(prevRequests);

    const clusterVectorSource = new VectorSource({
      features: newFeatures,
    });

    const clusterSource = new Cluster({
      distance: 100,
      source: clusterVectorSource,
      geometryFunction: (feature) => {
        let type = feature.getGeometry().getType();
        if (type === "Polygon") {
          return feature.getGeometry().getInteriorPoint();
        } else if (type === "LineString") {
          return feature.getGeometry().getCoordinateAt(0.5);
        }
      },
    });

    const clusters = new VectorLayer({
      source: clusterSource,
      style: (feature) => clusterStyleFunction(feature),
    });

    return map.addLayer(clusters);
  }
};