// Imports
import { setPrevRequests } from "@store-geostore";
import { cookieGetToken, urls } from "api/common";
import { CheckError, filterByService } from "utils/common";

// Requests by Domain
export const getRequests = async () => {
  await fetch(urls.getRequests, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => setPrevRequests(filterByService(actualData, "GeoStore")))
    .catch((error) => console.log(error));
};
