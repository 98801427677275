// Cookies Import
import Cookies from "universal-cookie";

// Initialize cookies
const cookies = new Cookies();

// Domain option
const domain =
  process.env.NODE_ENV === "development" ? "localhost" : ".envimap.hu";

// Token
export const cookieSetToken = (token) => cookies.set("userToken", token);
export const cookieGetToken = () => cookies.get("userToken");
export const cookieRemoveToken = () => cookies.remove("userToken");

// TempPassword
export const cookieGetTempPassword = () =>
  cookies.get("tempPassword", { domain: domain });

// Set GeoStore data
export const cookieSetGeoStoreData = (data) => {
  const selectedProds = {
    slugArray: data.map((prod) => prod.slug),
  };

  cookies.set("geoStoreData", selectedProds);
};

// Get GeoStore data
export const cookieGetGeoStoreData = () => cookies.get("geoStoreData");

// Set GeoStore info
export const cookieSetGeoStoreInfo = (info) =>
  cookies.set("geoStoreInfo", info);

// Get GeoStore info
export const cookieGetGeoStoreInfo = () => cookies.get("geoStoreInfo");

// Remove all GeoStore
export const cookieRemoveGeoStore = () => {
  cookies.remove("geoStoreData");
  cookies.remove("geoStoreInfo");
};

// Set Tour state
export const cookieSetTour = (value) => cookies.set("tourActive", value);

// Get Tour state
export const cookieGetTour = () => cookies.get("tourActive");

// RememberMe
export const cookieSetRememberMe = (email, pass) => {
  cookies.set(
    "rememberMe",
    { email: email, password: pass },
    { domain: domain }
  );
};
export const cookieGetRememberMe = () => {
  return cookies.get("rememberMe", { domain: domain });
};
export const cookieRemoveRememberMe = () => {
  cookies.remove("rememberMe", { domain: domain });
};
