// Imports
import proj4 from "proj4";
import { createContext, useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { StoreModel, changeOrtomapChangeTrack, modelortoLayer } from "@service-roof";
import Map from "ol/Map";
import View from "ol/View";
import { fromLonLat, transform } from "ol/proj";
import { register } from "ol/proj/proj4";

// Define EPSG:23700 used by the building's JSON data
const EPSG_23700 = "EPSG:23700";
proj4.defs(
  EPSG_23700,
  "+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs"
);
register(proj4);

// Create MapContext context
export const MapContext = createContext();

// ORTOMAP
export const Ortomap = () => {
  const { gps } = useSnapshot(StoreModel.model);

  const ortomapRef = useRef(null);
  const [ortomap, setOrtomap] = useState(null);

  const setupOrtomap = (gps) => {
    const convertedGPS = transform([gps.x, gps.y], EPSG_23700, "EPSG:4326");

    const options = {
      view: new View({
        projection: EPSG_23700,
        center: fromLonLat(convertedGPS, EPSG_23700),
        resolution: 0.1,
      }),
      layers: [modelortoLayer],
    };

    const mapObject = new Map(options);
    mapObject.setTarget(ortomapRef.current);
    setOrtomap(mapObject);
    return () => mapObject.setTarget(undefined);
  };

  // --- MOUNT MAP ---
  useEffect(() => {
    if (!gps?.x) return;

    return setupOrtomap(gps);
  }, [gps]);

  useEffect(() => {
    if (!ortomap) return;

    const handleLoadEnd = () => {
      // Pass successful load to store
      changeOrtomapChangeTrack();

      // Check ortho availability
      const [x, y] = [750, 750];
      const pixelData = modelortoLayer.getData([x, y]);
      const availability = pixelData && pixelData[3] !== 0;
      
      // Pass availability to store
      StoreModel.view.ortoAvailable = availability;
    };

    ortomap.on("loadend", handleLoadEnd);

    return () => {
      ortomap.un("loadend", handleLoadEnd);
    };
  }, [ortomap]);

  return (
    <MapContext.Provider value={{ map: ortomap }}>
      <div id="ortomap" className="ortomap" ref={ortomapRef} />
    </MapContext.Provider>
  );
};