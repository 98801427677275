import { useEffect, useState } from "react";
import { Login, cookieGetRememberMe } from "@apis-common";
import { ArrowRight } from "@icons";
import { Button, CheckboxForm, Input, InputPassword } from "@molecules";
import { useToastContext } from "@partials";
import { LoginFormFields } from "@store-geostore";
import { dataObject, handleInput } from "@utils-common";
import { Modal } from "..";

const renderFormField = (formField, index, setFormData) => {
  const { label, type, value, name } = formField;
  const commonProps = {
    key: index,
    label,
    type,
    value,
    onChange: (e) => handleInput(e, setFormData),
    name,
  };

  if (type === "password") {
    return <InputPassword {...commonProps} />;
  } else if (type !== "checkbox") {
    return <Input {...commonProps} />;
  }
};

export const ModalLogin = () => {
  const addToast = useToastContext();
  const [formData, setFormData] = useState(LoginFormFields);
  const checkboxObject = formData.find((item) => item.type === "checkbox");

  // Handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = dataObject(formData);

    // Login API call
    Login(data, addToast);
  };

  // Load RememberMe
  useEffect(() => {
    const cookieRememberMe = cookieGetRememberMe();
    if (cookieRememberMe) {
      setFormData([
        {
          label: "Email-cím*",
          type: "text",
          name: "email",
          value: cookieRememberMe.email,
        },
        {
          label: "Jelszó*",
          type: "password",
          name: "password",
          value: cookieRememberMe.password,
        },
        {
          label: "Jegyezzen meg",
          value: true,
          name: "rememberMe",
          type: "checkbox",
        },
      ]);
    }
  }, []);

  return (
    <Modal fullH>
      <form onSubmit={handleSubmit}>
        <div className="modal-content__body login">
          <div className="modal-content__body-form">
            {formData.map((formField, index) => {
              if (formField.type !== "checkbox") {
                return renderFormField(formField, index, setFormData);
              }
            })}
            <Button
              iconPosition="after"
              icon={<ArrowRight />}
              text="Belépés"
              type="submit"
              value="download"
            />
            <div className="modal-content__body-form__bottom">
              <CheckboxForm
                checked={checkboxObject.value}
                label={checkboxObject.label}
                name={checkboxObject.name}
                onChange={(e) => handleInput(e, setFormData)}
                value={checkboxObject.value}
                required={checkboxObject.required}
                hasError={checkboxObject.hasError}
              />
              <a href="https://account.envimap.hu/forgotten" target="blank">
                Elfelejtett jelszó?
              </a>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
