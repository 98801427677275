import { Feature } from "ol";
import { prevStyleFunction } from "./Styles";
import CrosshairCursor from "assets/images/common/Cursor";
import throttle from "lodash.throttle";
import Polygon from "ol/geom/Polygon.js";
import Select from "ol/interaction/Select.js";
import { transform, transformExtent } from "ol/proj";
import { setPanelBottomActive, setRequest } from "@store-geostore";
import { previousPolygonLayer, zoomToFeatureExtent } from "@partials";

// Ref to currently selected interaction
let select = null;

// Utility function to preprocess requests
const createFeaturesFromRequests = (prevRequests) => {
  const newFeatures = [];

  prevRequests.forEach((request) => {
    const originalPolygons = request.request_data.polygons;
    const transformedPolygons = originalPolygons.map((polygon) => {
      return polygon.map((coordinates) =>
        transform(coordinates, "EPSG:4326", "EPSG:3857")
      );
    });

    const polygonGeometry = new Polygon(transformedPolygons);
    const newFeature = new Feature({
      geometry: polygonGeometry,
      status: request.status,
      data: request,
    });
    newFeatures.push(newFeature);
  });

  return newFeatures;
};

// Clicked on feature
export const changeInteraction = (map) => {
  // Add layer to previous layer
  const layers = map.getLayers().getArray();
  const previousLayer = layers.find(
    (layer) => layer.values_.title === "previous-layer"
  );

  if (select !== null) {
    map.removeInteraction(select);
  }

  select = new Select({ layers: [previousLayer] });

  if (select !== null) {
    map.addInteraction(select);
    select.on("select", (e) => {
      const feature = e.selected[0];

      if(feature) {
        // Set data
        setRequest(feature.values_.data);
        //Zoom to feature extent
        zoomToFeatureExtent(map, feature, 200);
      }

      // Open or close panel bottom
      setPanelBottomActive(e.selected.length > 0);
    });
  } else {
    deselectFeatures(map);
  }
};

// Deselect features
export const deselectFeatures = (map) => {
  if (select) {
    select.getFeatures().clear();
    map.dispatchEvent(select);
  }
};

// Add cookie request to map
export const addCookieFeaturesToLayer = (cookie, layer) => {
  const newCookieFeatures = [];

  // Create Features
  cookie.polygons.forEach((polygon) => {
    const transformedPolygons = polygon.map((coordinates) =>
      transform(coordinates, "EPSG:4326", "EPSG:3857")
    );

    const polygonGeometry = new Polygon([transformedPolygons]);
    const newCookieFeature = new Feature({
      geometry: polygonGeometry,
    });
    newCookieFeatures.push(newCookieFeature);
  });

  // Add features to layer
  newCookieFeatures.forEach((newCookieFeature) =>
    layer.getSource().addFeature(newCookieFeature)
  );
};

// Add previous request to map
export const addPreviousFeaturesToLayer = (prevRequests, layer) => {

  const newFeatures = createFeaturesFromRequests(prevRequests);
  newFeatures.map((newFeature) =>
    layer.getSource().addFeature(newFeature)
  );
  layer.setStyle((feature) => {
    return prevStyleFunction(feature, true);
  });
};

// Map hover events
export const handleMapHover = (map) => {
  map.on(
    "pointermove",
    throttle((e) => {
      const getFeature = (pixel) =>
        map.forEachFeatureAtPixel(
          pixel,
          (feature) => {
            return feature;
          },
          {layerFilter: (layer) => {
            return layer === previousPolygonLayer;
          },
          }
        );

      map.getViewport().style.cursor = getFeature(e.pixel)
        ? "pointer"
        : CrosshairCursor;
    }, 100)
  );
};


// Map search events
export const handleSearch = (map, searchBounds) => {
  if (searchBounds.length > 0) {
    const transSearchBounds = transformExtent(
      searchBounds,
      "EPSG:4326",
      "EPSG:3857"
    );
    let getView = map.getView();
    getView.fit(transSearchBounds, { duration: 600 });
  }
};