// Imports
import { useSnapshot } from "valtio";
import { StoreModel, handleViewFlyover, handleViewHomeing } from "@service-roof";
import { AutoRotate, Home } from "@icons";

// VIEW ACTIONS
const ViewActions = () => {
  return (
    <>
      <ul className="viewactions">
        <Homeing />
        <Flyover />
      </ul>
    </>
  );
};

const Homeing = () => {
  return (
    <li>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleViewHomeing();
        }}
        title="Kamera alaphelyzetbe állítása"
      >
        <Home />
      </button>
    </li>
  );
};

const Flyover = () => {
  const { autoRotate } = useSnapshot(StoreModel.view);

  return (
    <li className={autoRotate ? "active" : ""}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleViewFlyover();
        }}
        title="Autómatikus forgatás"
      >
        <AutoRotate />
      </button>
    </li>
  );
};

export default ViewActions;
