const googleMapsApiKey = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_GOOGLE_PLACES_API_KEY_PROD 
  : process.env.REACT_APP_GOOGLE_PLACES_API_KEY_DEV;


export const getGoogleMapsAPI = () => {
    return new Promise((resolve, reject) => {
      if (window.google && window.google.maps) {
        resolve(window.google.maps);
      } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve(window.google.maps);
        script.onerror = reject;
        document.head.appendChild(script);
      }
    });
  };
  