// Imports
import { CheckError } from "@utils-common";
import {
  cookieRemoveRememberMe,
  cookieSetRememberMe,
  cookieSetToken,
} from "./cookie";
import { getInit } from "./init";
import { urls } from "./urls";

// Login
export const Login = async (body, message) => {
  await fetch(urls.login, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: body.email,
      password: body.password,
    }),
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Kickout users wo are inactive or in default domain
      if (
        !actualData.is_active ||
        actualData.domain_id === "670a5c9c-1c12-476e-90a0-bbce8bf56d0c"
      ) {
        message(["error", "A felhasználói fiók nincs aktiválva!"]);
      } else {
        // Save token
        cookieSetToken(actualData.token);
        // Store/Remove rememberMe from cookie
        if (body.rememberMe) {
          cookieSetRememberMe(body.email, body.password);
        } else {
          cookieRemoveRememberMe();
        }
        // Init
        getInit(actualData.domain_id);

        message(["success", "Sikeres belépés! Átirányítás..."]);

        setTimeout(() => window.location.reload(), 2000);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
