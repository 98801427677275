// Imports
import { proxy } from "valtio";
import { cookieSetGeoStoreInfo, getGeocodeData } from "@apis-common";
import { getFormattedDate } from "@utils-common";
import { formatArea, getPolygonCenter } from "@utils-forest";
import { round } from "@utils-roof";
import { transform } from "ol/proj";

const initNewRequestForestData = {
  polygons: [
    [
      [18.111970446164605, 47.2568064162011],
      [18.107655589809866, 47.25161034447561],
    ],
    [
      [18.146747305533182, 47.27424091430021],
      [18.150236215545334, 47.2752482198496],
    ],
  ],
  package: 0,
  total_area: 124.3,
  available_from: "2023-04-14 20:42:09.100904",
  available_till: "2023-06-12 20:42:09.100904",
  survey_date: "2020-04-21",
};

const initNewRequestForest = {
  lat: 0,
  lon: 0,
  building_type: "string",
  building_features: 0,
  service_id: "Forest",
  resolved_addr: "string",
  request_data: initNewRequestForestData,
};

const initNewRequestGeoStorePanelInfo = {
  polygons: [],
  city: "",
  total_area: 0,
  survey_date: "",
  available: null,
};

// STORE
export const StoreNewRequestGeoStore = proxy({
  // Has two types: area | file
  panelType: "area",
  drawActive: false,
  drawDone: false,
  addToMap: false,
  newRequestData: initNewRequestForest,
  panelInfo: initNewRequestGeoStorePanelInfo,
  panelState: 0,
});

// Set toolbarActive
export const setToolbarActive = (value) => {
  StoreNewRequestGeoStore.drawActive = value;
};
// Set toolbar to make done StoreApp
export const setToolbarDone = (value) => {
  StoreNewRequestGeoStore.drawDone = value;
};
// Set toolbar map true
export const addToMapTrue = (value) => {
  StoreNewRequestGeoStore.addToMap = value;
};
// Set toolbar to change panel type
export const setPanelType = (value) => {
  StoreNewRequestGeoStore.panelType = value;
};

// Set toolbar to change panel type
export const resetPanelInfoGeoStore = () =>
  (StoreNewRequestGeoStore.panelInfo = initNewRequestGeoStorePanelInfo);

// Set new request data
export const setNewRequestDataGeoStore = async (features, isAvailable) => {
  let averageLat, averageLon;

  // Get the centers for each feature
  const featureCenters = features.map((feature) => {
    const center = getPolygonCenter(feature.getGeometry());
    return [center.lat, center.lon];
  });

  // Calculate the average latitude and longitude
  if (featureCenters.length > 0) {
    const totalLat = featureCenters.reduce((sum, center) => sum + center[0], 0);
    const totalLon = featureCenters.reduce((sum, center) => sum + center[1], 0);
    averageLat = totalLat / featureCenters.length;
    averageLon = totalLon / featureCenters.length;
  }

  // Transform each features geometry
  const transformedFeatures = features.map((feature) => {
    const coordinates = feature.getGeometry().getCoordinates()[0];
    return coordinates.map((coords) =>
      transform(coords, "EPSG:3857", "EPSG:4326")
    );
  });

  // Get address for the average center
  const address = await getGeocodeData(averageLat, averageLon);

  // Calculate the total area
  const totalArea = features.reduce((sum, feature) => {
    const area = formatArea(feature.getGeometry());
    return sum + parseFloat(area);
  }, 0);

  const panelInfo = {
    polygons: transformedFeatures,
    city: address.locality,
    total_area: totalArea < 0.01 ? 0.01 : totalArea,
    survey_date: getFormattedDate(),
    lat: featureCenters[0][0],
    lon: featureCenters[0][1],
    available: isAvailable,
  };

  // Set cooike data
  cookieSetGeoStoreInfo(panelInfo);

  // Set panel info
  StoreNewRequestGeoStore.panelInfo = panelInfo;

  // Set store data
  StoreNewRequestGeoStore.newRequestData = {
    lat: averageLat,
    lon: averageLon,
    building_type: "string",
    building_features: 0,
    service: "GeoStore",
    resolved_addr: address,
    request_data: {
      polygons: transformedFeatures,
      package: 0,
      total_area: round(totalArea, 2),
      available_from: "2023-04-14 20:42:09.100904",
      available_till: "2023-06-12 20:42:09.100904",
      survey_date: getFormattedDate(),
    },
  };
};

// Set panel state
export const setPanelState = (value) =>
  (StoreNewRequestGeoStore.panelState = value);
