// Base URL
const baseURL = "https://api.envimap.hu/api/v1/";

// Routes
const users = `${baseURL}users/`;
const domain = "https://api.envimap.hu/api/v3/domain/";
const request = "https://api.envimap.hu/api/v3/request/";

// Used urls in API calls
export const urls = {
  // Health
  health: `https://api.envimap.hu/api/v1/public/health`,
  // User
  profile: `${users}profile`,
  // Domain
  domain: `${domain}`,
  // Request
  postNewRequest: `${request}create`, // POST - New request
  getRequests: `${request}`, // GET - All prev requests
  getRequestByID: `${request}`, // GET - Specific prev request by {request_id}

  getFile: `${request}file/`,
  // Login
  login: `${users}login`,
  // Registration
  registration: `${users}register`,
  // VAT number
  vatNum: "https://api.cegjelzo.com/api/v2/search"
};
