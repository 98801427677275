const checkNull = (data) => {
  if (data === null) return "";
  return data;
};

export const formatAddress = (address) => {
  if (address.startsWith("{")) {
    const { locality, route, street_number, postal_code } = JSON.parse(address);
    const formatedAddress = `${checkNull(locality)}, ${checkNull(
      route
    )} ${checkNull(street_number)}${
      route !== null || street_number !== null ? "," : ""
    } ${postal_code}`;
    return formatedAddress;
  }
  return address;
};

export const getCounty = (address) => {
  if (address.startsWith("{")) {
    const { county } = JSON.parse(address);
    return county;
  }
  return address;
};

export const getLocalityFromAddress = (addressString) => {
  try {
    const addressObject = JSON.parse(addressString);
    const locality = addressObject.locality;
    return locality;
  } catch (error) {
    console.error("Hiba történt az adatok feldolgozása közben:", error);
    return null;
  }
};
