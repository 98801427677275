// Imports
import { setNewRequestPinAddress } from "@store-roof";
import { getGoogleMapsAPI } from "./getGoogleMapsApi";

export const getGeocode = async (lat, lng) => {
  const googleMaps = await getGoogleMapsAPI();
  const geocoder = new googleMaps.Geocoder();

  try {
    const response = await new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          resolve(results);
        } else {
          reject(new Error(`Geocoding failed: ${status}`));
        }
      });
    });

    const result = response[0];

    const getAddressComponent = (type) => {
      return result.address_components.find(comp => comp.types.includes(type));
    };

    const county = getAddressComponent("administrative_area_level_1")?.long_name;
    const postal_code = getAddressComponent("postal_code")?.long_name || null;
    const locality = getAddressComponent("locality")?.long_name || null;
    const political = getAddressComponent("political")?.short_name || null;
    const route = getAddressComponent("route")?.short_name || null;
    const street_number = getAddressComponent("street_number")?.long_name || null;
    const code = result.plus_code?.global_code || null;

    const formattedAddress = (route && street_number)
      ? `${postal_code || "XXXX"} ${locality}${(political && political !== locality) ? " (" + political + ")": ""}, ${route} ${street_number}.`
      : `${postal_code || "XXXX"} ${locality}, ${code}`;

    const address = {
      formatted: formattedAddress,
      county,
      postal_code,
      locality,
      political,
      route,
      street_number,
      code
    };

    setNewRequestPinAddress(address);
  } catch (error) {
    console.error("Geocoding error:", error);
  }
};
