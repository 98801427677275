// Imports
import { GaEventLogin, cookieGetToken, cookieRemoveToken, getInit, urls } from "api/common";
import { setUser, setUserLoggedIn } from "data/common";
import { CheckError } from "utils/common";

// getProfile
export const getProfile = async () => {
  await fetch(urls.profile, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {

      // GA send login event
      GaEventLogin();
      
      // Redirect user if using temporary password
      if (actualData.is_temp_password) {
        cookieRemoveToken();
        window.location.assign("https://account.envimap.hu");
      }
      // Set returned user data
      setUser(actualData);
      // Set user logged in
      setUserLoggedIn(true);
      // Init
      getInit(actualData.domain_id);
    })
    .catch((error) => console.log(error));
};
