export const ModalLayout = ({ children, fullH }) => {
  return (
    <>
      <div className="modal-layout" />
      <div className="modal">
        <div className="modal-centered">
          <div className={`modal-content${fullH ? ' full-height' : ''}`}>{children}</div>
        </div>
      </div>
    </>
  );
};
