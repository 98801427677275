// Imports
import { proxy } from "valtio";

// STORE
export const StoreUser = proxy({
  userLoggedIn: false,
  temporaryEmail: '',
  user: {
    // User related data holder
    first_name: "",
    last_name: "",
    email: "",
    domain_id: "",
    id: "",
    is_admin: false,
  },
});

// --- USER ---
// Set user logged in
export const setUserLoggedIn = (StoreApp) => {
  StoreUser.userLoggedIn = StoreApp;
};

// Set user logged in
export const SetTemporaryEmail = (email) => {
  StoreUser.temporaryEmail = email;
};

// Set user data from api data
export const setUser = (data) => {
  // Keys that need to be stored
  const needToStore = [
    "first_name",
    "last_name",
    "email",
    "domain_id",
    "id",
    "is_admin",
  ];

  // Store the values
  needToStore.forEach((key) => {
    StoreUser.user[key] = data[key];
  });
};
