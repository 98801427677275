import { createContext, useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
// Layout
import { MainBottom } from "@layout";
// Partials
import {
  MapConfig,
  availabilityLayer,
  backgroundLayer,
  satalaiteLayer,
  ortoLayer,
  maskAvailabilityLayer,
  previousPolygonLayer,
  clearDraw,
  useToastContext,
  zoomToFeatureExtent,
} from "@partials";
// Components
import { StoreRequests, setPanelBottomActive, setRequest } from "@store-forest";
import {
  addClusterLayerToMap,
  addPreviousFeaturesToLayer,
  changeInteraction,
  handleMapHover,
} from "./functions.MapForest";
import Map from "ol/Map";
import View from "ol/View";
import { defaults as controlDefaults } from "ol/control/defaults";
import { defaults as interactionDefaults } from "ol/interaction/defaults";
import { PanelBottom } from "@service-forest";
import { StoreApp } from "@store-common";
import { useSearchParams } from "react-router-dom";

// Create MapContext context
const MapContext = createContext();

export const MapForest = () => {
  // Map and layers
  const mapRef = useRef();
  const [ map, setMap ] = useState(null);
  const [ prevRequestsInit, setPrevRequestsInit ] = useState(false);
  const { prevRequests, activeRequestData, panelBottomActive} = useSnapshot(StoreRequests);
  const { asideActive } = useSnapshot(StoreApp);
  // url param
  const [urlParam, setUrlParam] = useSearchParams();
  const [urlParamInit, setUrlParamInit] = useState(false);
  // toast
  const addToast = useToastContext();

  // Init map
  useEffect(() => {
    let options = {
      view: new View({
        projection: MapConfig.baseViewProjection,
        center: MapConfig.baseViewWMCenter,
        zoom: MapConfig.initZoom,
        minZoom: MapConfig.initZoom,
        maxZoom: MapConfig.maxZoom,
        extent: MapConfig.maxViewExtent(),
      }),
      layers: [
        backgroundLayer,
        satalaiteLayer,
        availabilityLayer,
        ortoLayer,
        previousPolygonLayer
      ],
      controls: controlDefaults({
        zoom: false,
        rotate: false,
      }),
      interactions: interactionDefaults({
        pinchRotate: false,
      }),
    };
    let mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []);

  // Initial map effect
  useEffect(() => {
    // Clean previousPolygonLayer
    previousPolygonLayer.getSource().clear();
  }, []);

  // Add previous request, clusters and pins to map
  useEffect(() => {
    if (prevRequests.length > 0) {
      // Add prev requests to
      addPreviousFeaturesToLayer(prevRequests, previousPolygonLayer);
      // Add clusters
      addClusterLayerToMap(prevRequests, map);
      // Set prev requests initalization
      setPrevRequestsInit(true);
    }
  }, [prevRequests]);

  // Check Initial UrlParam
  useEffect(() => {
    if (prevRequestsInit) {
      const previousFeatures = previousPolygonLayer.getSource().getFeatures();
      
      if (!urlParamInit && previousFeatures) {
        // Get initial url param
        const initId = urlParam.get("erd");

        if (initId) {
          // Find ID inside prevRequests
          let findFeature = previousFeatures.find(
            (e) => e.values_.data.request_id === "ERD-" + initId
          );

          // Go ahead if ID matched with any feature
          if (findFeature) {
            setRequest(findFeature.values_.data);
            zoomToFeatureExtent(map, findFeature, 200);
            setPanelBottomActive(true);
          } else {
            addToast([
              "error",
              `A keresett azonosító (ERD-${initId}) nem elérhető!`,
            ]);
          }
        }
        // Set init state
        setUrlParamInit(true);
      }
    }
  }, [prevRequestsInit, urlParamInit]);

  // Handle UrlParam Change when activePrevPin StoreApp changes
  useEffect(() => {
    if (urlParamInit) {
      if (panelBottomActive) {
        // Set URLSearchParam -> ID
        setUrlParam({ erd: activeRequestData.request_id.replace(/\D/g, "") });
      } else {
        // Unset URLSearchParam -> ID
        urlParam.delete("erd");
        setUrlParam(urlParam);
      }
    }
  }, [urlParamInit, panelBottomActive, activeRequestData, urlParam, setUrlParam]);

  // Handle Map events
  useEffect(() => {
    if (map) {
      // Map Hover events
      handleMapHover(map);
      // Feature click
      changeInteraction(map);
      // Mask Google Satalite with AvailabilityLayer
      maskAvailabilityLayer();
    }
  }, [map]);

  // ClearDraw if aside has been closed
  useEffect(() => {
    if (!asideActive) clearDraw(map);
  }, [asideActive]);

  return (
    <>
      <MapContext.Provider value={{ map: map }}>
        <div className="map" ref={mapRef} />
      </MapContext.Provider>
      <PanelBottom map={map}/>
      <MainBottom map={map} />
    </>
  );
};
