// Imports
import { useState, useEffect } from "react";
import { Close } from "@icons";
import { useSnapshot } from "valtio";
import { StoreApp } from "@store-common";
import { getServiceStatus } from "api/common/getServiceStatus";

export const ServiceStatus = () => {
  const { serviceStatus } = useSnapshot(StoreApp);
  const [active, setActive] = useState(true);

  const closeStatus = () => {
    setActive(false);
  };

  useEffect(() => {
    // Get the current service statuses from Contentful
    getServiceStatus();
  }, []);

  return serviceStatus.length > 0 ? (
    <div className={`servicestatus ${active ? "" : "hidden"}`}>
      <div className="servicestatus-head">
        <h5>
          {serviceStatus.length === 1
            ? "Rendszerüzenet"
            : "Rendszerüzenetek"
          }
        </h5>
        <button onClick={() => closeStatus()}>
          <Close color="white"/>
        </button>
      </div>
      <ul className="servicestatus-content">
        {serviceStatus.map((status, index) => {
          return (
            <li key={index}>
              <p>{status.message}</p>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};
