// Imports
import { proxy } from "valtio";
import { getGeocodeData } from "@apis-common";
import { getFormattedDate } from "@utils-common";
import { formatArea, getPolygonCenter } from "@utils-forest";
import { transform } from "ol/proj";

const initNewRequestForestData = {
  polygons: [],
  package: 0,
  total_area: 0,
  available_from: "",
  available_till: "",
  survey_date: "",
};

const initNewRequestForest = {
  lat: 0,
  lon: 0,
  building_type: "string",
  building_features: 0,
  service: "Forest",
  resolved_addr: "string",
  request_data: initNewRequestForestData,
};

// STORE
export const StoreNewRequest = proxy({
  // Has two types: area | file
  panelType: "area",
  drawActive: false,
  drawDone: false,
  addToMap: false,
  newRequestData: initNewRequestForest,
});

// Set toolbarActive
export const setToolbarActive = (value) => {
  StoreNewRequest.drawActive = value;
};
// Set toolbar to make done StoreApp
export const setToolbarDone = (value) => {
  StoreNewRequest.drawDone = value;
};
// Set toolbar map true
export const addToMapTrue = (value) => {
  StoreNewRequest.addToMap = value;
};
// Set toolbar to change panel type
export const setPanelType = (value) => {
  StoreNewRequest.panelType = value;
};

// Set new request data
export const setNewRequestData = async (features, isAvailable) => {

  let averageLat, averageLon;
  
  // Get the centers for each feature
  const featureCenters = features.map((feature) => {
    const center = getPolygonCenter(feature.getGeometry());
    return [center.lat, center.lon];
  });
  
  // Calculate the average latitude and longitude
  if (featureCenters.length > 0) {
    const totalLat = featureCenters.reduce((sum, center) => sum + center[0], 0);
    const totalLon = featureCenters.reduce((sum, center) => sum + center[1], 0);
    averageLat = totalLat / featureCenters.length;
    averageLon = totalLon / featureCenters.length;
  }

  // Transform each features geometry
  const transformedFeatures = features.map((feature) => {
    const coordinates = feature.getGeometry().getCoordinates()[0];
    return coordinates.map((coords) =>
      transform(coords, "EPSG:3857", "EPSG:4326")
    );
  });

  // Get address for the average center
  const address = await getGeocodeData(averageLat, averageLon);

  // Calculate the total area
  const totalArea = features.reduce((sum, feature) => {
    const area = formatArea(feature.getGeometry(), "ha");
    return sum + parseFloat(area);
  }, 0);

  // Set store data
  StoreNewRequest.newRequestData = {
    lat: averageLat,
    lon: averageLon,
    building_type: "string",
    building_features: 0,
    service: "Forest",
    resolved_addr: address,
    request_data: {
      polygons: transformedFeatures,
      total_area: totalArea < 1 ? 1 : totalArea,
      survey_date: getFormattedDate(),
    },
    available: isAvailable,
  };
};
