// OpenLayers
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";

// Components
import { PinStyle } from "./Pin";


export const modelortoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/Full_Merge_230404/{z}/{x}/{y}",
    crossOrigin: "Anonymous",
    minZoom: 18,
    maxZoom: 18,
  }),
  name: "modelortoLayer",
});

// NEW PIN LAYER
export const newPinLayer = new VectorLayer({
  source: new VectorSource(),
  style: PinStyle("select"),
  name: "newPinLayer",
});
