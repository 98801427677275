import { Close } from "@icons";
import { purchasingProcess } from "@store-geostore";
import { ModalLayout, useModalContext } from "..";
import { PurchasingProcess } from "components/partials/process";

export const ModalPurchasingProcess = () => {
  const { setIsModalOpen } = useModalContext();
  return (
    <ModalLayout>
      <div className="modal-content__header">
        <h2>VÁSÁRLÁS FOLYAMATA</h2>
        <button onClick={() => setIsModalOpen(false)}>
          <Close />
        </button>
      </div>
      <div className="modal-content__body">
        <p>Ismerje meg a vásárlási folyamat lépéseit...</p>
        <div className="modal-content__body body-process">
          {purchasingProcess.map((step, i) => (
            <PurchasingProcess key={i} step={step} i={i} />
          ))}
        </div>
      </div>
      <div className="modal-content__footer discount"></div>
    </ModalLayout>
  );
};
