// Imports
import { useEffect } from "react";
import { getTermsAndConds } from "@apis-common";
import { useSearchParams } from "react-router-dom";

export const TCResolver = () => {
  const [urlParam] = useSearchParams();

  useEffect(() => {
    const service = urlParam.get("service");
    
    if (service) {
      getTermsAndConds(service);
    }
  }, []);

  return <p>ÁSZF betöltése...</p>;
};