// Calculate summary
export const useSummaryCalculator = (selection, sides, edges) => {
  // Calculation
  const newSummary = selection.items.reduce(
    (acc, item) => {
      const side = sides.find((side) => side.uID === item.uID);
      const edge = edges.find((edge) => edge.uID === item.uID);

      return {
        area: acc.area + side?.properties.area_3d || 0,
        slope:
          acc.slope + side?.properties.area_3d * side?.properties.slope || 0,
        length: acc.length + edge?.properties.length_3d || 0,
      };
    },
    { area: 0, slope: 0, length: 0 }
  );

  // Extra calculation for slope weighted avarage
  if (newSummary.slope) {
    newSummary.slope = newSummary.slope / newSummary.area;
  }

  return newSummary;
};

// Create filter list for checkbox component
export const createUniqueArray = (panelData) => {
  const uniqueValues = {};

  const resultArray = panelData.items.reduce((accumulator, currentValue) => {
    const value = currentValue.properties.LAYER;

    if (!uniqueValues[value]) {
      uniqueValues[value] = true;
      accumulator.push(value);
    }

    return accumulator;
  }, []);

  return resultArray;
};
