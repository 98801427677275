// Imports
import { useSnapshot } from "valtio";
import { Navbar } from "@partials";
import { StoreUser } from "data/common";

export const MainTop = () => {
  const { searchInputOpen } = useSnapshot(StoreUser);

  return (
    <div className={`main-top ${searchInputOpen ? "searching" : ""}`}>
      <Navbar />
    </div>
  );
};
